import { useState } from 'react';

import { Button } from 'reactstrap';

import FormGroup from '@/components/form-group';
import Input from '@/components/input';
import Plus from '@/components/plus';

import WithActions from '@/engine/decorators/with-actions';
import emailValidator from '@/engine/validators/email-validator';

const CollaboratorAdd = ({ publish, propertyId }) => {
  const [adding, setAdding] = useState(false);
  const [email, setEmail] = useState({ value: '' });

  const onAdd = (e) => {
    e.preventDefault();
    setAdding(true);
  };

  const returnToDefault = () => {
    setAdding(false);
    setEmail({ value: '' });
  };

  const onCancel = (e) => {
    e.preventDefault();
    returnToDefault();
  };

  const onSave = (e) => {
    e.preventDefault();
    publish({ propertyId, email: email.value });
    returnToDefault();
  };

  return (
    <>
      {adding && (
        <div className="mt-2">
          <FormGroup>
            <Input type="text" value={email.value} onChange={(e) => setEmail(e)} validator={emailValidator} isRequired placeholder="Email Address" />
          </FormGroup>
        </div>
      )}
      <div>
        {!adding && (
          <Button
            onClick={onAdd}
            color="primary"
          >
            <Plus className="btn-swatch__icon mr-2" />
            Add Collaborator
          </Button>
        )}
        {adding && (
          <div className="mt-2">
            <Button
              onClick={onSave}
              color="primary"
              disabled={!email?.valid}
            >
              Add
            </Button>
            <Button
              onClick={onCancel}
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default WithActions(CollaboratorAdd);
