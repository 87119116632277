import {
  always, identity,
} from 'rambda';

import Router from 'next/router';
import { when } from 'baconjs';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import alertStore from '@/engine/stores/alert-store';
import propertyDataStore from '../data/property.data.store';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

const deleteAction = actionDispatcher.subscribe('property-delete');
const deleteConfirmAction = actionDispatcher.subscribe('property-delete-confirm');

const toDeleteRequest = ({ id }) => {
  return Api.delete(`/api/properties/${id}`);
};

const navigateToList = () => {
  Router.push('/properties');
};

const deleteUpdates = deleteAction
  .map(always({
    title: 'Delete Reno',
    text: 'Are you sure you want to delete this reno?',
    submitAction: 'property-delete-confirm',
    submitText: 'Delete',
  }));

const deleteRequestUpdates = when(
  [propertyDataStore.selectUpdates, deleteConfirmAction],
  identity,
)
  .flatMap(toDeleteRequest);

const deleteSuccessUpdates = deleteRequestUpdates
  .skipErrors();

const deleteFailedUpdates = deleteRequestUpdates
  .errors()
  .mapError(always({
    message: 'Failed to remove property',
    type: 'error',
  }));

const deleteModalUnsubscribe = deleteUpdates.onValue(alertStore.open);
const closeModalUnsubscribe = deleteSuccessUpdates
  .merge(deleteFailedUpdates)
  .onValue(alertStore.close);
const navigateToListUnsubscribe = deleteSuccessUpdates.onValue(navigateToList);
const notificationUnsubscribe = deleteFailedUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      deleteModalUnsubscribe();
      closeModalUnsubscribe();
      navigateToListUnsubscribe();
      notificationUnsubscribe();
    });
  }
}
