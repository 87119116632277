import { objOf, prop } from 'rambda';

import AdminUsersDataStore from '@/data/users/admin-users-data-store';
import stateStore from '@/engine/stores/state-store';

const adminUserStateUpdates = AdminUsersDataStore
  .updates
  .map(prop('items'))
  .map(objOf('users'));

adminUserStateUpdates.onValue(stateStore.Publish('admin-users', 'admin-users'));

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading admin-user-state-store');
    });
  }
}
