import { mergeAll as BaconMergeAll } from 'baconjs';

import {
  merge,
  objOf,
  pickAll,
  prop,
} from 'rambda';

import StateStore from '@/engine/stores/state-store';
import actionDispatcher from '@/engine/actions/action-dispatcher';

import PropertyDataStore from '../data/property.data.store';
import { VIEW_DETAIL, VIEW_TILES } from '../constants';

// functions
const addViewUpdate = (searchUpdate) => {
  if (searchUpdate.searchText) {
    return merge(searchUpdate, { view: VIEW_DETAIL });
  }

  return searchUpdate;
};

const toEmptySearch = () => ({ searchText: '' });

// streams
const selectTilesViewUpdates = actionDispatcher.subscribe('property-budget-tiles-view');

const viewSelectUpdates = BaconMergeAll(
  selectTilesViewUpdates.map(() => ({ view: VIEW_TILES })),
  actionDispatcher.subscribe('property-budget-detail-view').map(() => ({ view: VIEW_DETAIL })),
);

const clearSearchUpdates = actionDispatcher.subscribe('property-budget-search-clear')
  .merge(selectTilesViewUpdates)
  .map(toEmptySearch);

const searchTextUpdates = actionDispatcher.subscribe('property-budget-search')
  .map(prop('value'))
  .map(objOf('searchText'))
  .map(addViewUpdate)
  .merge(clearSearchUpdates);

const searchTextStateUpdates = searchTextUpdates
  .map(prop('searchText'))
  .map(objOf('value'));

// SUBSCRIBERS
searchTextStateUpdates.onValue((state) => StateStore.Publish('properties', 'property-budget-search', state));

export {
  searchTextUpdates,
  viewSelectUpdates,
};
