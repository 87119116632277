import Progress from '../progress';

import WithState from '@/engine/decorators/with-state';

import Icon from '@/components/icon';
import { FormatMoney } from '@/engine/utils/number';

const BudgetProgress = ({
  progress,
  progressFormatted,
  complete,
  className = 'property-detail-progress',
  withBg = false,
  showTotal = false,
  budget = 0,
}) => {
  const progressBarIcon = complete ? (
    <Icon icon="check" size="md" />
  ) : (
    false
  );

  return (
    <>
      <Progress
        className={className}
        percentage={progress > 100 ? progress - 100 : progress}
        text={complete ? '' : progressFormatted}
        icon={progressBarIcon}
        status={progress > 100 ? 'danger' : false}
        withBG={withBg}
      />
      {showTotal && (<div className="text-center text-small font-weight-bold" style={{ fontSize: '12px', color: '#999' }}>{FormatMoney(budget)}</div>)}
    </>
  );
};

export default WithState(BudgetProgress);
