import {
  mergeAll as BaconMergeAll,
  when as BaconWhen,
} from 'baconjs';

import {
  always,
  merge,
  prop,
  propEq,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';
import projectItemDataStore from '../data/project-item-data-store';

const nameUpdateAction = actionDispatcher.subscribe('project-item-name');
const quantityUpdateAction = actionDispatcher.subscribe('project-item-quantity');
const budgetUpdateAction = actionDispatcher.subscribe('project-item-budget');
const spentUpdateAction = actionDispatcher.subscribe('project-item-spent');
const quantityAddAction = actionDispatcher.subscribe('project-item-quantity-add');
const quantityRemoveAction = actionDispatcher.subscribe('project-item-quantity-remove');

const toNameChange = ({ projectId, itemId, value }) => ({
  data: {
    name: value,
  },
  projectId,
  id: itemId,
  valid: !!value,
});

const toQuantityChange = (
  { items },
  {
    projectId,
    itemId,
    value,
    operation,
  },
) => {
  const item = items.find(propEq('id', itemId));
  if (!item) {
    return {
      valid: false,
    };
  }

  let quantity = 0;
  switch (operation) {
    case 'add':
      quantity = item.quantity + 1;
      break;
    case 'remove':
      quantity = item.quantity - 1;
      break;
    default:
      quantity = parseInt(value, 10);
      break;
  }

  let { budget } = item;
  if (quantity > 0) {
    budget = parseInt((item.budget / item.quantity) * quantity, 10);
  }

  return {
    data: {
      quantity,
      budget,
    },
    projectId,
    id: itemId,
    valid: quantity >= 1,
  };
};

const toBudgetChange = ({ projectId, itemId, value }) => ({
  data: {
    budget: value,
  },
  projectId,
  id: itemId,
  valid: true,
});

const toSpentChange = ({ projectId, itemId, value }) => ({
  data: {
    spent: value,
  },
  projectId,
  id: itemId,
  valid: true,
});

const toEditRequest = ({ id, data }) => {
  return Api.put(`/api/project-items/${id}`, data);
};

const toDsChange = ({ id, data }) => {
  return merge({ id }, data);
};

const nameUpdates = nameUpdateAction
  .filter(propEq('event', 'blur'))
  .map(toNameChange);

const allQuantityUpdates = BaconMergeAll(
  quantityUpdateAction.map(merge({ operation: 'fixed' })),
  quantityAddAction.map(merge({ operation: 'add' })),
  quantityRemoveAction.map(merge({ operation: 'remove' })),
)
  .toEventStream();

const quantityUpdates = BaconWhen(
  [projectItemDataStore.updates, allQuantityUpdates],
  toQuantityChange,
);

const budgetUpdates = budgetUpdateAction
  .filter(propEq('event', 'blur'))
  .map(toBudgetChange);

const spentUpdates = spentUpdateAction
  .filter(propEq('event', 'blur'))
  .map(toSpentChange);

const allUpdates = BaconMergeAll(
  nameUpdates,
  quantityUpdates,
  budgetUpdates,
  spentUpdates,
)
  .filter(prop('valid'));

const dsChanges = allUpdates
  .map(toDsChange);

const updateRequests = allUpdates
  .flatMap(toEditRequest);

const failedUpdateRequests = updateRequests
  .errors()
  .mapError(always({ message: 'Failed to save change', type: 'error' }));

const failedUpdatesUnsubscribe = failedUpdateRequests.onValue(notificationStore.publish);
const updateDataStoreUnsubscribe = dsChanges.onValue(projectItemDataStore.update);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      failedUpdatesUnsubscribe();
      updateDataStoreUnsubscribe();
    });
  }
}
