import { forEach } from 'rambda';

import workTrackerItemGroupByCategoryStore from '@/data/work-tracker/work-tracker-item-group-by-category-store';
import stateStore from '@/engine/stores/state-store';

const publishState = ({ items }) => {
  forEach((category) => {
    stateStore.Publish('work-tracker', `work-item-list-${category.id}`, { items: category.items });
  }, items);
};

const listStateUpdates = workTrackerItemGroupByCategoryStore
  .updates;

const listStateUnsubscribe = listStateUpdates.onValue(publishState);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading work-item-state-store');
      listStateUnsubscribe();
    });
  }
}
