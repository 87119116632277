import AppLayout from '@/layouts/app';

import AdminUserList from '../components/admin-list/admin-user-list';

const pageProps = {
  id: 'admin-users',
};

const WorkTackerAdminPage = () => {
  return (
    <AppLayout
      Component={AdminUserList}
      userRole="admin"
      componentProps={pageProps}
    />
  );
};

export default WorkTackerAdminPage;
