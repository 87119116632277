import FilterStore from '@/engine/stores/filter-store';
import projectItemDataStore from './project-item-data-store';

class ProjectItemFilterStore extends FilterStore {
  constructor() {
    super(projectItemDataStore);
  }
}

export default new ProjectItemFilterStore();
