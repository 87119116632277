import { always } from 'rambda';

import Api from '@/engine/utils/api';
import actionDispatcher from '@/engine/actions/action-dispatcher';
import stateStore from '@/engine/stores/state-store';
import { once } from 'baconjs';

const printAction = actionDispatcher.subscribe('property-print');
const printSubmitAction = actionDispatcher.subscribe('property-print-submit');
const printCancelAction = actionDispatcher.subscribe('property-print-cancel');
const cleanupAction = actionDispatcher.subscribe('unmount-property-print');

const propertyBlobs = [];

const cleanUp = () => {
  propertyBlobs.forEach((url) => {
    URL.revokeObjectURL(url);
  });
  propertyBlobs.length = 0;
};

const isIos = () => {
  const ua = navigator.userAgent;
  const result = (/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  return result;
};

const print = (pdfData) => {
  if (isIos()) {
    return;
  }

  const iframeId = 'property-print-iframe';
  if (document.getElementById(iframeId)) {
    cleanUp();
    document.body.removeChild(document.getElementById(iframeId));
  }

  const printIframe = document.createElement('iframe');
  document.body.appendChild(printIframe);

  printIframe.style.position = 'absolute';
  printIframe.style.top = '0';
  printIframe.style.left = '-1000px';
  printIframe.id = iframeId;

  pdfData.blob().then((blob) => {
    const url = URL.createObjectURL(blob);
    propertyBlobs.push(url);

    printIframe.src = url;
    printIframe.onload = () => {
      printIframe?.focus();
      printIframe?.contentWindow?.print();
    };
  });
};

const toPrintModalVisible = ({ propertyId }) => {
  return {
    propertyId,
    isVisible: true,
  };
};

const toPdfRequest = ({
  propertyId,
  details,
  projects,
  tradeTracker,
  projectToPage,
  tradeTrackerNewPage,
}) => {
  if (isIos()) {
    window.open(`/api/properties/${propertyId}/print?details=${details}&projects=${projects}&tradeTracker=${tradeTracker}&tradeTrackerNewPage=${tradeTrackerNewPage}&projectToPage=${projectToPage}`, '_blank');
    return once({});
  }

  return Api.postBase64(`/api/properties/${propertyId}/print`, {
    details,
    projects,
    tradeTracker,
    projectToPage,
    tradeTrackerNewPage,
  });
};

const printSubmitUnsubscribe = printSubmitAction
  .flatMapLatest(toPdfRequest)
  .onValue(print);

const printCancelUnsubscribe = printCancelAction
  .merge(printSubmitAction)
  .map(always({ isVisible: false }))
  .onValue(stateStore.Publish('common', 'property-print-modal'));

const printUnsubscribe = printAction
  .map(toPrintModalVisible)
  .onValue(stateStore.Publish('common', 'property-print-modal'));

const cleanUpUnsubscribe = cleanupAction
  .onValue(cleanUp);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading property-print-store');
      cleanUp();
      printUnsubscribe();
      printCancelUnsubscribe();
      printSubmitUnsubscribe();
      cleanUpUnsubscribe();
    });
  }
}
