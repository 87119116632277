import AnonymousLayout from '@/layouts/anonymous';

import ActivateAccount from '../components/activate-account';

const pageProps = {
  id: 'activate-account-page',
};

const ActivateAccountPage = () => (
  <AnonymousLayout Component={ActivateAccount} componentProps={pageProps} />
);

export default ActivateAccountPage;
