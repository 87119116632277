import { always, objOf, prop } from 'rambda';
import workTrackerItemDataStore from '@/data/work-tracker/work-tracker-admin-item-data-store';
import actionDispatcher from '@/engine/actions/action-dispatcher';
import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

const deleteAction = actionDispatcher.subscribe('work-item-admin-remove');

const toDeleteRequest = ({ id }) => {
  return Api.delete(`/api/admin/work-tracker/items/${id}`);
};

const deleteUpdates = deleteAction
  .map(prop('itemId'))
  .map(objOf('id'));

const deleteFailedRequestUpdates = deleteUpdates
  .flatMap(toDeleteRequest)
  .errors()
  .mapError(always({ message: 'Failed to delete item', type: 'error' }));

const deleteFromDataStoreUnsubscribe = deleteUpdates.onValue(workTrackerItemDataStore.remove);
const deleteFailedUnsubscribe = deleteFailedRequestUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading admin-work-item-delete-store');
      deleteFromDataStoreUnsubscribe();
      deleteFailedUnsubscribe();
    });
  }
}
