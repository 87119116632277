import { when as BaconWhen, fromPromise } from 'baconjs';
import { identity } from 'rambda';
import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import ImageTools from '@/engine/utils/image-tools';
import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

import propertyDataStore from '../data/property.data.store';

const saveAction = actionDispatcher.subscribe('property-save');
const editAction = actionDispatcher.subscribe('property-edit');
const editImageAction = actionDispatcher.subscribe('property-edit-image');

const toSaveUpdate = ({ id }) => ({
  propertyId: id,
});

const getImage = ({ file, resize }) => {
  return fromPromise(ImageTools.resize(file, resize));
};

const getBase64Image = (blob) => {
  return fromPromise(new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (data) => {
      resolve({
        data: btoa(data.target.result),
        contentType: blob.type,
      });
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsBinaryString(blob);
  }));
};

const toImageSaveUpdate = ({ id }, { data, contentType }) => ({
  id,
  data,
  contentType,
});

const toImageEdit = ({ id, data, contentType }) => {
  const blob = ImageTools.imageDataToBlob(
    data,
    contentType,
  );
  const blobUrl = URL.createObjectURL(blob);

  return {
    id,
    hasImage: true,
    imageUrl: blobUrl,
  };
};

const toImageUploadRequest = ({ id, data, contentType }) => {
  return Api.post(`/api/properties/${id}/image`, { data, contentType });
};

const navigateToProperty = ({ propertyId }) => {
  Router.push(`/properties/${propertyId}`);
};

const navigateToEdit = ({ propertyId }) => {
  Router.push(`/properties/${propertyId}/edit`);
};

const saveUpdates = BaconWhen(
  [propertyDataStore.selectUpdates.toProperty(), saveAction],
  toSaveUpdate,
);

const imageUpdates = editImageAction
  .flatMapLatest(getImage)
  .flatMapLatest(getBase64Image);

const saveImageUpdates = BaconWhen(
  [propertyDataStore.selectUpdates.toProperty(), imageUpdates],
  toImageSaveUpdate,
);

const saveImageRequestUpdates = saveImageUpdates
  .flatMapLatest(toImageUploadRequest);

const saveRequestFailedUpdates = saveImageRequestUpdates
  .errors()
  .mapError(identity);

editAction.onValue(navigateToEdit);
saveUpdates.onValue(navigateToProperty);
saveImageUpdates
  .map(toImageEdit)
  .onValue((data) => propertyDataStore.update(data));

saveRequestFailedUpdates.onValue(() => {
  notificationStore.publish({ type: 'error', message: 'Failed to save photo.' });
});
