const passwordValidator = (value) => {
  // minimum length
  if ((value?.length ?? 0) < 8) {
    return false;
  }

  // must contain at least one uppercase letter
  if (!/[A-Z]/.test(value)) {
    return false;
  }

  // must contain at least one lowercase letter
  if (!/[a-z]/.test(value)) {
    return false;
  }

  // must contain at least one number
  if (!/[0-9]/.test(value)) {
    return false;
  }

  // must contain at least one special character
  if (!/[^A-Za-z0-9]/.test(value)) {
    return false;
  }

  // should not contain whitespace
  if (/[\s]/.test(value)) {
    return false;
  }

  return true;
};

export default passwordValidator;
