import { fromPromise } from 'baconjs';

const Api = {
  get: (url) => {
    return fromPromise(fetch(url, {
      method: 'get',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Server Error');
        }
        return response.json();
      }).catch(() => {
        throw new Error('Server Error');
      }));
  },
  post: (url, data) => {
    return fromPromise(fetch(url, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Server Error');
        }
        return response.json();
      })
      .catch(() => {
        throw new Error('Server Error');
      }));
  },
  postBase64: (url, data) => {
    return fromPromise(fetch(url, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Server Error');
        }
        return response;
      })
      .catch(() => {
        throw new Error('Server Error');
      }));
  },
  put: (url, data) => {
    return fromPromise(fetch(url, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Server Error');
        }
        return response.json();
      })
      .catch(() => {
        throw new Error('Server Error');
      }));
  },
  delete: (url) => {
    return fromPromise(fetch(url, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Server Error');
        }
        return response.json();
      })
      .catch(() => {
        throw new Error('Server Error');
      }));
  },
};

export default Api;
