import {
  always,
  findIndex,
  flatten,
  forEach,
  map,
  omit,
  prop,
  propEq,
} from 'rambda';
import { when as BaconWhen } from 'baconjs';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

import budgetSuggestionsDataStore from '@/data/budget/budget-suggestions-data-store';

import projectDataStore from '../data/project.data.store';
import projectItemDataStore from '../data/project-item-data-store';
import projectPhotoDataStore from '@/data/projects/project-photo-data-store';

const loadAction = actionDispatcher.subscribe('project-page')
  .filter(propEq('event', 'load'));

const toLoadRequest = ({ id }) => {
  return Api.get(`/api/projects/${id}`);
};

const toLoadUpdates = ({ items }, { id }) => {
  const itemIndex = findIndex(propEq('id', id), items);
  if (itemIndex >= 0) {
    return null;
  }

  return {
    id,
  };
};

const loadProjects = (projects) => {
  projectDataStore.load(map(omit(['activeItems', 'optionalItems', 'photos']), projects) || []);
  projectItemDataStore.load(flatten(map(prop('activeItems'), projects)) || []);
  budgetSuggestionsDataStore.load(flatten(map(prop('optionalItems'), projects)) || []);
  projectPhotoDataStore.load(flatten(map(prop('photos'), projects)) || []);
};

const loadProject = (project) => {
  projectDataStore.insert(omit(['activeItems', 'optionalItems', 'photos'], project));
  forEach((item) => {
    projectItemDataStore.insert(item);
  }, project.activeItems);

  forEach((item) => {
    budgetSuggestionsDataStore.insert(item);
  }, project.optionalItems);

  forEach((item) => {
    projectPhotoDataStore.insert(item);
  }, project.photos);
};

const loadDataStore = ({ data }) => {
  loadProjects([data]);
};

const select = ({ id }) => {
  projectDataStore.select(id);
};

const clear = () => {
  projectDataStore.clear();
};

const loadUpdates = BaconWhen(
  [projectDataStore.updates.toProperty(), loadAction],
  toLoadUpdates,
)
  .filter(prop('id'));

const loadProjectRequestUpdates = loadUpdates
  .flatMapLatest(toLoadRequest);

const failedLoadUpdates = loadProjectRequestUpdates
  .errors()
  .mapError(always({ message: 'Failed to load project', type: 'error' }));

const loadProjectSuccessUpdates = loadProjectRequestUpdates
  .skipErrors();

const failUnsubscribe = failedLoadUpdates.onValue(notificationStore.publish);
const selectUnsubscribe = loadAction.onValue(select);
const clearUnsubscribe = loadUpdates.onValue(clear);
const loadUnsubscribe = loadProjectSuccessUpdates.onValue(loadDataStore);

export const LoadProjects = loadProjects;
export const LoadProject = loadProject;

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading project-load-store');
      failUnsubscribe();
      selectUnsubscribe();
      clearUnsubscribe();
      loadUnsubscribe();
    });
  }
}
