import {
  always, identity,
} from 'rambda';

import Router from 'next/router';
import { when } from 'baconjs';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import alertStore from '@/engine/stores/alert-store';
import templateDataStore from '@/data/templates/template-data-store';

import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

const deleteAction = actionDispatcher.subscribe('admin-template-remove');
const deleteConfirmAction = actionDispatcher.subscribe('admin-template-remove-confirm');

const toDeleteRequest = ({ id }) => {
  return Api.delete(`/api/admin/templates/${id}`);
};

const navigateToList = () => {
  Router.push('/admin/templates');
};

const deleteUpdates = deleteAction
  .map(always({
    title: 'Delete Template',
    text: 'Are you sure you want to delete this template?',
    submitAction: 'admin-template-remove-confirm',
    submitText: 'Delete',
  }));

const deleteRequestUpdates = when(
  [templateDataStore.selectUpdates, deleteConfirmAction],
  identity,
)
  .flatMap(toDeleteRequest);

const deleteSuccessUpdates = deleteRequestUpdates
  .skipErrors();

const deleteFailedUpdates = deleteRequestUpdates
  .errors()
  .mapError(always({
    message: 'Failed to remove template',
    type: 'error',
  }));

const deleteModalUnsubscribe = deleteUpdates.onValue(alertStore.open);
const closeModalUnsubscribe = deleteSuccessUpdates
  .merge(deleteFailedUpdates)
  .onValue(alertStore.close);
const navigateToListUnsubscribe = deleteSuccessUpdates.onValue(navigateToList);
const notificationUnsubscribe = deleteFailedUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      deleteModalUnsubscribe();
      closeModalUnsubscribe();
      navigateToListUnsubscribe();
      notificationUnsubscribe();
    });
  }
}
