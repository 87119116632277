import { useRouter } from 'next/router';
import { useEffect } from 'react';

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';

import Plus from '@/images/white-plus.inline.svg';

import Button from '@/components/button';
import Loading from '@/components/loading';
import WithActions from '@/engine/decorators/with-actions';
import WithState from '@/engine/decorators/with-state';

const ProjectAdd = ({ templates, publish }) => {
  const router = useRouter();
  const { id } = router.query;

  useEffect(() => {
    publish({ event: 'load' });
  }, []);

  if (!templates) {
    return <Loading />;
  }

  const selectTemplate = (e) => {
    const templateId = e.currentTarget.getAttribute('data-id');
    publish({ event: 'selectTemplate', templateId, propertyId: id });
  };

  return (
    <>
      <div className="back-button-top-container">
        <Button
          id="navigate-property"
          actionMetaData={{ propertyId: id }}
          swatch
          color="secondary"
          className="back-button-top"
          size="sm"
        >
          Back
        </Button>
      </div>
      <h1 className="swatch-title swatch-title--main">
        <small>Choose Budget</small>
        Category
      </h1>
      <Row className="row-space-items">
        {templates?.map((template) => (
          <Col xs="6" sm="4" md="3" xl="2" key={template.id}>
            <span
              data-id={template.id}
              className="as-link"
              onClick={selectTemplate}
            >
              <Card className="project card-raised h-100">
                <div className="project-preview-image project-preview-image-default" />
                <CardBody className="project-preview-details p-3">
                  <CardTitle className="mb-0">
                    <span className="as-link">{template.name}</span>
                  </CardTitle>
                </CardBody>
              </Card>
            </span>
          </Col>
        ))}

        <Col
          xs="6"
          sm="4"
          md="3"
          xl="2"
          className="align-items-center d-flex justify-content-center"
        >
          <Button
            id="project-add-custom"
            actionMetaData={{ propertyId: id }}
            size="md"
            swatch="xl"
            color="primary"
          >
            <Plus className="btn-swatch__icon btn-swatch__icon--larger" />
            <br />
            <span>
              Custom
              <br />
              Category
            </span>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default WithState(WithActions(ProjectAdd));
