import { when as BaconWhen } from 'baconjs';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';

import propertyDataStore from '../data/property.data.store';

import { STATUS_ACTIVE, STATUS_COMPLETED } from '@/shared/constants';

const statusToggleAction = actionDispatcher.subscribe('property-status-toggle');

const toStatusUpdate = (property) => ({
  id: property.id,
  status: property.status === STATUS_COMPLETED ? STATUS_ACTIVE : STATUS_COMPLETED,
  completeDate: property.status === STATUS_ACTIVE ? new Date() : null,
});

const toStatusUpdateRequest = (statusUpdate) => Api.post(`/api/properties/${statusUpdate.id}/status/${statusUpdate.status}`);

const statusToggleUpdates = BaconWhen(
  [propertyDataStore.selectUpdates, statusToggleAction],
  toStatusUpdate,
);

const statusRequestUpdates = statusToggleUpdates.flatMap(toStatusUpdateRequest);

// listeners
statusRequestUpdates.onValue();
statusToggleUpdates.onValue(propertyDataStore.update);
