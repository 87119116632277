import { useState } from 'react';
import {
  FormGroup,
  Label,
  Table,
} from 'reactstrap';

import Button from '@/components/button';
import ProjectItem from '@/components/project-item';
import TextArea from '@/components/text-area';
import WithState from '@/engine/decorators/with-state';

import Plus from '@/components/plus';
import SuggestionItem from '@/components/suggestion-item/suggestion-item';

const renderProjectItems = (activeItems) => {
  return activeItems?.map((item) => {
    return <ProjectItem key={item.id} projectId={item.projectId} item={item} />;
  });
};

const ProjectBudget = ({ project, items, suggestions }) => {
  const [showingSuggestions, setShowingSuggestions] = useState(false);

  const showSuggestions = () => {
    setShowingSuggestions(!showingSuggestions);
  };

  const renderSuggestions = () => {
    return suggestions.map((suggestion) => (
      <SuggestionItem key={suggestion.id} suggestion={suggestion} />
    ));
  };

  return (
    <>
      <div className="mb-4">
        <table className="list-items list-items--transformer table-borderless table-headed table table-sm">
          <thead className="list-items__head">
            <tr className="list-items__head-row">
              <td style={{ width: '50%' }} className="list-items__head-description">
                <h5>
                  <span className="mr-2">
                    Items
                  </span>
                </h5>
              </td>
              <td className="allcaps text-center h6 list-items__head-quantity">Qty.</td>
              <td className="allcaps text-right h6 minw-5 list-items__head-budget">Budget</td>
              <td className="allcaps text-right h6 minw-5 list-items__head-spent">Spent</td>
              <td className="allcaps text-right h6 list-items__head-available">Left</td>
              <td className="allcaps text-right h6 list-items__head-actions">&nbsp;</td>
            </tr>
          </thead>
          <tbody className="list-items__body">
            {renderProjectItems(items)}
            <tr className="budget-item-row budget-item-row--add">
              <td colSpan="6">
                <Button
                  id="project-budget-add"
                  color="primary"
                  className="mr-2"
                  actionMetaData={{ projectId: project.id }}
                >
                  <Plus className="btn-swatch__icon mr-2" />
                  New Item
                </Button>
                <Button
                  id="project-budget-show-suggestions"
                  onClick={showSuggestions}
                  color="secondary"
                  size="sm"
                  className="italic px-1"
                >
                  {showingSuggestions ? 'Hide ' : 'Show '}
                  suggestions
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {(showingSuggestions) && (
        <div className="mb-4">
          <Table
            size="sm"
            responsive
            className="list-items table-accentuated table-borderless table-headed"
          >
            <thead className="list-items__head">
              <tr className="list-items__head-row">
                <td style={{ width: '60%' }} className="list-items__head-description">
                  <h5>
                    <span className="mr-2">Suggestions</span>
                  </h5>
                </td>
                <td className="text-right">
                  <Label>Guide</Label>
                </td>
                <td className="text-right">{/* Actions */}</td>
              </tr>
            </thead>
            <tbody className="list-items__body">
              {suggestions.length > 0 && renderSuggestions()}
              {suggestions.length === 0 && (
                <tr>
                  <td colSpan="3">
                    <div className="p-3 small text-center">
                      Looks like you&apos;ve added all the suggested items for this category!
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      )}
      <FormGroup>
        <TextArea
          id="project-notes"
          autosize
          hasMinHeight
          label="Notes"
          value={project.notes}
          node={project}
        />
      </FormGroup>
      <FormGroup>
        <TextArea
          id="project-scope"
          autosize
          hasMinHeight
          label="Scope of work"
          value={project.scopeOfWork}
          node={project}
        />
      </FormGroup>
    </>
  );
};

export default WithState(ProjectBudget);
