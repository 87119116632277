import { Row, Col, Label } from 'reactstrap';
import classNames from 'classnames';

import WithState from '@/engine/decorators/with-state';

import Button from '@/components/button';
import Icon from '@/components/icon';
import Input from '@/components/input';

import { VIEW_DETAIL, VIEW_TILES } from '../../constants';

import PropertyBudgetTiles from './property-budget-tiles';
import PropertyBudgetDetail from './property-budget-detail';

const PropertyBudget = ({
  projects,
  searchText = '',
  view,
}) => {
  const renderBudgetSearch = () => {
    return (
      <Row className="mb-4 align-items-center">
        <Col xs={{ size: '12', order: '2' }} sm className="d-flex">
          <Input
            id="property-budget-search"
            placeholder="Find a budget item"
          />
          {searchText && (
            <Button id="property-budget-search-clear" className="nowrap">
              <Icon icon="clear" />
              <span className="d-none d-md-inline"> Clear Search</span>
            </Button>
          )}
        </Col>
        <Col
          xs={{ size: '12', order: '1' }}
          sm={{ size: 'auto', order: '3' }}
          className="mb-2 mb-sm-0"
        >
          <Label className="mr-2">View</Label>
          <Button
            className={classNames({ active: view === VIEW_TILES })}
            id="property-budget-tiles-view"
            title="Tiles View"
          >
            <Icon icon="view_module" size="md" />
          </Button>
          <Button
            className={classNames({ active: view === VIEW_DETAIL })}
            id="property-budget-detail-view"
            title="Detail View"
          >
            <Icon icon="list" size="md" />
          </Button>
        </Col>
      </Row>
    );
  };

  if (!projects) {
    return null;
  }

  return (
    <>
      {projects && projects.length > 0 ? renderBudgetSearch() : ''}
      {view === VIEW_TILES && (
        <PropertyBudgetTiles projects={projects} />
      )}
      {view === VIEW_DETAIL && (
        <PropertyBudgetDetail projects={projects} searchText={searchText} />
      )}
    </>
  );
};

export default WithState(PropertyBudget);
