/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import classNames from 'classnames';
import { Button as ReactstrapButton } from 'reactstrap';

import WithActions from '@/engine/decorators/with-actions';

import Swatch from '@/images/square-paint-swatch.inline.svg';

const Button = ({
  children,
  onClick,
  swatch,
  icon,
  className,
  publish,
  disabled,
  disableOnFirstClick = false,
  ...props
}) => {
  const [disabledInternal, setDisabbledInternal] = useState(!!disabled);

  const buttonClicked = (evt) => {
    evt.preventDefault();
    if (disableOnFirstClick) {
      setDisabbledInternal(true);
    }
    publish({ type: 'click', name: props.name });
    if (onClick) {
      onClick(evt);
    }
  };

  return (
    <ReactstrapButton
      className={classNames(className, {
        'btn-swatch': swatch,
        'btn-swatch-primary': swatch && props.color === 'primary',
        'btn-swatch-secondary': swatch && props.color === 'secondary',
        'btn-swatch-lg': swatch === 'lg',
        'btn-swatch-xl': swatch === 'xl',
        'btn-icon': icon,
      })}
      onClick={buttonClicked}
      disabled={disabledInternal}
      {...props}
    >
      {swatch && <Swatch className="btn__swatch" />}
      {children}
    </ReactstrapButton>
  );
};

export default WithActions(Button);
