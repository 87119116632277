import AppLayout from '@/layouts/app';

import WorkTrackerAdmin from '../components/admin/work-tracker-admin';

const pageProps = {
  id: 'work-tracker-admin-categories',
};

const WorkTackerAdminPage = () => {
  return (
    <AppLayout
      Component={WorkTrackerAdmin}
      userRole="admin"
      componentProps={pageProps}
    />
  );
};

export default WorkTackerAdminPage;
