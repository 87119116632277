import classNames from 'classnames';

import {
  Container,
} from 'reactstrap';

import WithActions from '@/engine/decorators/with-actions';
import WithUser from '@/engine/decorators/with-user';

import Avatar, { ALL_AVATARS } from '@/components/avatar';

const AvatarList = ({ user, publish }) => {
  const { avatar } = user;

  const changeAvatar = (newAvatar) => {
    publish({ avatar: newAvatar });
  };

  return (
    <Container className="container-fluid-max mt-4 mb-5" fluid>
      <div className="text-center">
        <h1 className="swatch-title swatch-title--main ">
          <small>
            Choose your
            <br />
          </small>
          Avatar
        </h1>
      </div>

      <div className="avatar-select-list">
        {ALL_AVATARS.map((a) => (
          <div
            key={a}
            className={classNames('avatar-select', {
              'avatar-select--active': avatar === a,
            })}
          >
            <label htmlFor={a}>
              <input
                id={a}
                type="radio"
                value={a}
                checked={avatar === a}
                onChange={() => {
                  changeAvatar(a);
                }}
              />
              <Avatar id={a} />
            </label>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default WithUser(WithActions(AvatarList));
