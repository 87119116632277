import AppLayout from '@/layouts/app';

import TemplateAdminList from '../components/admin-list/template-admin';

const pageProps = {
  id: 'template-admin-list',
};

const TemplatesAdminPage = () => {
  return (
    <AppLayout
      Component={TemplateAdminList}
      userRole="admin"
      componentProps={pageProps}
    />
  );
};

export default TemplatesAdminPage;
