/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';

import CircularProgressbar from 'react-circular-progressbar';

const Progress = ({
  withBG,
  className,
  style = 'circular',
  icon,
  status,
  ...props
}) => (
  <span
    className={classNames(className, 'progress-wrapper', {
      'progress-wrapper--circular': style === 'circular',
      'progress-wrapper--with-bg': withBG,
      'progress-wrapper--danger': status === 'danger',
    })}
  >
    {style === 'circular' && <CircularProgressbar {...props} />}
    {icon && <span className="progress__icon">{icon}</span>}
  </span>
);

export default Progress;
