import { useEffect } from 'react';

import WithState from '@/engine/decorators/with-state';
import AdminUserListItem from './admin-user-list-item';
import WithActions from '@/engine/decorators/with-actions';

const AdminUserList = ({ publish, users }) => {
  useEffect(() => {
    publish({ event: 'load' });
  }, []);

  return (
    <>
      <div className="text-center">
        <h1 className="swatch-title swatch-title--main">Users</h1>
      </div>
      <table className="list-items list-items--transformer table-borderless table-headed table table-sm">
        <thead className="list-items__head">
          <tr className="list-items__head-row">
            <td style={{ width: '50%' }} className="allcaps h6">Full Name</td>
            <td className="allcaps h6">Email</td>
            <td className="allcaps h6">Account Type</td>
            <td className="allcaps h6">Create Date</td>
            <td className="allcaps h6">Activation Date</td>
            <td className="allcaps h6">Last Login</td>
            <td style={{ width: '75px' }} />
          </tr>
        </thead>
        <tbody className="list-items__body">
          {users?.map((user) => (
            <AdminUserListItem id="adminUser" key={user.id} user={user} />
          ) ?? <tr><td colSpan="4">No users found</td></tr>)}
        </tbody>
      </table>
    </>
  );
};

export default WithActions(WithState(AdminUserList));
