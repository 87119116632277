import { useState } from 'react';

import CurrencyInput from 'react-currency-input-field';
import classNames from 'classnames';

import { FormatMoney } from '@/engine/utils/number';

import Button from '@/components/button';
import Icon from '@/components/icon';

import WithActions from '@/engine/decorators/with-actions';

import WorkItemMenu from './work-item-menu';

const makeNumber = (value) => {
  if (value === null || value === undefined) {
    return 0;
  }

  let result = Number(value);
  if (Number.isNaN(result)) {
    result = 0;
  }

  return result;
};

const WorkItemMoney = ({ publish, item, isAdmin }) => {
  const [internalValue, setInternalValue] = useState(makeNumber(item.value) ?? 0);
  const [itemExpanded, setItemExpanded] = useState(false);

  const openItem = () => {
    setItemExpanded(!itemExpanded);
  };

  const onValueChange = (value) => {
    if (value !== internalValue) {
      publish({ value: value ?? 0, itemId: item.id });
      setInternalValue(value ?? 0);
    }
  };

  return (
    <tr
      key={item.id}
      className={classNames(
        'budget-item-row',
        'budget-item-row--default',
        {
          'is-expanded': itemExpanded,
        },
      )}
    >
      <td className="budget-item-row__description">
        <div
          onClick={openItem}
          className="match-input w-100"
        >
          {item.label}
        </div>
        <span className="d-md-none d-inline-flex">
          <span className="pl-2 mt-1">
            <div className="nowrap">{FormatMoney(internalValue) || '-'}</div>
          </span>
          <Button
            color="none"
            size="sm"
            onClick={openItem}
            className="btn-circle p-0 ml-2"
          >
            <Icon
              icon={itemExpanded ? 'expand_less' : 'expand_more'}
              size="md"
            />
          </Button>
        </span>
      </td>
      <td className="text-right budget-item-row__spent budget-item-bit__mob-show-for-expanded">
        <label htmlFor="" className="budget-item-row__label">
          Value
        </label>
        <CurrencyInput
          allowDecimals={false}
          allowNegativeValue={false}
          value={internalValue}
          onValueChange={onValueChange}
          placeholder="$0"
          intlConfig={{ locale: 'en-NZ', currency: 'NZD' }}
          prefix="$"
          decimalSeparator="."
          groupSeparator=","
          className="form-control form-control-no-browser-helpers"
        />
      </td>
      <td className="budget-item-row__actions budget-item-bit__mob-show-for-expanded mt-2 mt-md-0">
        <WorkItemMenu isAdmin={isAdmin} item={item} />
      </td>
    </tr>
  );
};

export default WithActions(WorkItemMoney, { actionKey: 'work-item-update' });
