import React from 'react';
import classnames from 'classnames';

import BlackwithOne from './images/BlackwithOne.inline.svg';
import Brainy from './images/Brainy.inline.svg';
import CHAT from './images/CHAT.inline.svg';
import Corny from './images/Corny.inline.svg';
import CurlyMagee from './images/CurlyMagee.inline.svg';
import GetOnYa from './images/GetOnYa.inline.svg';
import GlassesPerson from './images/GlassesPerson.inline.svg';
import Hammerhead from './images/Hammerhead.inline.svg';
import Plain from './images/Plain.inline.svg';
import Sparkles from './images/Sparkles.inline.svg';
import Sweetlittledoggo from './images/Sweetlittledoggo.inline.svg';
import Thumbs from './images/Thumbs.inline.svg';
import Tree from './images/Tree.inline.svg';
import Whilemyguitargentlyweeps from './images/Whilemyguitargentlyweeps.inline.svg';

export const avatars = {
  BlackwithOne,
  Brainy,
  CHAT,
  Corny,
  CurlyMagee,
  GetOnYa,
  GlassesPerson,
  Hammerhead,
  Plain,
  Sparkles,
  Sweetlittledoggo,
  Thumbs,
  Tree,
  Whilemyguitargentlyweeps,
};

export const ALL_AVATARS = Object.keys(avatars);

const Avatar = ({ id, size, className }) => {
  const AvatarSVG = avatars[id] || Thumbs;
  return (
    <div
      className={classnames(
        className,
        'avatar',
        { 'avatar-sm': size === 'small' },
        { 'avatar-lg': size === 'large' },
      )}
    >
      <AvatarSVG />
    </div>
  );
};

export default Avatar;
