import AnonymousLayout from '@/layouts/anonymous';

import RegisterForm from '../components/register-form';

const pageProps = {
  id: 'register-page',
};

const RegisterPage = () => (
  <AnonymousLayout Component={RegisterForm} componentProps={pageProps} />
);

export default RegisterPage;
