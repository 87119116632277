import { mergeAll as BaconMergeAll } from 'baconjs';

import {
  merge,
  objOf,
  pick,
  prop,
  propEq,
} from 'rambda';

import stateStore from '@/engine/stores/state-store';
import actionDispatcher from '@/engine/actions/action-dispatcher';

import propertyDataStore from '../data/property.data.store';
import projectDataStore from '../../project/data/project.data.store';
import projectItemGroupByProjectStore from '../../project/data/project-item-group-by-project-store';
import { viewSelectUpdates, searchTextUpdates } from './property.budget.store';

import { VIEW_BUDGET, VIEW_TILES } from '../constants';

const viewChanges = actionDispatcher.subscribe('property').filter(propEq('event', 'view'));

const toHasProjects = ({ items }) => ({
  hasProjects: items?.length > 0,
});

const viewUpdates = viewChanges.map(pick(['view']));

const hasProjectUpdates = projectDataStore
  .updates
  .toEventStream()
  .map(toHasProjects);

const stateUpdates = BaconMergeAll(
  propertyDataStore.selectUpdates.toEventStream().map(objOf('property')),
  viewUpdates,
  hasProjectUpdates,
).scan({ view: VIEW_BUDGET }, merge);

const projectUpdates = projectItemGroupByProjectStore
  .updates
  .map(prop('items'))
  .map(objOf('projects'));

const budgetStateUpdates = BaconMergeAll(
  projectUpdates,
  searchTextUpdates,
  viewSelectUpdates,
)
  .scan({ view: VIEW_TILES, searchText: '', projects: [] }, merge);

const propertiesStateUnsubscribe = stateUpdates.onValue(stateStore.Publish('properties', 'property'));
const budgetStateUnsubscribe = budgetStateUpdates.onValue(stateStore.Publish('properties', 'property-budget'));

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading property-state-store');
      propertiesStateUnsubscribe();
      budgetStateUnsubscribe();
    });
  }
}
