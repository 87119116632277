/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Col,
  FormGroup,
  Row,
} from 'reactstrap';
import { map } from 'rambda';

import WithState from '@/engine/decorators/with-state';

import Button from '@/components/button';
import Icon from '@/components/icon';
import Plus from '@/components/plus';
import ImageField from '@/components/image-field';

const ProjectPhotos = ({ photos, projectId }) => {
  if (!photos) {
    return null;
  }

  const renderPhotoRow = ({ id, beforeImageUrl, afterImageUrl }) => {
    return (
      <div key={id}>
        <Row>
          <Col sm="6">
            <FormGroup>
              <label>Before Image</label>
              {beforeImageUrl && (
                <Button
                  id="project-photo-delete"
                  actionMetaData={{ photoId: id, type: 'before' }}
                  icon
                  title="Delete this image"
                  className="text-danger"
                >
                  <Icon icon="delete" className="mr-2" />
                  Delete Image
                </Button>
              )}

              <ImageField
                id="project-photo-edit"
                actionMetaData={{ photoId: id, type: 'before' }}
                fileUrl={beforeImageUrl}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup>
              <label>After Image</label>
              {afterImageUrl && (
                <Button
                  id="project-photo-delete"
                  actionMetaData={{ photoId: id, type: 'after' }}
                  icon
                  title="Delete this image"
                  className="text-danger"
                >
                  <Icon icon="delete" className="mr-2" />
                  Delete Image
                </Button>
              )}

              <ImageField
                id="project-photo-edit"
                actionMetaData={{ photoId: id, type: 'after' }}
                fileUrl={afterImageUrl}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      {map(renderPhotoRow, photos)}
      <Row>
        <Col sm="12 justify-content-center" style={{ marginBottom: '30px' }}>
          <Button
            id="project-photo-add"
            actionMetaData={{ projectId }}
            color="primary"
            className="mr-2"
          >
            <Plus className="btn-swatch__icon mr-2" />
            Add more
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default WithState(ProjectPhotos, { stateKey: 'project-photos' });
