import { useState } from 'react';
import Link from 'next/link';

import {
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import WithUser from '@/engine/decorators/with-user';

import Avatar from './avatar';
import Icon from './icon';
import RenoLink from './link';

import Logo from '@/images/my-properties-square-white.inline.svg';

const AppBar = ({ user }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="appbar">
      <Nav className="align-items-end">
        <NavItem className="appbar__nav-item">
          <Link href="/" passHref>
            <a className="appbar__apptitle">
              <Logo className="appbar__logo mr-2" />
              <span className="h4 mb-0 mt-4">RenoTracker</span>
            </a>
          </Link>
        </NavItem>

        <NavItem className="appbar__nav-item ml-auto">
          {/* left / right divider */}
        </NavItem>

        <NavItem className="appbar__nav-item d-none d-md-block pb-1">
          <Link href="/properties">My Properties</Link>
        </NavItem>
        {user.roles?.admin && (
          <>
            <NavItem className="appbar__nav-item d-none d-md-block pb-1">
              <Link href="/admin/templates">Templates</Link>
            </NavItem>
            <NavItem className="appbar__nav-item d-none d-md-block pb-1">
              <Link href="/admin/trade-tracker">Trade Tracker</Link>
            </NavItem>
            <NavItem className="appbar__nav-item d-none d-md-block pb-1">
              <Link href="/admin/users">Users</Link>
            </NavItem>
          </>
        )}
        {user.avatar && (
          <NavItem className="appbar__nav-item">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={dropdownToggle}
              direction="right"
            >
              <DropdownToggle
                className="btn-none p-0"
                title="Move to another category"
              >
                <Avatar
                  className="d-inline-block"
                  size="small"
                  id={user.avatar}
                />
                <Icon icon="more_vert" size="md" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-right">
                <DropdownItem className="d-block d-md-none">
                  <Link href="/">My Properties</Link>
                </DropdownItem>
                {user.roles?.admin && (
                  <DropdownItem className="d-block d-md-none">
                    <Link href="/admin/templates">Templates</Link>
                  </DropdownItem>
                )}
                {user.roles?.admin && (
                  <DropdownItem className="d-block d-md-none">
                    <Link href="/admin/trade-tracker">Trade Tracker</Link>
                  </DropdownItem>
                )}
                {user.roles?.admin && (
                  <DropdownItem className="d-block d-md-none">
                    <Link href="/admin/users">Users</Link>
                  </DropdownItem>
                )}
                {user.imping && (
                  <DropdownItem>
                    <RenoLink id="imp-stop">Stop Impersonation</RenoLink>
                  </DropdownItem>
                )}
                <DropdownItem>
                  <Link href="/profile">My Profile</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link href="/profile/avatar">Change my avatar</Link>
                </DropdownItem>
                <DropdownItem>
                  <a target="_blank" href="/RenoTracker Terms of Use - Software Licence v1 230624.pdf">Terms Of Use</a>
                </DropdownItem>
                <DropdownItem>
                  <RenoLink id="logout">Logout</RenoLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavItem>
        )}
      </Nav>
    </div>
  );
};

export default WithUser(AppBar);
