import { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import Button from '../button';
import WithState from '@/engine/decorators/with-state';

const PrintModal = ({
  isVisible,
  className,
  propertyId,
}) => {
  const [details, setDetails] = useState(true);
  const [tradeTracker, setTradeTracker] = useState(true);
  const [projects, setProjects] = useState(true);
  const [projectToPage, setProjectToPage] = useState(false);
  const [tradeTrackerNewPage, setTradeTrackerNewPage] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      setDetails(true);
      setTradeTracker(true);
      setProjects(true);
      setProjectToPage(false);
      setTradeTrackerNewPage(false);
    }
  }, [isVisible]);

  const detailsChanged = () => {
    setDetails(!details);
  };

  const tradeTrackerChanged = () => {
    setTradeTracker(!tradeTracker);
  };

  const projectsChanged = () => {
    setProjects(!projects);
  };

  const projectToPageChanged = () => {
    setProjectToPage(!projectToPage);
  };

  const tradeTrackerNewPageChanged = () => {
    setTradeTrackerNewPage(!tradeTrackerNewPage);
  };

  return (
    <Modal isOpen={isVisible} className={className}>
      <ModalHeader>Print Property</ModalHeader>
      <ModalBody>
        <h5>Select print out sections:</h5>
        <input type="checkbox" id="details" name="details" value="details" checked={details} onChange={detailsChanged} />
        <label className="ml-2" htmlFor="details">Print details</label>
        <br />
        <input type="checkbox" id="projects" name="projects" value="projects" checked={projects} onChange={projectsChanged} />
        <label className="ml-2" htmlFor="projects">Print categories</label>
        <br />
        <input type="checkbox" id="trade-tracker" name="trade-tracker" value="trade-tracker" checked={tradeTracker} onChange={tradeTrackerChanged} />
        <label className="ml-2" htmlFor="trade-tracker">Print trade tracker</label>
        <br />
        <br />
        <h5>Layout settings:</h5>
        <input type="checkbox" id="projectToPage" name="projectToPage" value="projectToPage" checked={projectToPage} onChange={projectToPageChanged} />
        <label className="ml-2" htmlFor="projectToPage">New Page Per Category</label>
        <br />
        <input type="checkbox" id="tradeTrackerNewPage" name="tradeTrackerNewPage" value="tradeTrackerNewPage" checked={tradeTrackerNewPage} onChange={tradeTrackerNewPageChanged} />
        <label className="ml-2" htmlFor="tradeTrackerNewPage">Start Trade Tracker on New Page</label>
      </ModalBody>
      <ModalFooter>
        <Button
          id="property-print-submit"
          actionMetaData={{
            propertyId,
            details,
            tradeTracker,
            projects,
            projectToPage,
            tradeTrackerNewPage,
          }}
          color="primary"
        >
          Print
        </Button>
        <Button
          id="property-print-cancel"
          className="ml-2"
          color="secondary"
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WithState(PrintModal);
