import {
  prop,
  merge,
  eqProps,
  curry,
} from 'rambda';

import { Bus } from 'baconjs';

const scanUpdates = (prev, next) => {
  const { id, data } = next;
  const state = merge(data, { version: prev.version });

  const result = merge(prev, { [id]: state, version: prev.version + 1 });
  return result;
};

class StateStore {
  constructor() {
    this.getOrCreateStream = this.getOrCreateStream.bind(this);

    this.Publish = curry(this.Publish.bind(this));
    this.Subscribe = this.Subscribe.bind(this);

    this.streams = {};
  }

  getOrCreateStream() {
    const area = 'common';
    if (!this.streams[area]) {
      const inputStream = new Bus();
      this.streams[area] = {
        inputStream,
        updates: inputStream.scan({ version: 0 }, scanUpdates),
      };

      // ensure the stream is hot
      this.streams[area].updates.onValue();
    }

    return this.streams[area];
  }

  Publish(area, id, data) {
    const stream = this.getOrCreateStream(area).inputStream;

    stream.push({ id, data });
  }

  Subscribe(area, id) {
    const stream = this.getOrCreateStream(area).updates;
    return stream
      .toEventStream()
      .map(prop(id))
      .skipDuplicates(eqProps('version'));
  }
}

export default new StateStore();
