import { map } from 'rambda';
import WithState from '@/engine/decorators/with-state';
import WorkItemList from './work-item-list';
import addIndex from '@/engine/funcs/add-index';
import TotalsAcquisition from './totals/totals-acquisition';
import TotalsSales from './totals/totals-sales';
import TotalsFinancial from './totals/totals-financial';
import WorkItemAdd from './work-item-add';
import WorkTrackerIncludeAgentFees from './work-tracker-include-agent-fees';

const renderTotals = ({ id }) => {
  const controlId = `work-tracker-category-${id}`;
  switch (id) {
    case 'oTyVM3gQiHat8YAHwqxxay':
      return <TotalsAcquisition id={controlId} />;
    case '4pK67Qh3zc8z1Dx9AwFLur':
      return <TotalsSales id={controlId} />;
    case 'b43fA5X9bjrTKAhqvQFaHH':
      return <TotalsFinancial id={controlId} />;
    default:
      return null;
  }
};

const renderSaleHeader = (category) => {
  if (category.id !== '4pK67Qh3zc8z1Dx9AwFLur') {
    return null;
  }

  return (
    <WorkTrackerIncludeAgentFees id="work-tracker-include-agent-fees" />
  );
};

const renderCategory = (category, idx) => {
  return (
    <div className={idx && 'mt-5'} key={category.id}>
      <h3 className="mt-1">{category.label}</h3>
      {renderSaleHeader(category)}
      <table
        className="list-items list-items--transformer table-borderless table-headed table table-sm"
      >
        <thead className="list-items__head">
          <tr className="list-items__head-row">
            <td className="list-items__head-description">
              <h5>Items</h5>
            </td>
            <td className="allcaps text-right h6 minw-5 list-items__head-budget">
              <span>Value</span>
            </td>
            <td className="allcaps text-right h6 list-items__head-actions" />
          </tr>
        </thead>
        <tbody className="list-items__body">
          <WorkItemList id={`work-item-list-${category.id}`} />
          <WorkItemAdd id="work-item-add" categoryId={category.id} />
        </tbody>
      </table>
      {renderTotals(category)}
    </div>
  );
};

const WorkTracker = ({
  categories,
}) => {
  if (!categories) {
    return null;
  }
  return addIndex(map)(renderCategory, categories);
};

export default WithState(WorkTracker, { stateKey: 'work-tracker-categories' });
