import { Bus } from 'baconjs';
import { prop } from 'rambda';
import Router from 'next/router';

import { OpenUserUpdates } from './admin-user-navigation-store';
import adminUsersDataStore from '@/data/users/admin-users-data-store';
import stateStore from '@/engine/stores/state-store';

const adminUserLoadBus = new Bus();

Router.events.on('routeChangeComplete', () => {
  if (Router.route === '/admin/users/[id]') {
    adminUserLoadBus.push({ id: Router.query.id });
  }
});

const toUserDetails = (user) => ({
  loaded: user.id !== undefined,
  user,
});

const adminUserLoadUpdates = adminUserLoadBus.toEventStream();
const selectUpdates = OpenUserUpdates.map(prop('userId')).merge(adminUserLoadUpdates.map(prop('id')));

const selectedUserUpdates = adminUsersDataStore.selectUpdates.doLog('selectedUser').map(toUserDetails);

const dsSelectionUpdateUnSub = selectUpdates.onValue(adminUsersDataStore.select);

const stateUpdatesUnSub = selectedUserUpdates.onValue(stateStore.Publish('admin-users', 'admin-user-details'));

export const AdminUserLoadUpdates = adminUserLoadUpdates;

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      dsSelectionUpdateUnSub();
      stateUpdatesUnSub();
      adminUserLoadBus.dispose();
    });
  }
}
