import { when } from 'baconjs';
import {
  always,
  find,
  identity,
  prop,
  propEq,
  reduce,
} from 'rambda';

import uuid from '@/shared/utils/uuid';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import workTrackerItemGroupByCategoryStore from '@/data/work-tracker/work-tracker-item-group-by-category-store';
import workTrackerItemDataStore from '@/data/work-tracker/work-tracker-item-data-store';
import propertyDataStore from '../../properties/data/property.data.store';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

const workItemAddAction = actionDispatcher.subscribe('work-item-add');

const toAddRequest = (workItem) => {
  return Api.post(`/api/work-tracker/item/property/${workItem.propertyId}`, workItem);
};

const toAddUpdate = (categories, property, data) => {
  const category = find(propEq('id', data.categoryId), categories);

  if (!category) {
    return null;
  }

  const sortOrder = reduce((acc, item) => Math.max(acc, item.order), 0, category.items) + 10;

  return {
    id: uuid.fromUUID(uuid.newUUID()),
    canDelete: true,
    categoryId: data.categoryId,
    createDate: new Date(),
    itemType: '9Wz7kzXdD5YWYH3JVYT7qo',
    itemTypeName: 'fixedCredit',
    label: data.label,
    propertyId: property.id,
    order: sortOrder,
    value: data.value,
  };
};

const workItemAddUpdates = when(
  [
    workTrackerItemGroupByCategoryStore.updates.map(prop('items')).toProperty(),
    propertyDataStore.selectUpdates,
    workItemAddAction,
  ],
  toAddUpdate,
)
  .filter(identity);

const insertRequestUpdates = workItemAddUpdates
  .flatMap(toAddRequest);

const insertFailedRequestUpdates = insertRequestUpdates
  .errors()
  .mapError(always({ message: 'Failed to save work tracker row', type: 'error' }));

const insertSubscribeUpdates = workItemAddUpdates.onValue(workTrackerItemDataStore.insert);
const failedUnsubscribe = insertFailedRequestUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading work-item-add-store');
      insertSubscribeUpdates();
      failedUnsubscribe();
    });
  }
}
