import { useState } from 'react';
import {
  Card,
  CardBody,
  Label,
  Nav,
  NavItem,
} from 'reactstrap';

import FormGroup from '@/components/form-group';
import Input from '@/components/input';
import Icon from '@/components/icon';

import WithLoading from '@/engine/decorators/with-loading';

import WithState from '@/engine/decorators/with-state';
import WithActions from '@/engine/decorators/with-actions';

import Button from '@/components/button';
import { formatDate } from '@/engine/utils/date';
import { ACCOUNT_STATUS_FREE, ACCOUNT_STATUS_PAID, ACCOUNT_STATUS_TRIAL } from '@/shared/constants';
import ProfileSubscribe from './profile-subscribe';

const renderFree = () => {
  return (
    <div className="alert alert-success">You qualify for a free account. Enjoy using RenoTracker on us.</div>
  );
};

const renderTrial = (expiry, setShowPurchase) => {
  return (
    <div className="mb-3">
      <div className="alert alert-success">
        Your free trial expires on&nbsp;
        {formatDate(new Date(expiry))}
      </div>
      <div>
        <Button
          color="primary"
          onClick={() => setShowPurchase(true)}
        >
          Activate Subscription now
        </Button>
      </div>
    </div>
  );
};

const renderPayment = (payment) => {
  return (
    <div key={payment.id}>
      <div className="mb-2">
        <Icon icon="download" id="download-invoice" actionMetaData={{ paymentId: payment.id }} className="mr-3" />
        Date: {formatDate(payment.date)} Amount: ${payment.amount}
        <br />
      </div>
    </div>

  );
};

const renderPaid = (expiry, hasActiveSubscription, payments, setShowPurchase) => {
  return (
    <div className="mb-3">
      <h3 className="mt-2 text-center w-100">Payments</h3>
      {hasActiveSubscription && (
        <>
          <div className="alert alert-success">
            Your subscription is active.
          </div>
          <div>
            Next Payment Date: {formatDate(new Date(expiry))}
            <Button
              icon
              id="cancel-subscription"
              title="Cancel Subscription"
            >
              <span>
                <Icon icon="cancel" />
                Cancel
              </span>
            </Button>
          </div>
        </>
      )}
      {!hasActiveSubscription && (
        <>
          <div className="alert alert-warning">
            Your subscription has been cancelled. You will loose access to your account on&nbsp;
            {formatDate(new Date(expiry))}
          </div>
          <div>
            <Button
              color="primary"
              onClick={() => setShowPurchase(true)}
            >
              Activate Subscription now
            </Button>
          </div>
        </>
      )}
      <div className="mt-2">
        <strong>Past Payments</strong>
      </div>
      {
        payments?.length
          ? <div className="mb-2">{payments.map(renderPayment)}</div>
          : <div className="mt-2">No payments have been made so far</div>
      }
    </div>
  );
};

const Profile = ({
  accountStatus,
  payments = [],
  expiry,
  firstName,
  lastName,
}) => {
  const [showPurchase, setShowPurchase] = useState(false);

  const renderPaymentSection = () => {
    if (accountStatus === ACCOUNT_STATUS_FREE) {
      return renderFree();
    }

    return accountStatus === ACCOUNT_STATUS_TRIAL
      ? renderTrial(expiry, setShowPurchase)
      : renderPaid(expiry, accountStatus === ACCOUNT_STATUS_PAID, payments, setShowPurchase);
  };

  const renderProfile = () => (
    <Card className="mw-30 m-auto card-raised">
      <CardBody>
        <FormGroup>
          <Label for="profile-firstName">First Name</Label>
          <Input
            id="profile-firstName"
            fieldClassName="form-control af-hidden-autofill-icon"
            value={firstName}
          />
        </FormGroup>
        <FormGroup>
          <Label for="profile-lastName">Last Name</Label>
          <Input
            id="profile-lastName"
            fieldClassName="form-control af-hidden-autofill-icon"
            value={lastName}
          />
        </FormGroup>
        {renderPaymentSection()}
        <Nav className="justify-content-center align-items-center nav-space-items">
          <NavItem>
            <Button
              id="profile-back"
              type="submit"
              swatch
              color="secondary"
            >
              Back
            </Button>
          </NavItem>
        </Nav>
      </CardBody>
    </Card>
  );

  return (
    <div className="property">
      <h1 className="swatch-title swatch-title--main swatch-title--main swatch-title--main-small">
        <small>Edit</small>
        Profile
      </h1>
      {showPurchase ? <ProfileSubscribe /> : renderProfile()}
    </div>
  );
};

export default WithActions(WithState(WithLoading(Profile)));
