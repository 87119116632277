import {
  filter,
  find,
  merge,
  propEq,
  prop,
  pick,
  sortBy,
} from 'rambda';

import {
  combineTemplate,
  mergeAll as BaconMergeAll,
  once as BaconOnce,
} from 'baconjs';

import stateStore from '@/engine/stores/state-store';
import actionDispatcher from '@/engine/actions/action-dispatcher';

import budgetSuggestionsDataStore from '@/data/budget/budget-suggestions-data-store';

import projectDataStore from '../data/project.data.store';
import projectItemDataStore from '../data/project-item-data-store';
import { VIEW_BUDGET } from '@/modules/properties/constants';

const viewSelectAction = actionDispatcher.subscribe('project-page')
  .filter(propEq('event', 'view'));

const toProjectState = ({ items, project }) => {
  return {
    project,
    hasItems: !!find(propEq('projectId', project.id), items),
  };
};

const sortFunc = (item) => {
  if (!item.name) {
    return 'zzzzzzzzzzzzzzzzz';
  }
  return item.name.toLowerCase();
};

const toProjectBudgetState = ({ items, projectId }) => {
  const projectItems = filter(propEq('projectId', projectId), items);
  return {
    projectId,
    items: sortBy(sortFunc, projectItems),
  };
};

const toProjectSuggestionsState = ({ items, projectId }) => {
  return {
    projectId,
    suggestions: filter(propEq('projectId', projectId), items),
  };
};

const viewSelectUpdates = viewSelectAction
  .map(pick(['view']));

const projectStateUpdates = combineTemplate({
  items: projectItemDataStore.updates.map(prop('items')),
  project: projectDataStore.selectUpdates,
})
  .map(toProjectState);

const allProjectStateUpdates = BaconMergeAll(
  projectStateUpdates,
  viewSelectUpdates,
)
  .scan({}, merge);

const projectBudgetStateUpdates = combineTemplate({
  items: projectItemDataStore.updates.map(prop('items')),
  projectId: projectDataStore.selectUpdates.map(prop('id')).skipDuplicates(),
})
  .map(toProjectBudgetState);

const projectSuggestionsStateUpdates = combineTemplate({
  items: budgetSuggestionsDataStore.updates.map(prop('items')),
  projectId: projectDataStore.selectUpdates.map(prop('id')).skipDuplicates(),
})
  .map(toProjectSuggestionsState);

const allBudgetStateUpdates = BaconMergeAll(
  projectBudgetStateUpdates,
  projectSuggestionsStateUpdates,
)
  .scan({}, merge);

const projectStateUnsubscribe = allProjectStateUpdates.onValue(stateStore.Publish('projects', 'project-page'));
const projectBudgetStateUnsubscribe = allBudgetStateUpdates.onValue(stateStore.Publish('project', 'project-budget'));

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading project-state-store');
      projectStateUnsubscribe();
      projectBudgetStateUnsubscribe();
    });
  }
}
