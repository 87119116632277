import AppLayout from '@/layouts/app';

import ProfileAvatarList from '../components/avatar/profile-avatar-list';

const pageProps = {
  id: 'profile-avatar-page',
};

const ProfileAvatarPage = () => {
  return (
    <AppLayout
      Component={ProfileAvatarList}
      componentProps={pageProps}
    />
  );
};

export default ProfileAvatarPage;
