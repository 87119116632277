import Logo from '@/images/my-properties-square-white.inline.svg';

const LoginLogo = () => {
  return (
    <h1 className="swatch-title swatch-title--main login__heading">
      <span>
        <span className="mr-2 mr-md-3 d-inline-block align-middle">
          <Logo className="login__logo" />
        </span>
        <span className="d-inline-block align-middle text-left login__text">
          <small>
            Welcome to
            <br />
          </small>
          RenoTracker
        </span>
      </span>
    </h1>
  );
};

export default LoginLogo;
