import {
  mergeAll as BaconMergeAll,
  fromPromise,
  when as BaconWhen,
} from 'baconjs';

import {
  always,
  isEmpty,
  merge,
  pick,
  prop,
  propEq,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import notificationStore from '@/engine/stores/notification-store';
import stateStore from '@/engine/stores/state-store';
import ImageTools from '@/engine/utils/image-tools';
import Api from '@/engine/utils/api';

const addressAction = actionDispatcher.subscribe('property-add-address');
const imageAction = actionDispatcher.subscribe('property-add-image');
const saveAction = actionDispatcher.subscribe('property-add-save');
const loadAction = actionDispatcher.subscribe('add-property-page');

const toAddress = ({ fullAddress, address }) => ({
  name: fullAddress,
  address: {
    address1: address.line1,
    address2: address.line2,
    suburb: address.suburb,
    city: address.city,
    postcode: address.postcode,
    fullAddress,
  },
});

const getImage = ({ file, resize }) => {
  return fromPromise(ImageTools.resize(file, resize));
};

const getBase64Image = (blob) => {
  return fromPromise(new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (data) => {
      resolve({
        data: btoa(data.target.result),
        contentType: blob.type,
      });
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsBinaryString(blob);
  }));
};

const toImage = ({ data, contentType }) => {
  const blob = ImageTools.imageDataToBlob(
    data,
    contentType,
  );
  const blobUrl = URL.createObjectURL(blob);

  return {
    data,
    contentType,
    imageUrl: blobUrl,
  };
};

const toSaveUpdate = ({
  name,
  address,
  data,
  contentType,
}) => {
  return {
    name,
    address,
    data,
    contentType,
    valid: !!(address && !isEmpty(address)),
  };
};

const toCreateRequest = (data) => {
  return Api.post('/api/properties', data);
};

const imageUpdates = imageAction
  .flatMapLatest(getImage)
  .flatMapLatest(getBase64Image);

const addPropertyUpdates = BaconMergeAll(
  addressAction.map(toAddress),
  imageUpdates.map(toImage),
  loadAction.map(always({
    name: '',
    address: null,
    data: null,
    contentType: null,
    imageUrl: null,
  })),
)
  .scan({}, merge);

const saveUpdates = BaconWhen(
  [addPropertyUpdates.toProperty(), saveAction],
  toSaveUpdate,
);

const toPropertyId = ({ data: { id } }) => ({ propertyId: id });

const invalidSaveUpdates = saveUpdates.filter(propEq('valid', false));
const validSaveUpdates = saveUpdates.filter(prop('valid'));

const saveRequestUpdates = validSaveUpdates
  .flatMap(toCreateRequest);

const failedSaveUpdates = saveRequestUpdates
  .errors()
  .mapError(always({ message: 'Failed to save property', type: 'error' }));

const propertyCreatedUpdates = saveRequestUpdates
  .skipErrors()
  .map(toPropertyId);

const stateUpdates = BaconMergeAll(
  addPropertyUpdates,
  saveAction.map(always({ send: true })),
  invalidSaveUpdates.map(always({ send: false })),
  failedSaveUpdates.map(always({ send: false })),
  propertyCreatedUpdates.map(always({ send: false })),
)
  .map(pick(['send', 'imageUrl']))
  .scan({}, merge);

stateUpdates.onValue(stateStore.Publish('properties', 'add-property-page'));
invalidSaveUpdates.map(always({ message: 'Address is required', type: 'error' }))
  .onValue(notificationStore.publish);
failedSaveUpdates.onValue(notificationStore.publish);

export const PropertyCreatedUpdates = propertyCreatedUpdates;
