import { always, path, propEq } from 'rambda';

import notificationStore from '@/engine/stores/notification-store';
import AdminUsersDataStore from '@/data/users/admin-users-data-store';
import Api from '@/engine/utils/api';
import actionDispatcher from '@/engine/actions/action-dispatcher';

import { AdminUserLoadUpdates } from './admin-user-details-state-store';

const loadAction = actionDispatcher.subscribe('admin-users').filter(propEq('event', 'load'));

const loadUsers = () => {
  return Api.get('/api/admin/users');
};

const loadUpdates = loadAction.merge(AdminUserLoadUpdates)
  .flatMap(loadUsers);

const loadSuccessUpdates = loadUpdates
  .skipErrors()
  .map(path(['data', 'items']));

const loadFailedUpdates = loadUpdates
  .errors()
  .mapError(always({ message: 'Failed to load users', type: 'error' }));

const failUnsubscribe = loadFailedUpdates.onValue(notificationStore.publish);
const loadUnsubscribe = loadSuccessUpdates.onValue(AdminUsersDataStore.load);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading admin-user-load-store');
      loadUnsubscribe();
      failUnsubscribe();
    });
  }
}
