import { Row, Col } from 'reactstrap';
import classnames from 'classnames';

import Button from '@/components/button';
import Plus from '@/images/white-plus.inline.svg';
import PropertyBudgetTile from './property-budget-tile';

const PropertyBudgetTiles = ({ projects }) => {
  const hasProjects = !!(projects?.length);

  const renderNoProjects = () => {
    return (
      <div className="text-center">
        <h4>Welcome to your reno!</h4>
        <p>
          To get started, add some categories and items to your budget using
          the button below...
        </p>
      </div>
    );
  };

  return (
    <>
      {!hasProjects && renderNoProjects()}

      <Row
        className={classnames('row-space-items', {
          'justify-content-center': !hasProjects,
        })}
      >
        <Col
          xs="6"
          sm="6"
          md="4"
          lg="3"
          className="align-items-center d-flex justify-content-center"
        >
          <Button
            id="property-project-add"
            size="md"
            swatch="lg"
            color="primary"
            className="project-list-add"
          >
            <Plus className="btn-swatch__icon btn-swatch__icon--larger" />
            <br />
            <span>
              <small>Add to</small>
              <br />
              Budget
            </span>
          </Button>
        </Col>

        {projects.map((project) => (
          <Col key={project.id} xs="6" sm="6" md="4" lg="3">
            <PropertyBudgetTile id={`project-budget-totals-${project.id}`} project={project} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PropertyBudgetTiles;
