import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';

import Link from '@/components/link';

import WithLoading from '@/engine/decorators/with-loading';

const TemplateAdminList = ({ templates }) => {
  return (
    <Row>
      {templates.map((template) => (
        <Col key={template.id} xs="12" sm="6" lg="4">
          <Card className="mb-2 card-raised">
            <Link
              id="navigate-admin-template"
              actionMetaData={{ templateId: template.id }}
              className="as-link"
            >
              <CardBody>
                <CardTitle className="mb-0">{template.name}</CardTitle>
              </CardBody>
            </Link>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default WithLoading(TemplateAdminList);
