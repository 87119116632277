import { mergeAll } from 'baconjs';
import {
  always,
  assoc,
  merge,
  prop,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import notificationStore from '@/engine/stores/notification-store';
import stateStore from '@/engine/stores/state-store';
import Api from '@/engine/utils/api';

// ACTIONS
const loadAction = actionDispatcher.subscribe('profile-page');

// functions
const toLoadUserRequest = () => {
  return Api.get('/api/login/user');
};

// streams
const loadUserRequests = loadAction
  .flatMapLatest(toLoadUserRequest);

const loadUserFailed = loadUserRequests
  .errors()
  .mapError(always({ message: 'Failed to load profile', type: 'error' }));

const stateUpdates = mergeAll(
  loadUserRequests.skipErrors().map(prop('data')).map(assoc('loaded', true)),
)
  .scan({}, merge);

// SUBSCRIBERS
const stateUnsub = stateUpdates.doLog('profileState').onValue(stateStore.Publish('profile', 'profile-page'));
const loadUserFailedUnsub = loadUserFailed.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading profile-state-store');
      stateUnsub();
      loadUserFailedUnsub();
    });
  }
}
