import { pick } from 'rambda';
import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';

const projectNavigateAction = actionDispatcher.subscribe('project-navigate');

const navigateToProject = ({ projectId }) => {
  Router.push(`/project/${projectId}`);
};

const projectNavigationUpdates = projectNavigateAction
  .map(pick(['projectId']));

projectNavigationUpdates.onValue(navigateToProject);
