import { useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  Card,
  CardBody,
} from 'reactstrap';

import WithState from '@/engine/decorators/with-state';
import WithActions from '@/engine/decorators/with-actions';

import Button from '@/components/button';
import Loading from '@/components/loading';

import ClonePropertyForm from './clone-property-form';

const renderLoading = () => {
  return (
    <Loading />
  );
};

const renderEdit = (property) => {
  return (
    <>
      <h1 className="swatch-title swatch-title--main swatch-title--main swatch-title--main-small">
        <small>Clone</small>
        Reno Details
      </h1>
      <Card className="mw-30 m-auto card-raised">
        <CardBody>
          <ClonePropertyForm
            id="property-clone-address"
            property={property}
          />
        </CardBody>
      </Card>
    </>
  );
};

const CloneProperty = ({ publish, property }) => {
  const router = useRouter();
  const { id } = router.query;

  useEffect(() => {
    if (id) {
      publish({ event: 'load', id });
    }
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <div className="property">
      <div className="back-button-top-container">
        <Button
          id="property-edit-back"
          actionMetaData={{ propertyId: id }}
          swatch
          color="secondary"
          className="back-button-top"
          size="sm"
        >
          Back
        </Button>
      </div>
      {property?.id ? renderEdit(property) : renderLoading()}
    </div>
  );
};

export default WithActions(WithState(CloneProperty));
