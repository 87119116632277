import AppLayout from '@/layouts/app';

import CloneProperty from '../components/clone/clone-property';

const pageProps = {
  id: 'property',
};

const ClonePropertyPage = () => {
  return (
    <AppLayout Component={CloneProperty} componentProps={pageProps} />
  );
};

export default ClonePropertyPage;
