import AppLayout from '@/layouts/app';

import EditProperty from '../components/edit/edit-property';

const pageProps = {
  id: 'property',
};

const EditPropertyPage = () => {
  return (
    <AppLayout Component={EditProperty} componentProps={pageProps} />
  );
};

export default EditPropertyPage;
