import Link from '../link';

const PropertyLink = ({ propertyId, name }) => (
  <Link
    id="navigate-property"
    actionMetaData={{ propertyId }}
    href={`/properties/${propertyId}`}
  >
    {name}
  </Link>
);

export default PropertyLink;
