import { merge, reduce } from 'rambda';

const addProjectItem = (total, projectItem) => ({
  budget: projectItem.budget + total.budget,
  spent: projectItem.spent + total.spent,
});

const getProjectBudget = (project) => {
  const empty = {
    budget: 0,
    spent: 0,
    progress: 0,
    progressFormatted: '',
  };

  if (!project.activeItems?.length) {
    return empty;
  }

  const projectBudget = reduce(addProjectItem, empty, project.activeItems);
  let percent = 0;

  if (projectBudget.budget && projectBudget.spent) {
    percent = (projectBudget.spent / projectBudget.budget) * 100;
  }

  const progress = percent.toFixed(2);
  const progressFormatted = progress ? `${Math.round(progress)}%` : '';

  return merge(projectBudget, { progress, progressFormatted });
};

const addProject = (total, project) => {
  if (!project.activeItems?.length) {
    return total;
  }

  const projectItemsTotal = getProjectBudget(project);
  return {
    budget: projectItemsTotal.budget + total.budget,
    spent: projectItemsTotal.spent + total.spent,
  };
};

const getPropertyBudget = (property) => {
  const empty = {
    budget: 0,
    spent: 0,
    progress: 0,
    progressFormatted: 0,
  };

  if (!property.projects?.length) {
    return empty;
  }

  const propertyBudget = reduce(addProject, empty, property.projects);
  let percent = 0;

  if (propertyBudget.budget && propertyBudget.spent) {
    percent = (propertyBudget.spent / propertyBudget.budget) * 100;
  }

  const progress = percent.toFixed(2);
  const progressFormatted = progress ? `${Math.round(progress)}%` : '';

  return merge(propertyBudget, { progress, progressFormatted });
};

const PropertiesUtils = {
  getPropertyBudget,
  getProjectBudget,
};

export default PropertiesUtils;
