import { useEffect } from 'react';
import { map } from 'rambda';
import WithActions from '@/engine/decorators/with-actions';
import WithState from '@/engine/decorators/with-state';
import WorkItemList from './work-tracker-admin-item-list';
import addIndex from '@/engine/funcs/add-index';
import WorkItemAdd from './work-tracker-admin-item-add';

const renderCategory = (category, idx) => {
  return (
    <div className={idx && 'mt-5'} key={category.id}>
      <h3 className="mt-1">{category.label}</h3>
      <table
        className="list-items list-items--transformer table-borderless table-headed table table-sm"
      >
        <thead className="list-items__head">
          <tr className="list-items__head-row">
            <td className="list-items__head-tradeDescription">
              <h5>Items</h5>
            </td>
            <td className="text-right minw-5 list-items__head-tradeBudget">
              <span>Value</span>
            </td>
            <td className="text-right list-items__head-actions" />
          </tr>
        </thead>
        <tbody className="list-items__body">
          <WorkItemList id={`work-item-admin-list-${category.id}`} />
          <WorkItemAdd id="work-item-admin-add" categoryId={category.id} />
        </tbody>
      </table>
    </div>
  );
};

const WorkTracker = ({
  publish,
  categories,
}) => {
  useEffect(() => {
    publish({ event: 'load' });
  }, []);
  if (!categories) {
    return null;
  }
  return addIndex(map)(renderCategory, categories);
};

export default WithActions(WithState(WorkTracker));
