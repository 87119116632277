import GroupByStore from '@/engine/stores/group-by-store';
import workTrackerAdminItemDataStore from './work-tracker-admin-item-data-store';
import workTrackerCategoryDataStore from './work-tracker-category-data-store';

class WorkTrackerAdminItemGroupByCategoryStore extends GroupByStore {
  constructor() {
    super(workTrackerAdminItemDataStore, workTrackerCategoryDataStore, { itemDetailKey: 'categoryId' });
  }
}

export default new WorkTrackerAdminItemGroupByCategoryStore();
