import { combineTemplate } from 'baconjs';
import {
  forEach,
  keys,
  prop,
} from 'rambda';

import stateStore from '@/engine/stores/state-store';

import projectItemDataStore from '../data/project-item-data-store';
import propertyDataStore from '@/modules/properties/data/property.data.store';
import { STATUS_COMPLETED } from '@/shared/constants';

const toState = ({ property, items }) => {
  const state = {};
  let totalSpent = 0;
  let totalBudget = 0;

  forEach((item) => {
    if (item.spent) {
      totalSpent += item.spent;
    }

    if (item.budget) {
      totalBudget += item.budget;
    }

    const key = `project-budget-totals-${item.projectId}`;
    const projectBudget = state[key] || {
      spent: 0,
      budget: 0,
      progress: 0,
      progressFormatted: '0%',
    };

    projectBudget.spent += item.spent || 0;
    projectBudget.budget += item.budget || 0;

    if (projectBudget.budget && projectBudget.spent) {
      const progress = (projectBudget.spent / projectBudget.budget) * 100;
      projectBudget.progress = progress.toFixed(2);
      projectBudget.progressFormatted = progress ? `${Math.round(progress)}%` : '%';
    }

    state[key] = projectBudget;
  }, items);

  let renoCosts = 0;
  keys(state).forEach((key) => {
    const projectRenoCosts = property.status === STATUS_COMPLETED
      ? state[key].spent
      : Math.max(state[key].spent, state[key].budget);

    renoCosts += projectRenoCosts;
  });

  let totalPercent = 0;

  if (totalBudget && totalSpent) {
    totalPercent = (totalSpent / totalBudget) * 100;
  }

  if (property.status === STATUS_COMPLETED) {
    renoCosts = totalSpent;
  }

  state['property-budget-totals'] = {
    spent: totalSpent,
    budget: totalBudget,
    renoCosts,
    progress: totalPercent.toFixed(2),
    progressFormatted: totalPercent ? `${Math.round(totalPercent)}%` : '0%',
  };

  return state;
};

const publishState = (state) => {
  forEach((key) => stateStore.Publish('project', key, state[key]), keys(state));
};

const stateUpdates = combineTemplate({
  items: projectItemDataStore.updates.map(prop('items')),
  property: propertyDataStore.selectUpdates,
})
  .map(toState);

const budgetTotalUpdates = stateUpdates
  .map(prop('property-budget-totals'));

const unsubscribeStateUpdates = stateUpdates.onValue(publishState);

export const BudgetTotalUpdates = budgetTotalUpdates;

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading project-budget-state-store');
      unsubscribeStateUpdates();
    });
  }
}
