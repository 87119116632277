import { useState, useEffect } from 'react';
import { Input } from 'reactstrap';

import { FormatMoney, UnFormatMoney } from '@/engine/utils/number';

import WithActions from '@/engine/decorators/with-actions';

const MoneyInput = ({
  value,
  publish,
  name,
  id,
  disabled = false,
}) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const getValue = (rawValue) => {
    return UnFormatMoney(rawValue);
  };

  const onChange = (event) => {
    const updatedValue = getValue(event.target.value);
    publish({
      event: 'change',
      type: 'update',
      name: name || id,
      value: updatedValue,
    });
    setInternalValue(updatedValue);
  };

  const onBlur = (event) => {
    const updatedValue = getValue(event.target.value);
    publish({
      event: 'blur',
      type: 'update',
      name: name || id,
      value: updatedValue,
    });
    setInternalValue(updatedValue);
  };

  return (
    <Input
      type="tel"
      className="form-control-no-browser-helpers"
      value={!internalValue ? '' : FormatMoney(internalValue)}
      onChange={onChange}
      onBlur={onBlur}
      placeholder="$0"
      disabled={disabled}
    />
  );
};

export default WithActions(MoneyInput);
