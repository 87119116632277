import { combineTemplate } from 'baconjs';
import {
  filter,
  forEach,
  map,
  merge,
  prop,
} from 'rambda';

import addIndex from '@/engine/funcs/add-index';
import projectPhotoDataStore from '@/data/projects/project-photo-data-store';
import projectDataStore from '@/modules/project/data/project.data.store';
import stateStore from '@/engine/stores/state-store';

const filterEmptyPhotos = (items) => {
  return filter((p) => {
    return p.beforeImageUrl || p.afterImageUrl;
  }, items);
};

const photoUpdates = projectPhotoDataStore.updates.map(prop('items'))
  .map(filterEmptyPhotos);

const toGalleryStateUpdate = ({ photos, projects }) => {
  const keyIndex = {};
  const groupedItems = addIndex(map)((project, idx) => {
    keyIndex[project.id] = idx;
    return merge(project, {
      photos: [],
    });
  }, projects);

  forEach((photo) => {
    const projectId = prop('projectId', photo);
    const detailIndex = keyIndex[projectId];

    if (detailIndex >= 0) {
      groupedItems[detailIndex].photos.push(photo);
    }
  }, photos);

  return {
    projects: groupedItems,
    hasPhotos: photos?.length > 0,
  };
};

const galleryStateUpdates = combineTemplate({
  photos: photoUpdates,
  projects: projectDataStore.updates.map(prop('items')),
})
  .map(toGalleryStateUpdate);

const galleryStateUnsubscribe = galleryStateUpdates.onValue(stateStore.Publish('properties', 'property-gallery'));

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.log('unloading property-gallery-state-store');
      galleryStateUnsubscribe();
    });
  }
}
