/* eslint-disable react/jsx-props-no-spreading */
import {
  DropdownItem as ReactStrapDropdownItem,
} from 'reactstrap';

import WithActions from '@/engine/decorators/with-actions';

const DropDownItem = ({ children, publish, ...props }) => {
  const click = () => {
    publish({ event: 'click' });
  };

  return (
    <ReactStrapDropdownItem onClick={click} {...props}>
      {children}
    </ReactStrapDropdownItem>
  );
};

export default WithActions(DropDownItem);
