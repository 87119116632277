import { useState, useEffect } from 'react';
import classNames from 'classnames';

import WithActions from '@/engine/decorators/with-actions';
import WithState from '@/engine/decorators/with-state';

const getIsValid = (validator, required, value) => {
  if (required && !value) {
    return false;
  }
  if (validator) {
    return validator(value);
  }
  return true;
};

const InputCom = ({
  name,
  onChange,
  className,
  placeholder,
  type = 'text',
  publish,
  value,
  isRequired,
  validator,
  id,
  lg,
  min,
}) => {
  const [isValid, setIsValid] = useState(getIsValid(validator, isRequired, value));
  const [showValidation, setShowValidation] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    publish({
      event: 'change',
      type: 'update',
      name: name || id,
      value: value,
      valid: validate(value),
    });

    setInternalValue(value);
  }, [value]);

  const validate = (updatedValue) => {
    const valid = getIsValid(validator, isRequired, updatedValue);
    setIsValid(valid);
    if (valid && !showValidation) {
      setShowValidation(true);
    }
    return valid;
  };

  const onChangeInternal = (event) => {
    const data = {
      event: 'change',
      type: 'update',
      name: name || id,
      value: event.target.value,
      valid: validate(event.target.value),
    };

    if (onChange) {
      onChange(data);
    }

    setInternalValue(data.value);
    publish(data);
  };

  const onBlur = (event) => {
    setInternalValue(event.target.value);
    publish({
      event: 'blur',
      type: 'update',
      name: name || id,
      value: event.target.value,
      valid: validate(event.target.value),
    });
    setShowValidation(true);
  };

  const validationClassName = () => {
    if ((!isRequired && !validator) || !showValidation) return '';

    return isValid ? 'is-valid' : 'is-invalid';
  };

  const inputClassName = classNames(
    'form-control',
    lg ? 'form-control-lg' : null,
    validationClassName(),
    className,
  );

  return (
    <input
      min={min}
      name={name}
      placeholder={placeholder}
      autoComplete={type}
      type={type}
      className={inputClassName}
      value={internalValue}
      onChange={onChangeInternal}
      onBlur={onBlur}
    />
  );
};

const Input = WithState(WithActions(InputCom));
export default Input;
