import { identity, pick, prop } from 'rambda';
import { when } from 'baconjs';
import ActionDispatcher from '@/engine/actions/action-dispatcher';
import { MutateUser } from '@/engine/utils/use-user';
import notificationStore from '@/engine/stores/notification-store';

import api from '@/engine/utils/api';

const emailAction = ActionDispatcher.subscribe('login.form.email');
const passwordAction = ActionDispatcher.subscribe('login.form.password');
const loginAction = ActionDispatcher.subscribe('login.form.login');

const toFormData = (email, password) => ({
  email: email.value,
  password: password.value,
  valid: password.valid && email.valid,
});

const doLogin = (data) => {
  const loginData = pick(['email', 'password'], data);

  return api.post('/api/login', loginData);
};

const updateUser = (user) => {
  MutateUser(user);
};

const setLoginError = () => {
  notificationStore.publish({ type: 'error', message: 'failed to login' });
};

const submitUpdates = when(
  [emailAction.toProperty(), passwordAction.toProperty(), loginAction],
  toFormData,
)
  .filter(prop('valid'))
  .flatMap(doLogin);

const loginSuccessUpdates = submitUpdates.skipErrors();
const loginFailedUpdates = submitUpdates
  .errors()
  .mapError(identity);

loginSuccessUpdates.onValue(updateUser);
loginFailedUpdates.onValue(setLoginError);
