import GroupByStore from '@/engine/stores/group-by-store';
import projectItemFilterStore from './project-item-filter-store';
import projectDataStore from './project.data.store';

class ProjectItemGroupByProjectStore extends GroupByStore {
  constructor() {
    super(projectItemFilterStore, projectDataStore, { itemDetailKey: 'projectId' });
  }
}

export default new ProjectItemGroupByProjectStore();
