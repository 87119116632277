import Dropzone from 'react-dropzone';

import Icon from '../icon';
import WithActions from '@/engine/decorators/with-actions';

const ImageField = ({
  prompt = 'Drop an image here, or tap to select image to upload',
  publish,
  fileUrl,
  resize,
}) => {
  const onDropImage = async (files) => {
    const file = files[0];

    if (file) {
      publish({ file, resize });
    }
  };

  const { width, height } = resize || {};

  const imageStyle = {
    maxWidth: width,
    maxHeight: height,
    height: 'auto',
    width: 'auto',
  };
  return (
    <Dropzone
      className="form-control dropzone"
      accept="image/jpeg, image/png"
      onDrop={onDropImage}
    >
      {fileUrl && <img src={fileUrl} style={imageStyle} alt="Uploaded" />}
      {!fileUrl && (
        <div>
          <Icon icon="add_photo_alternate" size="lg" />
          <p>{prompt}</p>
        </div>
      )}
    </Dropzone>
  );
};

export default WithActions(ImageField);
