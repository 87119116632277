import {
  Card,
  CardBody,
  Nav,
  NavItem,
} from 'reactstrap';

import WithLoading from '@/engine/decorators/with-loading';

import WithState from '@/engine/decorators/with-state';
import WithActions from '@/engine/decorators/with-actions';

import Button from '@/components/button';
import { formatDate } from '@/engine/utils/date';
import {
  ACCOUNT_STATUS_CANCELLED,
  ACCOUNT_STATUS_FREE,
  ACCOUNT_STATUS_PAID,
  ACCOUNT_STATUS_TRIAL,
  ACCOUNT_STATUS_EXPIRED,
  ACCOUNT_STATUS_BILL_FAIL,
} from '@/shared/constants';
import UserDetailsFree from './admin-user-details-free';
import UserDetailsTrial from './admin-user-details-trial';
import UserDetailsPaid from './admin-user-details-paid';

const renderAccountStatus = (user) => {
  switch (user.accountStatus) {
    case ACCOUNT_STATUS_FREE:
      return <UserDetailsFree user={user} />;
    case ACCOUNT_STATUS_TRIAL:
    case ACCOUNT_STATUS_EXPIRED:
      return <UserDetailsTrial user={user} />;
    case ACCOUNT_STATUS_PAID:
    case ACCOUNT_STATUS_CANCELLED:
    case ACCOUNT_STATUS_BILL_FAIL:
      return <UserDetailsPaid user={user} />;
    default:
      return 'Unknown';
  }
};

const AdminUserDetails = ({
  user,
}) => {
  const {
    firstName,
    lastName,
    fullName,
  } = user;

  const renderProfile = () => (
    <Card className="mw-30 m-auto card-raised">
      <CardBody>
        <span className="d-block mr-4 mb-2">
          <strong className="mr-2">Full Name:</strong>
          {fullName}
        </span>
        <span className="d-block mr-4 mb-2">
          <strong className="mr-2">Firstname:</strong>
          {firstName}
        </span>
        <span className="d-block mr-4 mb-2">
          <strong className="mr-2">Lastname:</strong>
          {lastName}
        </span>
        <span className="d-block mr-4 mb-2">
          <strong className="mr-2">Email:</strong>
          {user.email}
        </span>
        <span className="d-block mr-4 mb-2">
          <strong className="mr-2">Email Verified:</strong>
          {user.emailVerified ? 'Yes' : 'No'}
        </span>
        <span className="d-block mr-4 mb-2">
          <strong className="mr-2">Create Date:</strong>
          {formatDate(new Date(user.createDate))}
        </span>
        <span className="d-block mr-4 mb-2">
          <strong className="mr-2">Activation Date:</strong>
          {user.activationDate ? formatDate(new Date(user.activationDate)) : ''}
        </span>
        <span className="d-block mr-4 mb-2">
          <strong className="mr-2">Last Login:</strong>
          {user.lastLoginDate ? formatDate(new Date(user.lastLoginDate)) : ''}
        </span>

        {renderAccountStatus(user)}
        <Nav className="justify-content-center align-items-center nav-space-items">
          <NavItem>
            <Button
              id="admin-user-details-back"
              type="submit"
              swatch
              color="secondary"
            >
              Back
            </Button>
          </NavItem>
        </Nav>
      </CardBody>
    </Card>
  );

  return (
    <div className="property">
      <h1 className="swatch-title swatch-title--main swatch-title--main swatch-title--main-small">
        <small>Edit</small>
        User
      </h1>
      {renderProfile()}
    </div>
  );
};

export default WithActions(WithState(WithLoading(AdminUserDetails)));
