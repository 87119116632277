import {
  always, prop,
} from 'rambda';
import Router from 'next/router';

import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';
import actionDispatcher from '@/engine/actions/action-dispatcher';

const saveAsTemplateAction = actionDispatcher.subscribe('project-save-as-template');

const toSaveAsTemplateRequest = ({ projectId }) => {
  return Api.post(`/api/projects/${projectId}/save-as-template`);
};

const navigateToTemplate = ({ id }) => {
  Router.push(`/admin/templates/${id}`);
};

const saveAsTemplateRequestUpdates = saveAsTemplateAction
  .flatMap(toSaveAsTemplateRequest);

const saveAsTemplateRequestSuccessUpdates = saveAsTemplateRequestUpdates
  .skipErrors()
  .map(prop('data'));

const saveAsTemplateRequestErrorUpdates = saveAsTemplateRequestUpdates
  .errors()
  .mapError(always({ message: 'Failed to save as template', type: 'error' }));

const saveFailureUnsubscribe = saveAsTemplateRequestErrorUpdates.onValue(notificationStore.publish);
const saveSuccessUnsubscribe = saveAsTemplateRequestSuccessUpdates.onValue(navigateToTemplate);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading project-save-as-template-store');
      saveFailureUnsubscribe();
      saveSuccessUnsubscribe();
    });
  }
}
