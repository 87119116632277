import {
  always, identity,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

const collaboratorAddAction = actionDispatcher.subscribe('property-collaborator-add');

const toAddRequest = ({ propertyId, email }) => {
  return Api.post(`/api/properties/${propertyId}/share`, { email });
};

const addRequestUpdates = collaboratorAddAction
  .doLog('collaborator-add')
  .flatMap(toAddRequest);

const addSuccessUpdates = addRequestUpdates
  .skipErrors();

const addFailedUpdates = addRequestUpdates
  .errors()
  .mapError(always({
    message: 'Failed to share property',
    type: 'error',
  }));

const notificationUnsubscribe = addFailedUpdates.onValue(notificationStore.publish);

export const ShareAddedUpdates = addSuccessUpdates.map(identity);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      notificationUnsubscribe();
    });
  }
}
