import { combineTemplate } from 'baconjs';
import {
  prop,
  sortBy,
} from 'rambda';

import templateDataStore from '@/data/templates/template-data-store';
import stateStore from '@/engine/stores/state-store';
import adminTemplateItemDataStore from '@/data/templates/admin-template-item-data-store';

const sortFunc = (item) => {
  if (!item.name) {
    return 'zzzzzzzzzzzzzzzzz';
  }
  return item.name.toLowerCase();
};

const toTemplateState = ({ template, items }) => {
  return {
    template,
    items: sortBy(sortFunc, items),
  };
};

const selectedTemplateUpdates = combineTemplate({
  template: templateDataStore.selectUpdates,
  items: adminTemplateItemDataStore.updates.map(prop('items')),
})
  .map(toTemplateState);

const stateUnsubscribe = selectedTemplateUpdates
  .onValue(stateStore.Publish('admin-templates', 'template-admin-edit-page'));

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.log('unloading template-state-store');
      stateUnsubscribe();
    });
  }
}
