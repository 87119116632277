import { when as BaconWhen } from 'baconjs';
import { always } from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import propertyDataStore from '../data/property.data.store';
import Api from '@/engine/utils/api';

import notificationStore from '@/engine/stores/notification-store';

const propertyAddressSelectAction = actionDispatcher.subscribe('property-edit-address');

const toPropertyAddressUpdate = ({ id }, { fullAddress, address }) => {
  return {
    id,
    address: {
      address1: address.line1,
      address2: address.line2,
      suburb: address.suburb,
      city: address.city,
      postcode: address.postcode,
      fullAddress,
    },
  }
};

const toPropertyUpdateRequest = (data) => {
  return Api.put(`/api/properties/${data.id}/address`, data.address);
};

const propertyAddressUpdates = BaconWhen(
  [propertyDataStore.selectUpdates.toProperty(), propertyAddressSelectAction],
  toPropertyAddressUpdate,
);

const propertyAddressUpdateRequestUpdates = propertyAddressUpdates
  .flatMapLatest(toPropertyUpdateRequest);

const propertyUpdatesFailedRequestUpdates = propertyAddressUpdateRequestUpdates
  .errors()
  .mapError(always({ type: 'error', message: 'Failed to update address' }));

propertyAddressUpdates.onValue(propertyDataStore.update);
propertyUpdatesFailedRequestUpdates
  .onValue(notificationStore.publish);
