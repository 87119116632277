import AppLayout from '@/layouts/app';

import ProjectAdd from '../components/add/project-add';

const pageProps = {
  id: 'project-add-page',
};

const ProjectAddPage = () => {
  return (
    <AppLayout
      Component={ProjectAdd}
      componentProps={pageProps}
    />
  );
};

export default ProjectAddPage;
