import { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { map, propEq, filter } from 'rambda';

import WithActions from '@/engine/decorators/with-actions';
import WithState from '@/engine/decorators/with-state';

import Loading from '@/components/loading';

import { STATUS_ACTIVE, STATUS_COMPLETED } from '../../../../shared/constants';

import AddPropertyButton from './add-property-button';
import PropertyTile from './property-tile';

const PropertiesList = ({ publish, items, loaded }) => {
  useEffect(() => {
    publish({ event: 'load' });
  }, []);

  const renderProperty = (property) => (
    <PropertyTile key={property.id} property={property} />
  );

  const renderLoaded = () => {
    const activeProperties = filter(propEq('status', STATUS_ACTIVE), items);
    const completedProperties = filter(propEq('status', STATUS_COMPLETED), items);

    return (
      <>
        {(items?.length === 0) && (
          <div className="text-center">
            <h3>Welcome to RenoTracker.</h3>
            <span>
              It is time for your explore RenoTracker by starting a new Reno.

              For hints on how to get started
              <a style={{ marginLeft: '1em' }} href="https://www.myproperties.co.nz" target="_blank" rel="noreferrer">click here</a>
            </span>
          </div>
        )}
        <Row className="row-space-items">
          <Col
            xs="6"
            md="4"
            lg="3"
            className="align-items-center d-flex justify-content-center"
          >
            <AddPropertyButton
              className="my-5 property-list-add-property"
              title={(
                <span>
                  <small>Start</small>
                  <br />
                  {items?.length ? 'New Reno' : 'First Reno'}
                </span>
              )}
            />
          </Col>
          {map(renderProperty, activeProperties)}
        </Row>
        {completedProperties.length > 0 && (
          <div className="mt-5">
            <div className="text-center">
              <h3 className="swatch-title">Completed</h3>
            </div>
            <div className="mt-4">
              <Row className="row-space-items">
                {map(renderProperty, completedProperties)}
              </Row>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderLoader = () => <Loading />;

  return (
    <Container className="container-fluid-max" fluid>
      <div className="text-center">
        <h1 className="swatch-title swatch-title--main">My Properties</h1>
      </div>
      {loaded ? renderLoaded() : renderLoader()}
    </Container>
  );
};

export default WithActions(WithState(PropertiesList));
