import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { assoc } from 'rambda';
import { v4 } from 'uuid';

import notificationStore from '@/engine/stores/notification-store';

const NotificationDisplay = () => {
  const [notification, setNotification] = useState({});

  useEffect(() => {
    const unsubscribe = notificationStore
      .subscribe()
      .onValue((newNotification) => {
        const notificationWithId = assoc('id', v4(), newNotification);

        setTimeout(() => {
          setNotification((currentNotification) => {
            if (currentNotification.id === notificationWithId.id) {
              return {};
            }
            return currentNotification;
          });
        }, 5000);

        setNotification(notificationWithId);
      });

    return () => {
      unsubscribe();
    };
  }, []);

  if (!notification?.message) {
    return null;
  }

  const className = classNames(
    'alert',
    notification.type === 'error' ? 'alert-danger' : 'alert-primary',
  );

  const close = () => {
    setNotification({});
  };

  return (
    <div className={className} onClick={close}>
      {notification.message}
    </div>
  );
};

export default NotificationDisplay;
