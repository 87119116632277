import { fromPromise } from 'baconjs';
import { always } from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import ImageTools from '@/engine/utils/image-tools';
import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

import projectPhotoDataStore from '@/data/projects/project-photo-data-store';

const editImageAction = actionDispatcher.subscribe('project-photo-edit');

const getImage = ({ file, resize, photoId, type }) => {
  return fromPromise(ImageTools.resize(file, resize))
    .map((blob) => ({ photoId, blob, type }));
};

const getBase64Image = ({ photoId, blob, type }) => {
  return fromPromise(new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (data) => {
      resolve({
        data: btoa(data.target.result),
        contentType: blob.type,
        photoId,
        type,
      });
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsBinaryString(blob);
  }));
};

const toImageEdit = ({
  photoId,
  data,
  contentType,
  type,
}) => {
  const blob = ImageTools.imageDataToBlob(
    data,
    contentType,
  );

  const editData = {
    id: photoId,
  };
  editData[`${type}ImageUrl`] = URL.createObjectURL(blob);

  return editData;
};

const toImageUploadRequest = ({
  photoId,
  data,
  contentType,
  type,
}) => {
  return Api.put(`/api/projects/photos/${photoId}`, { data, contentType, type });
};

const imageUpdates = editImageAction
  .flatMapLatest(getImage)
  .flatMapLatest(getBase64Image);

const saveImageRequestUpdates = imageUpdates
  .flatMapLatest(toImageUploadRequest);

const saveRequestFailedUpdates = saveImageRequestUpdates
  .errors()
  .mapError(always({ type: 'error', message: 'Failed to save photo.' }));

const unsubscribeImageUpdates = imageUpdates
  .map(toImageEdit)
  .onValue((data) => projectPhotoDataStore.update(data));

const unsubscribeFailedUpdates = saveRequestFailedUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading project photo edit store');
      unsubscribeFailedUpdates();
      unsubscribeImageUpdates();
    });
  }
}
