import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';

const registerClickedUpdates = actionDispatcher.subscribe('login.form.register');
const forgotPasswordUpdates = actionDispatcher.subscribe('login.form.forgot-password');
const returnToLoginUpdates = actionDispatcher.subscribe('forgot.password.form.return');

const navigateRegister = () => {
  Router.push('/auth/register');
};

const navigateForgotPassword = () => {
  Router.push('/auth/forgot-password');
};

const navigateLogin = () => {
  Router.push('/auth/login');
};

registerClickedUpdates.onValue(navigateRegister);
forgotPasswordUpdates.onValue(navigateForgotPassword);
returnToLoginUpdates.onValue(navigateLogin);
