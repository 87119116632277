import { combineTemplate, once } from 'baconjs';
import {
  assoc,
  forEach,
  keys,
  map,
  merge,
  pick,
  prop,
  reduce,
} from 'rambda';

import stateStore from '@/engine/stores/state-store';
import actionDispatcher from '@/engine/actions/action-dispatcher';

import WorkTrackerCategoryDataStore from '@/data/work-tracker/work-tracker-category-data-store';

import { BudgetTotalUpdates } from '../../project/stores/project-budget-state-store';
import workTrackerItemGroupByCategoryStore from '@/data/work-tracker/work-tracker-item-group-by-category-store';

const includeAgentFeeAction = actionDispatcher.subscribe('work-tracker-include-agent-fees');

const toStateUpdate = ({ items }) => {
  return {
    categories: items,
  };
};

const workTrackerUpdates = WorkTrackerCategoryDataStore
  .updates
  .map(toStateUpdate);

const toCategoryState = ({ categories, budget, includeAgentFees }) => {
  if (!categories || !budget) {
    return {};
  }

  let acqTotal = 0;
  let saleTotal = 0;

  const categoriesWithTotals = map((category) => {
    let agentFees = { type: 'fixed', value: 0 };
    let salePrice = 0;

    let total = reduce((acc, item) => {
      const val = parseInt(item.value ?? 0, 10);

      if (item.itemTypeName === 'agentFees') {
        agentFees = item.value;
      }

      if (item.itemTypeName === 'renoCosts') {
        return acc;
      }

      if (Number.isNaN(val)) {
        return acc;
      }

      switch (item.itemTypeName) {
        case 'salePrice':
          salePrice = val;
          return acc - val;
        default:
          return acc + val;
      }
    }, 0, category.items || []);

    if (category.id === 'oTyVM3gQiHat8YAHwqxxay') {
      const safeSpent = budget?.renoCosts ?? 0;
      total += safeSpent;
      acqTotal = total;
    } else if (category.id === '4pK67Qh3zc8z1Dx9AwFLur') {
      if (includeAgentFees) {
        let agentFee = agentFees?.value || 0;
        if (agentFees?.type === 'percent') {
          agentFee = Math.round((salePrice * agentFees.value) / 100);
        }

        total += parseInt(agentFee, 10);
      }
      total *= -1;
      saleTotal = total;
    }

    const gst = (total * 3) / 23;

    return merge(category, {
      total,
      gst,
    });
  }, categories);

  const acqGst = (acqTotal * 3) / 23;
  const saleGst = (saleTotal * 3) / 23;
  const profit = saleTotal - saleGst - (acqTotal - acqGst);

  const allState = reduce((state, category) => {
    return assoc(`work-tracker-category-${category.id}`, merge(
      category,
      {
        acqTotal,
        acqGst,
        profit,
      },
    ), state);
  }, {}, categoriesWithTotals);

  return allState;
};

const publishState = (state) => {
  forEach((key) => stateStore.Publish('work-tracker', key, state[key]), keys(state));
};

const includeAgentFeeUpdates = once({ includeAgentFees: true })
  .merge(includeAgentFeeAction.map(pick(['includeAgentFees'])));

const categoryStateUpdates = combineTemplate({
  categories: workTrackerItemGroupByCategoryStore.updates.map(prop('items')),
  budget: BudgetTotalUpdates,
  includeAgentFees: includeAgentFeeUpdates.map(prop('includeAgentFees')),
})
  .map(toCategoryState);

const stateUnsubscribe = workTrackerUpdates.onValue(stateStore.Publish('work-tracker', 'work-tracker-categories'));
const categoryStateUnsubscribe = categoryStateUpdates.onValue(publishState);
const agentFeeStateUnsubscribe = includeAgentFeeUpdates.onValue(stateStore.Publish('work-tracker', 'work-tracker-include-agent-fees'));

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading category-state-store');
      stateUnsubscribe();
      agentFeeStateUnsubscribe();
      categoryStateUnsubscribe();
    });
  }
}
