import { when } from 'baconjs';

import { always, identity, prop } from 'rambda';
import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import propertyDataStore from '../data/property.data.store';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

const cloneAction = actionDispatcher.subscribe('property-clone');
const cloneAddressAction = actionDispatcher.subscribe('property-clone-address');
const navigateCloneAction = actionDispatcher.subscribe('navigate-property-clone');
const cloneNameAction = actionDispatcher.subscribe('property-clone-name');

const toCloneUpdate = ({ id }, { fullAddress, address }, { value }) => {
  return {
    id,
    name: value,
    address: {
      address1: address.line1,
      address2: address.line2,
      suburb: address.suburb,
      city: address.city,
      postcode: address.postcode,
      fullAddress,
    },
  };
};

const toCloneRequest = ({ id, name, address }) => {
  return Api.post(`/api/properties/${id}/clone`, { name, address });
};

const navigate = ({ id }) => {
  Router.push(`/properties/${id}`);
};

const navigateClone = ({ id }) => {
  Router.push(`/properties/${id}/clone`);
};

const cloneUpdates = when(
  [propertyDataStore.selectUpdates, cloneAddressAction.toProperty(), cloneNameAction.toProperty(), cloneAction],
  toCloneUpdate,
)
  .flatMap(toCloneRequest);

const cloneNavigationUpdates = when(
  [propertyDataStore.selectUpdates, navigateCloneAction],
  identity,
);

const cloneSuccessUpdates = cloneUpdates
  .skipErrors()
  .map(prop('data'));

const cloneFailedUpdates = cloneUpdates
  .errors()
  .mapError(always({ type: 'error', message: 'failed to clone property' }));

const navigateUnsubscribe = cloneSuccessUpdates.onValue(navigate);
const failedUnsubscribe = cloneFailedUpdates.onValue(notificationStore.publish);
const navigateCloneUnsubscribe = cloneNavigationUpdates.onValue(navigateClone);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading work-item-add-store');
      navigateUnsubscribe();
      failedUnsubscribe();
      navigateCloneUnsubscribe();
    });
  }
}
