import { useState } from 'react';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import { FormatMoney } from '@/engine/utils/number';
import Button from '@/components/button';
import DropDownItem from '@/components/drop-down-item/drop-down-item';
import Icon from '@/components/icon';

const WorkItemMenu = ({ item, isAdmin }) => {
  const [actionsOpen, setActionsOpen] = useState(false);

  return (
    item.canDelete && (
      <span className="nowrap">
        <Button
          id={`work-item${isAdmin ? '-admin' : ''}-remove`}
          className="budget-item-bit__mob-expanded-only-inlineblock text-danger"
          color="secondary"
          size="sm"
          actionMetaData={{ itemId: item.id }}
        >
          <Icon icon="remove_circle_outline" />
          Remove item
        </Button>
        {item.suggested ? (
          <p className="budget-item-bit__mob-expanded-only-inlineblock suggested-text">
            Suggested:&nbsp;
            {FormatMoney(item.suggested)}
          </p>
        ) : ''}
        <Dropdown
          isOpen={actionsOpen}
          toggle={() => setActionsOpen(!actionsOpen)}
          className="d-none d-md-inline-block"
        >
          <DropdownToggle
            className="btn-match-input btn-none"
            title="Item actions"
          >
            <Icon icon="more_vert" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu--overscroll">
            {item.suggested ? (
              <DropDownItem>
                <span className="suggested-text">
                  Suggested:&nbsp;
                  <span className="suggested-amount">
                    {FormatMoney(item.suggested)}
                  </span>
                </span>
              </DropDownItem>
            ) : ''}
            <DropDownItem id={`work-item${isAdmin ? '-admin' : ''}-remove`} actionMetaData={{ itemId: item.id }}>
              <Icon icon="remove_circle_outline" className="text-danger mr-2" />
              Remove item
            </DropDownItem>
          </DropdownMenu>
        </Dropdown>
      </span>
    )
  );
};

export default WorkItemMenu;
