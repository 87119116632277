import { filter, propEq } from 'rambda';

import WithActions from '@/engine/decorators/with-actions';

import TemplateAdminList from './template-admin-list';
import WithState from '@/engine/decorators/with-state';
import WithLoading from '@/engine/decorators/with-loading';

import { STATUS_ACTIVE, STATUS_DRAFT } from '@/shared/constants';

const TemplateAdmin = ({ templates }) => {
  if (!templates) {
    return null;
  }

  const activeTemplates = filter(propEq('status', STATUS_ACTIVE), templates || []);
  const draftTemplates = filter(propEq('status', STATUS_DRAFT), templates || []);

  return (
    <span>
      <div className="text-center">
        <h1 className="swatch-title swatch-title--main">Templates</h1>
      </div>
      <TemplateAdminList templates={activeTemplates} loaded={!!templates} />

      {draftTemplates?.length > 0 && (
        <>
          <div className="text-center mt-4">
            <h3 className="swatch-title swatch-title--main">Draft</h3>
          </div>
          <TemplateAdminList templates={draftTemplates} loaded={!!templates} />
        </>
      )}
    </span>
  );
};

export default WithState(WithActions(WithLoading(TemplateAdmin)));
