import { useEffect } from 'react';
import {
  Row,
  Col,
  Nav,
  NavItem,
} from 'reactstrap';
import classnames from 'classnames';

import { formatDate } from '@/engine/utils/date';

import Button from '@/components/button';
import Icon from '@/components/icon';

import { STATUS_COMPLETED, STATUS_ACTIVE } from '../../../../shared/constants';
import BudgetProgress from '@/components/budget-progress';
import PropertyHeadingBudget from './property-heading-budget';
import actionDispatcher from '@/engine/actions/action-dispatcher';

const PropertyHeading = ({ property }) => {
  useEffect(() => {
    return () => {
      actionDispatcher.dispatch({ id: 'unmount-property-print' });
    };
  }, [property.Id]);

  const imageClassName = classnames(
    'property-detail-image',
    { 'property-detail-image--default': !property.hasImage },
  );
  return (
    <div className="property-detail">
      <div
        className={imageClassName}
      >
        {property.hasImage && <img src={property.imageUrl} alt={property.name} />}
      </div>
      <div className="property-detail-info">
        <Row className="property-detail-info__row">
          <Col xs="12" sm="auto">
            <BudgetProgress
              id="property-budget-totals"
              complete={property.status === STATUS_COMPLETED}
              showTotal
            />
          </Col>
          <Col xs="auto" className="col-fill">
            <h1 className="mb-0">{property.name}</h1>
            <p className="mb-0">
              {property.createDate && (
                <span className="d-inline-block mr-4">
                  <strong>Started:</strong>
                  {' '}
                  {formatDate(new Date(property.createDate))}
                </span>
              )}
              {property.status === STATUS_COMPLETED && (
                <span className="d-inline-block">
                  <strong>Completed:</strong>
                  {' '}
                  {formatDate(new Date(property.completeDate))}
                </span>
              )}
            </p>
            <Nav className="property-detail-nav">
              <NavItem>
                <Button
                  id="property-status-toggle"
                  icon
                  title={
                    property.status === STATUS_ACTIVE
                      ? 'Mark this reno as complete'
                      : 'Mark this reno as incomplete'
                  }
                >
                  {property.status === STATUS_ACTIVE && (
                    <span>
                      <Icon icon="check_circle_outline" />
                      {' '}
                      <span className="d-none d-sm-inline">Mark as </span>
                      Complete
                    </span>
                  )}
                  {property.status === STATUS_COMPLETED && (
                    <span>
                      <Icon icon="highlight_off" />
                      {' '}
                      <span className="d-none d-sm-inline">Mark as </span>
                      Incomplete
                    </span>
                  )}
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  icon
                  id="property-edit"
                  actionMetaData={{ propertyId: property.id }}
                  title="Edit reno details"
                >
                  <span>
                    <Icon icon="edit" />
                    Edit
                  </span>
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  icon
                  id="navigate-property-clone"
                  title="Clone this property"
                  disableOnFirstClick
                >
                  <span>
                    <Icon icon="layers" />
                    Clone
                  </span>
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  icon
                  id="property-print"
                  actionMetaData={{ propertyId: property.id }}
                  title="Print this reno"
                >
                  <span>
                    <Icon icon="print" />
                    Print
                  </span>
                </Button>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <PropertyHeadingBudget id="property-budget-totals" />
      </div>
    </div>
  );
};

export default PropertyHeading;
