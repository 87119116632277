import { mergeAll as BaconMerge, when as BaconWhen } from 'baconjs';
import { merge, prop } from 'rambda';
import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import stateStore from '@/engine/stores/state-store';
import Api from '@/engine/utils/api';

const loadUpdates = actionDispatcher.subscribe('resetPasswordPage');
const passwordUpdates = actionDispatcher.subscribe('reset.password.form.password');
const confirmPasswordUpdates = actionDispatcher.subscribe('reset.password.form.confirmPassword');
const submitUpdates = actionDispatcher.subscribe('reset.password.form.submit');

const toResetLinkValidateRequest = ({ token, id }) => Api.get(`/api/login/reset-password?token=${token}&id=${id}`);
const toLoadState = ({ data }) => {
  return {
    loaded: true,
    validLink: data.validLink,
    expired: data.expired,
  };
};

const toResetUpdate = (password, confirmPassword) => {
  return {
    password: password.value,
    valid: password && confirmPassword && password.value === confirmPassword.value,
  };
};

const toResetPasswordRequestData = ({ token, id }, { password }) => {
  return {
    token,
    id,
    password,
  };
};

const toResetPasswordRequest = (data) => {
  return Api.post('/api/login/reset-password', data);
};

const toState = ({ valid }) => {
  return {
    send: valid,
    passwordsMatch: valid,
  };
};

const navigateHome = () => {
  Router.push('/');
};

const resetUpdates = BaconWhen(
  [passwordUpdates.toProperty(), confirmPasswordUpdates.toProperty(), submitUpdates],
  toResetUpdate,
);

const passwordStateUpdates = resetUpdates
  .map(toState);

const resetPasswordRequests = BaconWhen(
  [loadUpdates.toProperty(), resetUpdates.filter(prop('valid')).toEventStream()],
  toResetPasswordRequestData,
)
  .flatMap(toResetPasswordRequest);

const loadRequestUpdates = loadUpdates.flatMap(toResetLinkValidateRequest);

const loadStateUpdates = loadRequestUpdates.map(toLoadState);

const stateUpdates = BaconMerge(
  loadStateUpdates,
  passwordStateUpdates,
)
  .scan({}, merge);

stateUpdates.onValue(stateStore.Publish('auth', 'resetPasswordPage'));
resetPasswordRequests.onValue(navigateHome);
