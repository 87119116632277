import WithState from '@/engine/decorators/with-state';

import { FormatMoney } from '@/engine/utils/number';

import MoneyInput from '@/components/money-input';

const WorkItemRenoCosts = ({ item, renoCosts }) => {
  return (
    <tr className="budget-item-row budget-item-row--default">
      <td className="budget-item-row__description">
        <div className="match-input w-100">
          {item.label}
        </div>
        <span className="d-md-none d-inline-flex">
          <span className="pl-2 mr-5 mt-1">
            <div className="nowrap">{FormatMoney(renoCosts) || '-'}</div>
          </span>
        </span>
      </td>
      <td className="text-right budget-item-row__spent budget-item-bit__mob-show-for-expanded">
        <MoneyInput
          name={item.uid}
          className="form-control-no-browser-helpers"
          value={renoCosts}
          disabled
          placeholder="$0"
        />
      </td>
      <td className="budget-item-row__actions budget-item-bit__mob-show-for-expanded mt-2 mt-md-0" />
    </tr>
  );
};

export default WithState(WorkItemRenoCosts, { stateKey: 'property-budget-totals' });
