import { merge, dissoc } from 'rambda';

import ActionDispatcher from '../actions/action-dispatcher';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const WithActions = (Component, { actionKey } = {}) => {
  const ComponentWithActions = (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    const enabled = !!(props.actionKey || props.id || actionKey);
    const { actionMetaData } = props || {};

    if (process.env.NODE_ENV === 'development' && !enabled) {
      console.log('[WithActions]', getDisplayName(Component), 'Failed to find action key');
    }

    const publish = (data) => {
      if (enabled) {
        const actionData = {
          // eslint-disable-next-line react/destructuring-assignment
          id: props.actionKey || props.id || actionKey,
          data: merge(data, actionMetaData),
        };
        ActionDispatcher.dispatch(actionData);
      }
    };

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component {...dissoc('actionMetaData', props)} publish={publish} />
    );
  };

  return ComponentWithActions;
};

export default WithActions;
