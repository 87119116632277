/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import WithActions from '@/engine/decorators/with-actions';

const Icon = ({
  className, icon, size, publish, ...props
}) => {
  const clicked = () => {
    publish({ event: 'clicked' });
  };

  return (
    <span
      onClick={clicked}
      className={classNames(className, 'icon material-icons', {
        [`icon--size-${size}`]: size,
      })}
      {...props}
    >
      {icon}
    </span>
  );
};

export default WithActions(Icon, { actionKey: 'icon' });
