import { Container, Col, Row } from 'reactstrap';
import Input from '@/components/input';
import Button from '@/components/button';
import WithUser from '@/engine/decorators/with-user';

import LoginLogo from '@/components/login-logo';
import emailValidator from '@/engine/validators/email-validator';
import FormGroup from '@/components/form-group';

const LoginForm = ({ user }) => {
  if (user.isLoggedIn) {
    return <div />;
  }

  return (

    <form>
      <Container fluid>
        <Row>
          <Col xs="12" className="d-flex align-items-center justify-content-center">
            <LoginLogo />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6" lg="4" className="ml-auto mr-auto">
            <FormGroup lg>
              <Input id="login.form.email" name="email" validator={emailValidator} isRequired type="text" placeholder="Email Address" lg />
            </FormGroup>
            <FormGroup lg>
              <Input id="login.form.password" name="password" type="password" isRequired placeholder="Password" lg />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs="12" md="6" lg="4" className="ml-auto mr-auto">
            <Button
              id="login.form.login"
              color="primary"
              type="submit"
              size="lg"
              className="w-100"
            >
              Login
            </Button>
            <br />
            <span className="d-inline-block w-100 text-center mt-2 text-muted">
              Forgot your password?
              <Button id="login.form.forgot-password" type="link" color="link">Click here</Button>
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6" lg="4" className="ml-auto mr-auto">
            <hr className="w-100" />
            <div className='text-center mb-2 text-muted'>
              Haven’t used Reno Tracker before? Why not Register for your free trial now.
            </div>
            <Button
              id="login.form.register"
              color="secondary"
              type="submit"
              size="md"
              className="d-flex ml-auto mr-auto"
            >
              Register Now
            </Button>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default WithUser(LoginForm, { redirectToIndex: true, showLoader: true });
