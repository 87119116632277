import {
  when as BaconWhen,
} from 'baconjs';

import {
  always,
  identity,
} from 'rambda';

import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

import projectDataStore from '../data/project.data.store';
import projectItemDataStore from '../data/project-item-data-store';
import alertStore from '@/engine/stores/alert-store';

const projectRemoveAction = actionDispatcher.subscribe('project-remove');
const projectRemoveConfirmAction = actionDispatcher.subscribe('project-remove-confirm');

const toProjectRemoveUpdate = ({ items, keyIndex, selectedId }) => {
  const itemIndex = keyIndex[selectedId];
  if (itemIndex >= 0) {
    return items[itemIndex];
  }

  return null;
};

const toProjectRemoveRequest = (project) => {
  return Api.delete(`/api/projects/${project.id}`)
    .map(always(project));
};

const removeFromDataStore = ({ id, propertyId }) => {
  projectDataStore.remove({ id });
  projectItemDataStore.removeByProjectId({ projectId: id });
  Router.push(`/properties/${propertyId}`);
};

const deleteUpdates = projectRemoveAction
  .map(always({
    title: 'Delete Category',
    text: 'Are you sure you want to delete this category?',
    submitAction: 'project-remove-confirm',
    submitText: 'Delete',
  }));

const projectRemoveUpdates = BaconWhen(
  [projectDataStore.updates.toProperty(), projectRemoveConfirmAction],
  toProjectRemoveUpdate,
)
  .filter(identity)
  .flatMapLatest(toProjectRemoveRequest);

const projectRemoveSuccessUpdates = projectRemoveUpdates
  .skipErrors();

const projectRemoveFailedUpdates = projectRemoveUpdates
  .errors()
  .mapError(always({ message: 'Failed to remove project', type: 'error' }));

const closeModalUpdates = projectRemoveFailedUpdates.merge(projectRemoveSuccessUpdates);

const projectRemoveDsUnsubscribe = projectRemoveSuccessUpdates.onValue(removeFromDataStore);
const failedUnsubscribe = projectRemoveFailedUpdates.onValue(notificationStore.add);
const deleteModalUnsubscribe = deleteUpdates.onValue(alertStore.open);
const closeModalUnsubscribe = closeModalUpdates.onValue(alertStore.close);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading project-remove-store');
      projectRemoveDsUnsubscribe();
      failedUnsubscribe();
      deleteModalUnsubscribe();
      closeModalUnsubscribe();
    });
  }
}
