/* eslint-disable react/jsx-props-no-spreading */
import Head from 'next/head';
import StateContext from '@/engine/state-context';
import NotificationDisplay from '@/components/notifcation-display';

const AnonymousLayout = ({
  stateContext = 'common',
  title = 'RenoTracker',
  Component,
  componentProps = {},
}) => (
  <>
    <Head>
      <title>{title}</title>
    </Head>
    <NotificationDisplay />
    <StateContext.Provider value={stateContext}>
      {Component && <Component {...componentProps} />}
    </StateContext.Provider>
  </>
);

export default AnonymousLayout;
