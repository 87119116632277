import {
  Col,
  Row,
} from 'reactstrap';

import { FormatMoney } from '@/engine/utils/number';

import WithState from '@/engine/decorators/with-state';

const ProjectBudgetHeading = ({
  spent,
  budget,
  prefix = '',
}) => {
  const spentLabel = prefix ? `${prefix} Spent` : 'Spent';
  const leftLabel = prefix ? `${prefix} Left` : 'Left';
  return (
    <Col sm="6" xs="12">
      <Row>
        <Col xs="6" sm="auto" className="text-center pr-1 pr-sm-2">
          <h3 className="swatch-title swatch-title--pale">
            {FormatMoney(spent ?? 0)}
            <small className="tiny">{spentLabel}</small>
          </h3>
        </Col>
        <Col xs="6" sm="auto" className="text-center pr-1 pr-sm-2">
          <h3 className="swatch-title swatch-title--pale">
            {budget ? FormatMoney(budget - spent) : 0}
            <small className="tiny">{leftLabel}</small>
          </h3>
        </Col>
      </Row>
    </Col>
  );
};

export default WithState(ProjectBudgetHeading);
