import { mergeAll as BaconMergeAll, fromArray, fromPromise } from 'baconjs';

import {
  always,
  identity,
  merge,
  objOf,
  prop,
} from 'rambda';

import StateStore from '@/engine/stores/state-store';
import actionDispatcher from '@/engine/actions/action-dispatcher';

import { VIEW_DETAIL, VIEW_TILES } from '../constants';

import propertyDataStore from '../data/property.data.store';
import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

// ACTIONS

const clearSearchAction = actionDispatcher.subscribe('property-dossier-search-clear');
const selectTilesViewAction = actionDispatcher.subscribe('property-dossier-tiles-view');
const selectDetailsViewAction = actionDispatcher.subscribe('property-dossier-detail-view');
const searchTextAction = actionDispatcher.subscribe('property-dossier-search');
const filesAddedAction = actionDispatcher.subscribe('property-dossier-add');

// functions
const toEmptySearch = () => ({ searchText: '' });

const getBase64File = ({ propertyId, file }) => {
  return fromPromise(new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (data) => {
      resolve({
        data: btoa(data.target.result),
        contentType: file.type,
        propertyId: propertyId,
      });
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  }));
};

const toUploadRequest = ({ propertyId, contentType, data }) => {
  return Api.post(`/api/properties/${propertyId}/files`, { contentType, data });
};

// streams
const viewSelectUpdates = BaconMergeAll(
  selectTilesViewAction.map(() => ({ view: VIEW_TILES })),
  selectDetailsViewAction.map(() => ({ view: VIEW_DETAIL })),
);

const clearSearchUpdates = clearSearchAction
  .map(toEmptySearch);

const searchTextUpdates = searchTextAction
  .map(prop('value'))
  .map(objOf('searchText'))
  .merge(clearSearchUpdates);

const searchTextStateUpdates = searchTextUpdates
  .map(prop('searchText'))
  .map(objOf('value'));

const dossierFilesUpdates = propertyDataStore.selectUpdates
  .map(prop('propertyFiles'))
  .map(objOf('files'));

const dossierStateUpdates = BaconMergeAll(
  searchTextUpdates,
  viewSelectUpdates,
  dossierFilesUpdates,
)
  .scan({ view: VIEW_TILES, searchText: '', files: [] }, merge);

const fileRequestUpdates = filesAddedAction
  .map(prop('files'))
  .flatMap(fromArray)
  .doLog('fileRequestUpdates')
  .flatMap(getBase64File)
  .flatMap(toUploadRequest)
  .doLog('fileRequestUpdates');

const fileRequestFailedUpdates = fileRequestUpdates
  .errors()
  .mapError(always({ type: 'error', message: 'Failed to save file to dossier.' }));

// SUBSCRIBERS
const searchTextStateUnSubscribe = searchTextStateUpdates.onValue((state) => StateStore.Publish('properties', 'property-budget-search', state));
const dossierStateUnsubscribe = dossierStateUpdates.onValue(StateStore.Publish('properties', 'property-dossier'));

const fileRequestFailedUnsubscribe = fileRequestFailedUpdates.onValue(notificationStore.publish);
const fileRequestUnsubscribe = fileRequestUpdates.onValue();

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading property-dossier-store');
      fileRequestFailedUnsubscribe();
      dossierStateUnsubscribe();
      searchTextStateUnSubscribe();
      fileRequestUnsubscribe();
    });
  }
}
