import WithActions from '@/engine/decorators/with-actions';
import WithState from '@/engine/decorators/with-state';

const WorkTrackerIncludeAgentFees = ({ publish, includeAgentFees = true }) => {
  const changeIncludeAgentFees = (e) => {
    publish({ includeAgentFees: e.target.checked });
  };

  return (
    <>
      <label htmlFor="includeAgentFees">Include agent fees</label>
      <input
        id="includeAgentFees"
        type="checkbox"
        checked={includeAgentFees}
        onChange={changeIncludeAgentFees}
        className="ml-2"
      />
    </>
  );
};

export default WithActions(WithState(WorkTrackerIncludeAgentFees));
