import WithState from '@/engine/decorators/with-state';

const { FormatMoney } = require('@/engine/utils/number');

const TotalsFinancial = ({ total, profit }) => {
  return (
    <table className="list-items table-accentuated table-borderless table-headed table table-sm">
      <tbody>
        <tr>
          <td>Total Financing and Other Costs</td>
          <td className="text-right">{FormatMoney(total)}</td>
        </tr>
        <tr>
          <td>Taxable Net Profit/Loss (Net of GST)</td>
          <td className="text-right">{FormatMoney(profit - total)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default WithState(TotalsFinancial);
