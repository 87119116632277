import { always } from 'rambda';

import workTrackerItemDataStore from '@/data/work-tracker/work-tracker-item-data-store';
import actionDispatcher from '@/engine/actions/action-dispatcher';
import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

const workItemUpdateAction = actionDispatcher.subscribe('work-item-admin-update');

const toWorkItemUpdateRequest = (workItem) => {
  return Api.put(`/api/admin/work-tracker/items/${workItem.id}`, { value: workItem.value });
};

const toWorkItemUpdate = ({ value, itemId }) => {
  return {
    id: itemId,
    value,
  };
};

const workItemUpdates = workItemUpdateAction
  .map(toWorkItemUpdate);

const workItemUpdateRequests = workItemUpdates
  .flatMap(toWorkItemUpdateRequest);

const workItemUpdateFailedUpdates = workItemUpdateRequests
  .errors()
  .mapError(always({ message: 'Failed to save update', type: 'error' }));

const dataStoreUpdatesUnsubscribe = workItemUpdates.onValue(workTrackerItemDataStore.update);
const failedUnsubscribe = workItemUpdateFailedUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading admin-work-item-update-store');
      dataStoreUpdatesUnsubscribe();
      failedUnsubscribe();
    });
  }
}
