import {
  always,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import alertStore from '@/engine/stores/alert-store';

import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

const activateAction = actionDispatcher.subscribe('admin-user-activate');
const activateConfirmAction = actionDispatcher.subscribe('admin-user-activate-confirm');

const toActivateRequest = ({ userId }) => {
  return Api.post(`/api/admin/users/${userId}/activate`);
};

const toActivateAlert = ({ userId, email }) => {
  return {
    title: 'Activate User',
    text: `Are you sure you want to activate the user with email ${email}?`,
    submitAction: 'admin-user-activate-confirm',
    submitText: 'Activate',
    actionMetaData: { userId },
  };
};

const activateUpdate = activateAction
  .map(toActivateAlert);

const activateRequestUpdates = activateConfirmAction.flatMap(toActivateRequest);
const activateSuccessUpdates = activateRequestUpdates
  .skipErrors();

const activateFailedUpdates = activateRequestUpdates
  .errors()
  .mapError(always({
    message: 'Failed to activate user',
    type: 'error',
  }));

const activateModalUnsubscribe = activateUpdate.onValue(alertStore.open);
const closeModalUnsubscribe = activateSuccessUpdates
  .merge(activateFailedUpdates)
  .onValue(alertStore.close);

const notificationUnsubscribe = activateFailedUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      activateModalUnsubscribe();
      closeModalUnsubscribe();
      notificationUnsubscribe();
    });
  }
}
