import {
  when as BaconWhen,
} from 'baconjs';

import {
  always,
  identity,
  find,
  prop,
  propEq,
  merge,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

import budgetSuggestionsDataStore from '@/data/budget/budget-suggestions-data-store';

import { STATUS_ACTIVE } from '@/shared/constants';

import projectItemDataStore from '../data/project-item-data-store';

const addAction = actionDispatcher.subscribe('project-budget-add');
const addSuggestionAction = actionDispatcher.subscribe('project-budget-add-suggestion');

const toAddRequest = ({ projectId }) => {
  return Api.post(`/api/projects/${projectId}/items`);
};

const toAddSuggestionUpdate = ({ items }, { suggestionId }) => {
  const item = find(propEq('id', suggestionId), items);
  if (!item) {
    return false;
  }

  return merge(item, { status: STATUS_ACTIVE });
};

const toEditRequest = ({ id }) => {
  return Api.put(`/api/project-items/${id}`, { status: STATUS_ACTIVE });
};

const addRequestUpdates = addAction
  .flatMap(toAddRequest);

const addRequestFailedUpdates = addRequestUpdates
  .errors()
  .mapError(always({ message: 'failed to add project item', type: 'error' }));

const addRequestSuccessUpdates = addRequestUpdates
  .map(prop('data'));

const addSuggestionUpdates = BaconWhen(
  [budgetSuggestionsDataStore.updates.toProperty(), addSuggestionAction],
  toAddSuggestionUpdate,
)
  .filter(identity);

const addSuggestionRequestUpdates = addSuggestionUpdates
  .flatMap(toEditRequest);

const addSuggestionFailedRequestUpdates = addSuggestionRequestUpdates
  .errors()
  .mapError(always({ message: 'Failed to add suggestion to project.', type: 'error' }));

const failedUnsubscribe = addRequestFailedUpdates.onValue(notificationStore.publish);
const addUnsubscribe = addRequestSuccessUpdates.onValue(projectItemDataStore.insert);
const addToItemsDataStoreUnsubscribe = addSuggestionUpdates.onValue(projectItemDataStore.insert);
const removeFromSuggestionsUnsubscribe = addSuggestionUpdates
  .onValue(budgetSuggestionsDataStore.remove);
const failedSuggestionUnsubscribe = addSuggestionFailedRequestUpdates
  .onValue(notificationStore.publish);


if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      failedUnsubscribe();
      addUnsubscribe();
      addToItemsDataStoreUnsubscribe();
      removeFromSuggestionsUnsubscribe();
      failedSuggestionUnsubscribe();
    });
  }
}
