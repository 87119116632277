import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useSWR, { mutate } from 'swr';

import actionDispatcher from '../actions/action-dispatcher';

const fetcher = async (url) => {
  const res = await fetch(url);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

const mutateUser = (user) => {
  mutate('/api/login/user', user, false);
};

export const MutateUser = mutateUser;

export default function UseUser({
  redirectToLogin = false,
  redirectToIndex = false,
} = {}) {
  const { data, error } = useSWR('/api/login/user', fetcher);
  const router = useRouter();
  const user = data?.data;

  useEffect(() => {
    if (redirectToIndex && user?.isLoggedIn) {
      router.push('/');
    }

    if (redirectToLogin && (user?.isLoggedIn === false || error?.status === 403)) {
      actionDispatcher.dispatch({ id: 'logout', data: {} });
    }
  }, [data, error?.status]);

  return { user };
}
