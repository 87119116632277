import AppLayout from '@/layouts/app';

import PropertiesList from '../components/list/properties-list';

const pageProps = {
  id: 'properties',
};

const PropertiesPage = () => (
  <AppLayout Component={PropertiesList} componentProps={pageProps} />
);

export default PropertiesPage;
