import { Bus } from 'baconjs';

class ActionDispatcher {
  constructor() {
    this.actionsBusses = {};
  }

  dispatch({ id, data }) {
    if (!this.actionsBusses[id]) {
      console.log('no action bus for id: ', id, data);
      return;
    }
    this.actionsBusses[id].push(data);
  }

  subscribe(id) {
    if (!this.actionsBusses[id]) {
      this.actionsBusses[id] = new Bus();
    }

    return this.actionsBusses[id].toEventStream();
  }
}

// only allow 1 action dispatcher
export default new ActionDispatcher();
