import { useState } from 'react';
import classNames from 'classnames';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import { FormatMoney } from '@/engine/utils/number';
import Button from '../button';
import DropDownItem from '../drop-down-item';
import Icon from '../icon';
import Input from '../input';
import MoneyInput from '../money-input';

const ProjectItem = ({
  projectId,
  item,
}) => {
  const [showActionsDropDown, setShowActionsDropDown] = useState(false);
  const [itemExpanded, setItemExpanded] = useState(false);
  const {
    name,
    quantity,
    budget,
    spent,
    suggestedBudget,
  } = item;

  const actionMetaData = { projectId, itemId: item.id };

  const openItem = () => {
    setItemExpanded(!itemExpanded);
  };

  const toggleItemActions = () => {
    setShowActionsDropDown(!showActionsDropDown);
  };

  const renderActions = () => (
    <span className="nowrap">
      <Button
        id="project-item-remove"
        className="budget-item-bit__mob-expanded-only-inlineblock text-danger"
        color="secondary"
        size="sm"
        actionMetaData={actionMetaData}
      >
        <Icon icon="remove_circle_outline" />
        Remove item
      </Button>
      {suggestedBudget ? (
        <p className="budget-item-bit__mob-expanded-only-inlineblock suggested-text">
          Suggested:&nbsp;
          {FormatMoney(suggestedBudget)}
        </p>
      ) : ''}
      <Dropdown
        isOpen={showActionsDropDown}
        toggle={toggleItemActions}
        className="d-none d-md-inline-block"
      >
        <DropdownToggle
          className="btn-match-input btn-none"
          title="Item actions"
        >
          <Icon icon="more_vert" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu--overscroll">
          {suggestedBudget ? (
            <DropDownItem>
              <span className="suggested-text">
                Suggested:&nbsp;
                <span className="suggested-amount">
                  {FormatMoney(suggestedBudget)}
                </span>
              </span>
            </DropDownItem>
          ) : ''}
          <DropDownItem id="project-item-remove" actionMetaData={actionMetaData}>
            <Icon icon="remove_circle_outline" className="text-danger mr-2" />
            Remove item
          </DropDownItem>
        </DropdownMenu>
      </Dropdown>
    </span>
  );

  return (
    <tr
      className={classNames(
        'budget-item-row',
        'budget-item-row--default',
        {
          'row-accentuated': item.isSuggested,
          'is-expanded': itemExpanded,
        },
      )}
    >
      <td className="budget-item-row__description">
        <div
          onClick={openItem}
          className="match-input budget-item-bit__mob-show-for-not-expanded w-100"
        >
          {item.name || 'New Item'}
        </div>
        <Input
          id="project-item-name"
          value={name}
          actionMetaData={actionMetaData}
          className="budget-item-bit__mob-show-for-expanded"
        />
        <span className="d-md-none d-inline-flex">
          <span className="pl-2">
            <label htmlFor="" className="d-block text-right line-height-1">
              <small>Left</small>
            </label>
            <div className="nowrap">{FormatMoney(budget - spent) || '-'}</div>
          </span>
          <Button
            color="none"
            size="sm"
            onClick={openItem}
            className="btn-circle p-0 ml-2"
          >
            <Icon
              icon={itemExpanded ? 'expand_less' : 'expand_more'}
              size="md"
            />
          </Button>
        </span>
      </td>
      <td className="text-right budget-item-row__quantity budget-item-bit__mob-show-for-expanded">
        <label htmlFor="" className="budget-item-row__label text-center">
          Quantity
        </label>
        <div className="flex-nowrap input-group">
          <div className="input-group-prepend">
            <Button id="project-item-quantity-remove" actionMetaData={actionMetaData} type="button" title="Decrease quantity" className="btn-icon pl-0 btn btn-secondary btn-xs">
              <span className="icon material-icons">remove</span>
            </Button>
          </div>
          <Input id="project-item-quantity" min="0" type="number" className="minw-3 form-control-no-browser-helpers text-center form-control" value={quantity} actionMetaData={actionMetaData} />
          <div className="input-group-append">
            <Button id="project-item-quantity-add" actionMetaData={actionMetaData} type="button" title="Increase quantity" className="btn-icon pr-0 btn btn-secondary btn-xs">
              <span className="icon material-icons">add</span>
            </Button>
          </div>
        </div>
      </td>
      <td className="text-right budget-item-row__budget budget-item-bit__mob-show-for-expanded">
        <label htmlFor="" className="budget-item-row__label">
          Budget
        </label>
        <MoneyInput id="project-item-budget" value={budget} actionMetaData={actionMetaData} />
      </td>
      <td className="text-right budget-item-row__spent budget-item-bit__mob-show-for-expanded">
        <label htmlFor="" className="budget-item-row__label">
          Spent
        </label>
        <MoneyInput id="project-item-spent" value={spent} actionMetaData={actionMetaData} />
      </td>
      <td className="text-right budget-item-row__available">
        <label htmlFor="" className="budget-item-row__label">
          Left
        </label>
        <span className="mt-2 d-inline-block">
          {FormatMoney(budget - spent)}
        </span>
      </td>
      <td className="budget-item-row__actions budget-item-bit__mob-show-for-expanded mt-2 mt-md-0">
        {renderActions()}
      </td>
    </tr>
  );
};

export default ProjectItem;
