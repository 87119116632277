import {
  when as BaconWhen,
} from 'baconjs';

import {
  always,
  identity,
  not,
  pipe,
  prop,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';

import budgetSuggestionsDataStore from '@/data/budget/budget-suggestions-data-store';

import projectItemDataStore from '../data/project-item-data-store';
import { STATUS_ITEM_SUGGESTED } from '@/shared/constants';
import notificationStore from '@/engine/stores/notification-store';

const removeAction = actionDispatcher.subscribe('project-item-remove');

const toRemoveUpdate = ({ keyIndex, items }, { itemId }) => {
  if (keyIndex[itemId] === undefined) {
    return null;
  }

  return items[keyIndex[itemId]];
};

const toRemoveSuggestionRequest = ({ id }) => {
  return Api.put(`/api/project-items/${id}`, { status: STATUS_ITEM_SUGGESTED });
};

const toRemoveItemRequest = ({ id }) => {
  return Api.delete(`/api/project-items/${id}`);
};

const removeUpdates = BaconWhen(
  [projectItemDataStore.updates.toProperty(), removeAction],
  toRemoveUpdate,
)
  .filter(identity);

const removeSuggestionUpdates = removeUpdates
  .filter(prop('suggestedBudget'));

const removeSuggestionRequestUpdates = removeSuggestionUpdates
  .flatMap(toRemoveSuggestionRequest);

const removeItemUpdates = removeUpdates
  .filter(pipe(prop('suggestedBudget'), not));

const removeItemRequestUpdates = removeItemUpdates
  .flatMap(toRemoveItemRequest);

const failedRemoveUpdates = removeSuggestionRequestUpdates
  .merge(removeItemRequestUpdates)
  .errors()
  .mapError(always({ message: 'Failed to remove item', type: 'error' }));

const addToSuggestionsDsUnsubscribe = removeSuggestionUpdates
  .onValue(budgetSuggestionsDataStore.insert);

const removeProjectItemDsUnsubscribe = removeUpdates
  .onValue(projectItemDataStore.remove);

const failedNotificationUnsubscribe = failedRemoveUpdates
  .onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading project-item-remove-store');
      addToSuggestionsDsUnsubscribe();
      removeProjectItemDsUnsubscribe();
      failedNotificationUnsubscribe();
    });
  }
}
