export const STATUS_DRAFT = 'draft';
export const STATUS_ACTIVE = 'active';
export const STATUS_COMPLETED = 'completed';
export const STATUS_DELETED = 'deleted';
export const STATUS_ITEM_SUGGESTED = 'optional';

export const ACCOUNT_STATUS_TRIAL = 0;
export const ACCOUNT_STATUS_FREE = 1;
export const ACCOUNT_STATUS_PAID = 2;
export const ACCOUNT_STATUS_CANCELLED = 3;
export const ACCOUNT_STATUS_BILL_FAIL = 4;
export const ACCOUNT_STATUS_DELETED = 5;
export const ACCOUNT_STATUS_EXPIRED = 6;
