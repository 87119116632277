import React from 'react';
import Button from '@/components/button';

import Plus from '@/images/white-plus.inline.svg';

const AddPropertyButton = ({
  className,
  color = 'primary',
  size = 'md',
  title = 'Add Reno',
  swatch = 'lg',
}) => (
  <Button
    id="add-property"
    size={size}
    swatch={swatch}
    color={color}
    className={className}
  >
    <Plus className="btn-swatch__icon btn-swatch__icon--larger" />
    <br />
    {title}
  </Button>
);

export default AddPropertyButton;
