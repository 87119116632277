import {
  always,
  merge,
  propEq,
} from 'rambda';

import {
  mergeAll as BaconMergeAll,
  when as BaconWhen,
} from 'baconjs';

import actionDispatcher from '@/engine/actions/action-dispatcher';

import projectDataStore from '../data/project.data.store';

import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

const nameUpdateAction = actionDispatcher.subscribe('project-name');
const notesUpdateAction = actionDispatcher.subscribe('project-notes');
const scopeUpdateAction = actionDispatcher.subscribe('project-scope');

const toNameChange = (project, { value }) => {
  return {
    data: {
      name: value,
    },
    valid: !!value,
    projectId: project.id,
    propertyId: project.propertyId,
    changed: project.name !== value,
  };
};

const toNotesChange = (project, { value }) => {
  return {
    data: {
      notes: value,
    },
    valid: true,
    projectId: project.id,
    propertyId: project.propertyId,
    changed: project.notes !== value,
  };
};

const toScopeChange = (project, { value }) => {
  return {
    data: {
      scopeOfWork: value,
    },
    valid: true,
    projectId: project.id,
    propertyId: project.propertyId,
    changed: project.scopeOfWork !== value,
  };
};

const updateDataStore = ({ projectId, data }) => {
  projectDataStore.update(merge({ id: projectId }, data));
};

const toProjectUpdateRequest = ({ projectId, data }) => {
  return Api.put(`/api/projects/${projectId}`, data);
};

const nameBlurUpdates = nameUpdateAction.filter(propEq('event', 'blur'));
const notesBlurUpdates = notesUpdateAction.filter(propEq('event', 'blur'));
const scopeBlurUpdates = scopeUpdateAction.filter(propEq('event', 'blur'));

const nameChangeUpdates = BaconWhen(
  [projectDataStore.selectUpdates.toProperty(), nameBlurUpdates],
  toNameChange,
)
  .filter(propEq('changed', true));

const notesChangeUpdates = BaconWhen(
  [projectDataStore.selectUpdates.toProperty(), notesBlurUpdates.toEventStream()],
  toNotesChange,
)
  .filter(propEq('changed', true));

const scopeChangeUpdates = BaconWhen(
  [projectDataStore.selectUpdates.toProperty(), scopeBlurUpdates],
  toScopeChange,
)
  .filter(propEq('changed', true));

const projectChangeUpdates = BaconMergeAll(
  nameChangeUpdates,
  notesChangeUpdates,
  scopeChangeUpdates,
);

const validProjectChangeUpdates = projectChangeUpdates
  .filter(propEq('valid', true));

const projectUpdateRequests = validProjectChangeUpdates
  .flatMap(toProjectUpdateRequest);

const failedProjectUpdateRequests = projectUpdateRequests
  .errors()
  .mapError(always({ type: 'error', message: 'Failed to update project' }));

validProjectChangeUpdates.onValue(updateDataStore);
failedProjectUpdateRequests.onValue(notificationStore.publish);
