import AppLayout from '@/layouts/app';

import Profile from '../components/admin-details/admin-user-details';

const pageProps = {
  id: 'admin-user-details',
};

const AdminUserDetailsPage = () => {
  return (
    <AppLayout
      Component={Profile}
      userRole="admin"
      componentProps={pageProps}
    />
  );
};

export default AdminUserDetailsPage;
