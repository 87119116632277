import {
  assoc,
  filter,
  merge,
  not,
  pipe,
  propEq,
  reduce,
} from 'rambda';

import DataStore from '@/engine/stores/data-store';
import addIndex from '@/engine/funcs/add-index';

const removeByProjectId = (prev, { projectId, operation }) => {
  const newItems = filter(pipe(propEq('projectId', projectId), not), prev.items);
  const keyIndex = addIndex(reduce)((indexObj, nextItem, index) => {
    return assoc(nextItem.id, index, indexObj);
  }, {}, newItems);

  return merge(
    prev,
    {
      items: newItems,
      keyIndex,
      operation,
    },
  );
};
class ProjectItemDataStore extends DataStore {
  constructor() {
    super({
      operations: {
        removeByProjectId,
      },
    });

    this.removeByProjectId = this.removeByProjectId.bind(this);
  }

  removeByProjectId({ projectId }) {
    this.stream.push({ operation: 'removeByProjectId', projectId });
  }
}

export default new ProjectItemDataStore();
