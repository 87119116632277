import { propEq } from 'rambda';

import ActionDispatcher from '@/engine/actions/action-dispatcher';
import api from '@/engine/utils/api';

import PropertiesDataStore from '../data/properties.data.store';
import stateStore from '@/engine/stores/state-store';

const loadUpdates = ActionDispatcher.subscribe('properties').filter(propEq('event', 'load'));

const loadProperties = () => {
  return api.get('/api/properties');
};

const publishProperties = ({ loaded, items } = {}) => {
  stateStore.Publish('properties', 'properties', { loaded, items });
};

const refreshProperties = ({ data }) => {
  PropertiesDataStore.load(data);
};

const propertiesFromApiUpdates = loadUpdates.flatMap(loadProperties);

loadUpdates.onValue(PropertiesDataStore.clear);
propertiesFromApiUpdates.onValue(refreshProperties);

PropertiesDataStore.updates.onValue(publishProperties);
