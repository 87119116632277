import { Bus } from 'baconjs';

const routeChangeEvents = new Bus();

// Router.events.routeChangeComplete((url) => {
//   routeChangeEvents.push({ url });
// });

routeChangeEvents.log('routeChanges');

const publish = ({
  asPath,
  pathName,
  query,
  route,
}) => {
  routeChangeEvents.push({
    asPath,
    pathName,
    query,
    route,
  });
};

export default {
  publish,
};
