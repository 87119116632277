import ActionDispatcher from '@/engine/actions/action-dispatcher';

const Form = ({ children, className = '', actionKey }) => {
  const onSubmit = () => {
    ActionDispatcher.dispatch({ id: actionKey, data: { event: 'submit' } });
  };

  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default Form;
