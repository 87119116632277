import AppLayout from '@/layouts/app';

import AddProperty from '../components/add/add-property';

const pageProps = {
  id: 'add-property-page',
};

const AddPropertyPage = () => {
  return <AppLayout Component={AddProperty} componentProps={pageProps} />;
};

export default AddPropertyPage;
