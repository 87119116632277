import {
  assoc,
  forEach,
  keys,
  prop,
  reduce,
} from 'rambda';

import stateStore from '@/engine/stores/state-store';

import WorkTrackerCategoryDataStore from '@/data/work-tracker/work-tracker-category-data-store';
import workTrackerItemGroupByCategoryStore from '@/data/work-tracker/work-tracker-item-group-by-category-store';

const toStateUpdate = ({ items }) => {
  return {
    categories: items,
  };
};

const workTrackerUpdates = WorkTrackerCategoryDataStore
  .updates
  .map(toStateUpdate);

const toCategoryState = (categories) => {
  if (!categories) {
    return {};
  }

  const allState = reduce((state, category) => {
    return assoc(`work-tracker-admin-category-${category.id}`, category, state);
  }, {}, categories);

  return allState;
};

const publishState = (state) => {
  forEach((key) => stateStore.Publish('work-tracker', key, state[key]), keys(state));
};

const categoryStateUpdates = workTrackerItemGroupByCategoryStore.updates
  .map(prop('items'))
  .map(toCategoryState);

const stateUnsubscribe = workTrackerUpdates.onValue(stateStore.Publish('work-tracker-admin', 'work-tracker-admin-categories'));
const categoryStateUnsubscribe = categoryStateUpdates.onValue(publishState);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading admin-category-state-store');
      stateUnsubscribe();
      categoryStateUnsubscribe();
    });
  }
}
