import { Bus, mergeAll as BaconMergeAll } from 'baconjs';
import {
  eqProps,
  filter,
  indexOf,
  pipe,
  propOr,
} from 'rambda';

const doFilter = (prev, next) => {
  const items = next.items || prev.items;
  const field = next.field || prev.field;
  const value = next.value || prev.value;

  if (!items || !field || !value) {
    return {
      items,
      field,
      value,
    };
  }

  return {
    items: filter(pipe(propOr('', field), (fieldValue) => indexOf(value, fieldValue) >= 0), items),
    field,
    value,
  };
};

class FilterStore {
  constructor(dataStore) {
    this.filterChanges = new Bus();
    this.updates = BaconMergeAll(
      this.filterChanges.toEventStream(),
      dataStore.updates.toEventStream().skipDuplicates(eqProps('items')),
    ).scan({ items: [] }, doFilter);
  }

  searchField(field, value) {
    this.filterChanges.push({ operation: 'filter', field, value });
  }
}

export default FilterStore;
