import { always } from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';
import { MutateUser } from '@/engine/utils/use-user';

const updateAvatarAction = actionDispatcher.subscribe('profile-avatar-page');

const updateUser = ({ avatar }) => {
  return Api.put('/api/profile/avatar', { avatar });
};

const updateLocalUser = (data) => {
  MutateUser(data);
};

const updateUserUpdates = updateAvatarAction
  .flatMapLatest(updateUser);

const updateUserSuccessUpdates = updateUserUpdates.skipErrors();
const updateUserFailedUpdates = updateUserUpdates.errors().mapError(always({ message: 'Failed to update avatar', type: 'error' }));

const successUnsub = updateUserSuccessUpdates.onValue(updateLocalUser);
const failedUnsub = updateUserFailedUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.log('unloading avatar-update-store');
      failedUnsub();
      successUnsub();
    });
  }
}
