import { Row, Col } from 'reactstrap';

import Button from '@/components/button';
import Plus from '@/images/white-plus.inline.svg';
// import PropertyDossierTile from './dossier-tile';

const PropertyDossierTiles = ({ files }) => {
  return (
    <Row
      className="row-space-items"
    >
      <Col
        xs="6"
        sm="6"
        md="4"
        lg="3"
        className="align-items-center d-flex justify-content-center"
      >
        <Button
          id="property-project-add"
          size="md"
          swatch="lg"
          color="primary"
          className="project-list-add"
        >
          <Plus className="btn-swatch__icon btn-swatch__icon--larger" />
          <br />
          <span>
            <small>Add file to</small>
            <br />
            Dossier
          </span>
        </Button>
      </Col>

      {files.map((file) => (
        <Col key={file.id} xs="6" sm="6" md="4" lg="3">

        </Col>
      ))}
    </Row>
  );
};

export default PropertyDossierTiles;
