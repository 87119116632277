import { Row, Col, Label } from 'reactstrap';

import classNames from 'classnames';

import WithState from '@/engine/decorators/with-state';

import Button from '@/components/button';
import Icon from '@/components/icon';
import Input from '@/components/input';

import { VIEW_DETAIL, VIEW_TILES } from '../../constants';
import PropertyDossierTiles from './dossier-tiles';
import DossierUploader from './dossier-uploader';

const PropertyDossier = ({
  files = [],
  searchText = '',
  propertyId,
  view = VIEW_TILES,
}) => {
  const renderDossierSearch = () => {
    return (
      <Row className="mb-4 align-items-center">
        <Col xs={{ size: '12', order: '2' }} sm className="d-flex">
          <Input
            id="property-dossier-search"
            placeholder="Find a file"
          />
          {searchText && (
            <Button id="property-dossier-search-clear" className="nowrap">
              <Icon icon="clear" />
              <span className="d-none d-md-inline"> Clear Search</span>
            </Button>
          )}
        </Col>
        <Col
          xs={{ size: '12', order: '1' }}
          sm={{ size: 'auto', order: '3' }}
          className="mb-2 mb-sm-0"
        >
          <Label className="mr-2">View</Label>
          <Button
            className={classNames({ active: view === VIEW_TILES })}
            id="property-dossier-tiles-view"
            title="Tiles View"
          >
            <Icon icon="view_module" size="md" />
          </Button>
          <Button
            className={classNames({ active: view === VIEW_DETAIL })}
            id="property-dossier-detail-view"
            title="Detail View"
          >
            <Icon icon="list" size="md" />
          </Button>
        </Col>
      </Row>
    );
  };

  const renderNoFiles = () => {
    return (
      <div className="text-center">
        <h4>Welcome to your property dossier!</h4>
        <p>
          To get started, upload some files by pressing the button below...
        </p>
      </div>
    );
  };

  const renderUploader = () => (
    <Row
      className="row-space-items justify-content-center mt-2"
    >
      <Col
        xs="6"
        sm="6"
        md="4"
        lg="3"
        className="align-items-center d-flex justify-content-center"
      >
        <DossierUploader id="property-dossier-add" propertyId={propertyId} />
      </Col>
    </Row>
  );

  return (
    <>
      {renderDossierSearch()}
      {!files?.length && renderNoFiles()}
      {files?.length > 0 && view === VIEW_TILES && (
        <PropertyDossierTiles files={files} />
      )}
      {files?.length > 0 && view === VIEW_DETAIL && (
        <div>detail</div>
      )}
      {renderUploader()}
    </>
  );
};

export default WithState(PropertyDossier);
