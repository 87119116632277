import { merge } from 'rambda';

const insert = (prev, next) => {
  const { items } = prev;
  const newItems = [...items];
  let { keyIndex } = prev;

  if (prev.keyIndex[next.item.id]) {
    newItems.splice(prev.keyIndex[next.item.id], 1, next.item);
  } else {
    newItems.push(next.item);
    keyIndex = merge(keyIndex, { [next.item.id]: newItems.length - 1 });
  }

  return merge(prev, {
    items: newItems,
    operation: 'insert',
    keyIndex,
    added: [next.item],
  });
};

export default insert;
