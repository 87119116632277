import {
  always,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import alertStore from '@/engine/stores/alert-store';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

// ACTIONS
const unsubscribeAction = actionDispatcher.subscribe('cancel-subscription');
const unsubscribeConfirmAction = actionDispatcher.subscribe('unsubscribe-confirm');

// FUNCTIONS
const toUnsubscribeRequest = () => {
  return Api.delete('/api/subscribe');
};

const navigateToList = () => {
  window.location.reload();
};

// STREAMS
const unsubscribeUpdates = unsubscribeAction
  .map(always({
    title: 'Cancel Subscription',
    text: 'Are you sure you want to cancel your subscription?',
    submitAction: 'unsubscribe-confirm',
    submitText: 'Unsubscribe',
  }));

const unsubscribeRequestUpdates = unsubscribeConfirmAction
  .flatMap(toUnsubscribeRequest);

const unsubscribeSuccessUpdates = unsubscribeRequestUpdates
  .skipErrors();

const deleteFailedUpdates = unsubscribeRequestUpdates
  .errors()
  .mapError(always({
    message: 'Failed to cancel subscription',
    type: 'error',
  }));

// SUBSCRIBERS
const deleteModalUnsubscribe = unsubscribeUpdates.onValue(alertStore.open);
const closeModalUnsubscribe = unsubscribeSuccessUpdates
  .merge(deleteFailedUpdates)
  .onValue(alertStore.close);
const navigateToListUnsubscribe = unsubscribeSuccessUpdates.onValue(navigateToList);
const notificationUnsubscribe = deleteFailedUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.log('unloading profile-unsubscribe-store');
      deleteModalUnsubscribe();
      closeModalUnsubscribe();
      navigateToListUnsubscribe();
      notificationUnsubscribe();
    });
  }
}
