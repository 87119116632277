import { map } from 'rambda';

import WithState from '@/engine/decorators/with-state';

import WorkItemRenoCosts from './item/work-item-reno-costs';
import WorkItemMoney from './item/work-item-money';
import WorkItemSalePrice from './item/work-item-sale-price';
import WorkItemAgentFees from './item/work-item-agent-fees';

const WorkItemList = ({ items }) => {
  if (!items) {
    return null;
  }

  let salePrice = 0;

  const renderWorkItem = (item) => {
    switch (item.itemTypeName) {
      case 'renoCosts':
        return <WorkItemRenoCosts key={item.id} item={item} />;
      case 'salePrice':
        salePrice = item.value;
        return <WorkItemSalePrice key={item.id} item={item} />;
      case 'agentFees':
        return <WorkItemAgentFees key={item.id} salePrice={salePrice} item={item} />;
      default:
        return <WorkItemMoney key={item.id} item={item} />;
    }
  };

  return map(renderWorkItem, items);
};

export default WithState(WorkItemList);
