import { combineTemplate } from 'baconjs';
import { filter, prop, propEq } from 'rambda';

import stateStore from '@/engine/stores/state-store';
import projectPhotoDataStore from '@/data/projects/project-photo-data-store';
import projectDataStore from '../data/project.data.store';

const toPhotoList = ({ projectId, photos }) => {
  return {
    photos: filter(propEq('projectId', projectId), photos),
  };
};

const selectedPhotoUpdates = combineTemplate({
  photos: projectPhotoDataStore.updates.map(prop('items')),
  projectId: projectDataStore.selectUpdates.map(prop('id')),
})
  .map(toPhotoList);

const statePublishUnsubscribe = selectedPhotoUpdates.onValue(stateStore.Publish('project', 'project-photos'));

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('Unloading project photo state store');
      statePublishUnsubscribe();
    });
  }
}
