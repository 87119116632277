import { always, prop, propEq } from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

import templatesDataStore from '../data/templates.data.store';

const loadTemplatesListAction = actionDispatcher.subscribe('template-admin-list');

const toLoadTemplateRequest = () => (
  Api.get('/api/admin/templates')
);

const loadTemplateListRequestUpdates = loadTemplatesListAction.doLog('load tpl')
  .filter(propEq('event', 'load'))
  .flatMapLatest(toLoadTemplateRequest);

const loadTemplateListErrors = loadTemplateListRequestUpdates
  .errors()
  .mapError(always({ message: 'Failed to load templates', type: 'error' }));

const templateDataUpdates = loadTemplateListRequestUpdates
  .skipErrors()
  .map(prop('data'));

loadTemplateListErrors.onValue(notificationStore.publish);
templateDataUpdates.onValue(templatesDataStore.load);
