import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { isEmpty } from 'rambda';

import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  Row,
} from 'reactstrap';

import WithActions from '@/engine/decorators/with-actions';
import WithState from '@/engine/decorators/with-state';
import WithUser from '@/engine/decorators/with-user';

import BudgetProgress from '@/components/budget-progress';
import Button from '@/components/button';
import Input from '@/components/input';
import Loading from '@/components/loading';
import Icon from '@/components/icon';
import PropertyLink from '@/components/property-link';

import { STATUS_COMPLETED } from '@/shared/constants';
import { VIEW_BUDGET, VIEW_GALLERY } from '@/modules/properties/constants';
import ProjectBudget from './project-budget';
import ProjectPhotos from '../photos/project-photos';
import ProjectBudgetHeading from './project-budget-heading';

const ProjectDetails = ({
  project,
  hasItems,
  publish,
  user,
  view = VIEW_BUDGET,
}) => {
  const router = useRouter();
  const { id } = router.query;
  useEffect(() => {
    if (id) {
      publish({ event: 'load', id });
    }
  }, [id]);

  if (!project || isEmpty(project)) {
    return <Loading />;
  }

  const openBudget = (e) => {
    e.preventDefault();
    publish({ event: 'view', view: VIEW_BUDGET });
  };

  const openGallery = (e) => {
    e.preventDefault();
    publish({ event: 'view', view: VIEW_GALLERY });
  };

  return (
    <span>
      <div className="back-button-top-container">
        <Button
          id="navigate-property"
          actionMetaData={{ propertyId: project?.propertyId }}
          swatch
          color="secondary"
          className="back-button-top"
          size="sm"
        >
          Back
        </Button>
      </div>
      <h1 className="swatch-title swatch-title--main swatch-title--main-small">
        <small>View + Edit</small>
        Budget
      </h1>
      <Card className="card-raised mw-70 m-auto">
        <CardBody>
          <Row className="row-nowrap">
            <Col xs="auto">
              <BudgetProgress
                id={`project-budget-totals-${project.id}`}
                className="project-detail-progress"
                completed={project.status === STATUS_COMPLETED}
                withBG
                showTotal
              />
            </Col>
            <Col xs="auto" className="col-fill">
              <PropertyLink property={project.property} />
              <div className="mt-2">
                <h4>
                  <Input
                    id="project-name"
                    value={project.name}
                    placeholder="Enter Category Name"
                  />
                </h4>

                <Nav className="property-detail-nav">
                  {hasItems > 0
                    && user.roles?.admin && (
                      <NavItem>
                        <Button
                          id="project-save-as-template"
                          size="xs"
                          actionMetaData={{ projectId: project.id }}
                        >
                          <Icon icon="filter_none" className="mr-2" />
                          Use as Template
                        </Button>
                      </NavItem>
                  )}
                  <NavItem className="ml-auto">
                    <Button
                      id="project-remove"
                      actionMetaData={{ projectId: project.id }}
                      icon
                      title="Delete this category and all of it's items"
                      className="text-danger"
                    >
                      <Icon icon="delete" />
                      Delete Category
                    </Button>
                  </NavItem>
                </Nav>
              </div>
            </Col>
          </Row>
          <div className="mt-2">
            <Row>
              <ProjectBudgetHeading id={`project-budget-totals-${project.id}`} />
            </Row>
          </div>
        </CardBody>

        <Nav tabs>
          <NavItem active={view === VIEW_BUDGET}>
            <span
              onClick={openBudget}
              className="btn as-link"
              title="View budget items in this category"
            >
              <Icon icon="toc" size="md" />
              Budget Items
            </span>
          </NavItem>
          <NavItem active={view === VIEW_GALLERY}>
            <span
              onClick={openGallery}
              className="btn as-link"
              title="View images of this category"
            >
              <Icon icon="photo_camera" size="md" />
              Photos
            </span>
          </NavItem>
        </Nav>
        <CardBody>
          {view === VIEW_BUDGET && (
            <ProjectBudget id="project-budget" project={project} />
          )}

          {view === VIEW_GALLERY && (
            <ProjectPhotos projectId={project.id} />
          )}

          <Nav className="justify-content-center align-items-center nav-space-items">
            <NavItem>
              <Button
                id="navigate-property"
                actionMetaData={{ propertyId: project?.propertyId }}
                swatch
                color="secondary"
              >
                Back
              </Button>
            </NavItem>
          </Nav>
        </CardBody>
      </Card>

    </span>
  );
};

export default WithActions(WithState(WithUser(ProjectDetails)));
