import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';

import BudgetProgress from '@/components/budget-progress';
import Link from '@/components/link';

import { STATUS_COMPLETED } from '../../../../shared/constants';
import PropertiesUtils from '../../utils/properties-util';
import WithState from '@/engine/decorators/with-state';
import { FormatMoney } from '@/engine/utils/number';

const PropertyBudgetTile = ({ project, spent, budget }) => {
  const projectBudget = PropertiesUtils.getProjectBudget(project);

  return (
    <Card className="project card-raised h-100">
      <Link id="project-navigate" actionMetaData={{ projectId: project.id }}>
        {!project.imageSrc && (
          <div className="project-preview-image project-preview-image-default" />
        )}
        {project.imageSrc && (
          <div
            className="project-preview-image"
            style={{ backgroundImage: `url(${project.imageSrc})` }}
          />
        )}
      </Link>

      <CardBody className="project-preview-details p-3">
        <BudgetProgress
          id={`project-budget-totals-${project.id}`}
          completed={project.status === STATUS_COMPLETED}
          className="project-progress"
          withBg
        />

        <CardTitle className="project-title mb-0">
          <Link
            id="project-navigate"
            actionMetaData={{ projectId: project.id }}
            href={`/project/${project.id}`}
          >
            {project.name}
          </Link>
        </CardTitle>
        <Row className="justify-content-center mt-2 mt-sm-3">
          <Col xs="auto" className="px-1 px-sm-2">
            <h5 className="mb-0 d-none d-sm-block">
              {FormatMoney(spent ?? '0')}
            </h5>
            <h6 className="mb-0 d-sm-none">
              {FormatMoney(spent ?? '0')}
            </h6>
            <p className="mb-0">
              <small>Spent</small>
            </p>
          </Col>
          <Col xs="auto" className="px-1 px-sm-2">
            <h5 className="mb-0 d-none d-sm-block">
              {FormatMoney((budget ?? 0) - (spent ?? 0))}
            </h5>
            <h6 className="mb-0 d-sm-none">
              {FormatMoney((budget ?? 0) - (spent ?? 0))}
            </h6>
            <p className="mb-0">
              <small>Left</small>
            </p>
          </Col>
        </Row>
        <Link
          id="project-navigate"
          actionMetaData={{ projectId: project.id }}
          className="d-none d-sm-block"
          href={`/project/${project.id}`}
        >
          View Details
        </Link>
      </CardBody>
    </Card>
  );
};

export default WithState(PropertyBudgetTile);
