import {
  always,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import alertStore from '@/engine/stores/alert-store';
import { MutateUser } from '@/engine/utils/use-user';

import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

const impAction = actionDispatcher.subscribe('admin-user-imp');
const impConfirmAction = actionDispatcher.subscribe('admin-user-imp-confirm');
const impStopAction = actionDispatcher.subscribe('imp-stop');

const toImpRequest = ({ userId }) => {
  return Api.post(`/api/admin/users/${userId}/imp`);
};

const toImpAlert = ({ userId, email }) => {
  return {
    title: 'Impersonate User',
    text: `Are you sure you want to impersonate the user with email ${email}?`,
    submitAction: 'admin-user-imp-confirm',
    submitText: 'Impersonate',
    actionMetaData: { userId },
  };
};

const toImpStop = () => {
  return Api.delete('/api/admin/users/imp');
};

const updateUser = (user) => {
  MutateUser(user);
};

const navigateHome = () => {
  window.location.href = '/properties';
};

const impUpdates = impAction
  .map(toImpAlert);

const impRequestUpdates = impConfirmAction.flatMap(toImpRequest);
const impSuccessUpdates = impRequestUpdates
  .skipErrors();

const impFailedUpdates = impRequestUpdates
  .errors()
  .mapError(always({
    message: 'Failed to impersonate user',
    type: 'error',
  }));

const deleteModalUnsubscribe = impUpdates.onValue(alertStore.open);
const closeModalUnsubscribe = impSuccessUpdates
  .merge(impFailedUpdates)
  .onValue(alertStore.close);

const impStopUpdates = impStopAction.flatMap(toImpStop);

const notificationUnsubscribe = impFailedUpdates.onValue(notificationStore.publish);
const navigateUpdatesUnsubscribe = impStopUpdates
  .merge(impSuccessUpdates)
  .map(updateUser)
  .onValue(navigateHome);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      deleteModalUnsubscribe();
      closeModalUnsubscribe();
      notificationUnsubscribe();
      navigateUpdatesUnsubscribe();
    });
  }
}
