import {
  always,
  eqProps,
  omit,
  propEq,
} from 'rambda';

import Api from '@/engine/utils/api';
import actionDispatcher from '@/engine/actions/action-dispatcher';

import propertyDataStore from '../data/property.data.store';
import workTrackerItemDataStore from '@/data/work-tracker/work-tracker-item-data-store';

import { LoadProjects } from '../../project/stores/project-load-store';
import { ShareAddedUpdates } from './properties.collaborator.add.store';
import notificationStore from '@/engine/stores/notification-store';
import { ShareremovedUpdates } from './properties.collaborator.remove.store';

const loadAction = actionDispatcher.subscribe('property').filter(propEq('event', 'load'));

const loadPropertyFromApi = ({ id }) => Api.get(`/api/properties/${id}`);

const loadDataStore = ({ data }) => {
  propertyDataStore.load([]);
  propertyDataStore.insert(omit(['projects', 'workTrackerItems'], data));
  workTrackerItemDataStore.load(data.workTrackerItems);
  LoadProjects(data.projects);
};

const selectAndClear = ({ id }) => {
  propertyDataStore.clear();
  propertyDataStore.select(id);
};

// STREAMS
const loadUpdates = loadAction.skipDuplicates(eqProps('id'));

const loadFromApiRequest = loadUpdates
  .merge(ShareAddedUpdates.map(({ data }) => ({ id: data?.propertyId })))
  .merge(ShareremovedUpdates.map(({ data }) => ({ id: data?.propertyId })))
  .flatMap(loadPropertyFromApi);

// SUBSCRIBERS
loadUpdates.onValue(selectAndClear);
loadFromApiRequest.errors()
  .mapError(always({ type: 'error', message: 'Failed to load property' })).onValue(notificationStore.publish);
loadFromApiRequest.onValue(loadDataStore);
