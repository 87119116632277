import { merge } from 'rambda';

const clear = (prev) => {
  return merge(prev, {
    items: [],
    operation: 'clear',
    keyIndex: {},
    loaded: false,
    selectedId: false,
  });
};

export default clear;
