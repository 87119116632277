import { useState } from 'react';
import { WidgetInput } from 'react-addressfinder';

import WithActions from '@/engine/decorators/with-actions';

const addressFinderKey = process.env.NEXT_PUBLIC_ADDRESS_FINDER_KEY;

const AddressFinder = ({
  id,
  fieldClassName,
  value,
  publish,
}) => {
  const [addressValue, setAddress] = useState(value);

  return (
    <WidgetInput
      id={id}
      addressFinderKey={addressFinderKey}
      inputClassName={fieldClassName}
      onSelected={(fullAddress, address) => {
        setAddress(fullAddress);
        publish({ event: 'selected', fullAddress, address });
      }}
      onChange={(e) => {
        setAddress(e.target.value);
      }}
      country="NZ"
      value={addressValue}
    />
  );
};

export default WithActions(AddressFinder);
