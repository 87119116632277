import AppLayout from '@/layouts/app';

import Property from '../components/property';

const pageProps = {
  id: 'property',
};

const PropertyPage = () => {
  return (
    <AppLayout Component={Property} componentProps={pageProps} />
  );
};

export default PropertyPage;
