import {
  findIndex
,
  merge,
  pick,
  propEq,
} from 'rambda';

import DataStore from '@/engine/stores/data-store';
import replaceItem from '@/engine/funcs/replace-item';

const updateProject = (prev, next) => {
  const itemId = next.update.propertyId;

  if (!itemId || !(prev.keyIndex[itemId] || prev.keyIndex[itemId] === 0)) {
    return prev;
  }

  const property = prev.items[prev.keyIndex[itemId]];
  const projectIndex = findIndex(propEq('id', next.update.id), property.projects);

  if (projectIndex < 0) {
    return prev;
  }

  const update = pick(['name', 'notes', 'scopeOfWork'], next.update);
  const updatedProject = merge(property.projects[projectIndex], update);
  const projects = replaceItem(property.projects, projectIndex, updatedProject);

  const updated = merge(property, { projects });
  const newItems = replaceItem(prev.items, prev.keyIndex[itemId], updated);

  return merge(prev, {
    items: newItems,
    operation: 'update',
    updated: [updated],
  });
};

class PropertyDataStore extends DataStore {
  constructor() {
    super({
      operations: {
        updateProject,
      },
    });

    this.updateProject = this.updateProject.bind(this);
  }

  updateProject(update) {
    this.stream.push({ operation: 'updateProject', update });
  }
}

export default new PropertyDataStore();
