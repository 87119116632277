import { always } from 'rambda';
import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import stateStore from '@/engine/stores/state-store';
import Api from '@/engine/utils/api';
import { MutateUser } from '@/engine/utils/use-user';

const loadUpdates = actionDispatcher.subscribe('activate-account-page');

const toActivateAccountRequest = ({ token, id }) => {
  return Api.post('/api/account/activate', { token, id });
};

const navigateHome = () => {
  Router.push('/');
};

const updateLoginState = (data) => {
  MutateUser(data);
};

const activateUpdates = loadUpdates.flatMap(toActivateAccountRequest);

const activateSuccessUpdates = activateUpdates
  .skipErrors()
  .map(updateLoginState);

const loadStateUpdates = activateUpdates
  .errors()
  .mapError(always({ loaded: true, message: 'Could not activate account.' }));

const stateUnsub = loadStateUpdates.onValue(stateStore.Publish('auth', 'activate-account-page'));
const unsubNavigate = activateSuccessUpdates.onValue(navigateHome);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.log('unloading activate-account-store');
      unsubNavigate();
      stateUnsub();
    });
  }
}
