import { forEach, prop, propEq } from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import stateStore from '@/engine/stores/state-store';
import Api from '@/engine/utils/api';

import workTrackerAdminItemGroupByCategoryStore from '@/data/work-tracker/work-tracker-admin-item-group-by-category-store';
import workTrackerAdminItemDataStore from '@/data/work-tracker/work-tracker-admin-item-data-store';

const adminLoadAction = actionDispatcher.subscribe('work-tracker-admin-categories').filter(propEq('event', 'load'));

const publishState = ({ items }) => {
  forEach((category) => {
    stateStore.Publish('work-tracker-admin', `work-item-admin-list-${category.id}`, { items: category.items });
  }, items);
};

const toLoadRequest = () => {
  return Api.get('/api/admin/work-tracker/items');
};

const adminLoadUpdates = adminLoadAction
  .filter(propEq('event', 'load'))
  .flatMap(toLoadRequest);

const adminLoadSuccessUpdates = adminLoadUpdates
  .skipErrors()
  .map(prop('data'));

const listStateUpdates = workTrackerAdminItemGroupByCategoryStore
  .updates;

const listStateUnsubscribe = listStateUpdates.onValue(publishState);
const adminLoadUnsubscribe = adminLoadSuccessUpdates.onValue(workTrackerAdminItemDataStore.load);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading work-admin-item-state-store');
      listStateUnsubscribe();
      adminLoadUnsubscribe();
    });
  }
}
