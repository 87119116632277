import { v4 as uuidv4 } from 'uuid';
import short from 'short-uuid';

const translator = short();

const fromUUID = (value) => {
  return translator.fromUUID(value);
};

const toUUID = (value) => {
  return translator.toUUID(value);
};

const newUUID = () => {
  return uuidv4();
};

export default {
  fromUUID,
  toUUID,
  newUUID,
};
