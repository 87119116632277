import Button from '@/components/button';

const UserDetailsFree = ({ user }) => {
  const actionMetaData = { userId: user.id, email: user.email };

  return (
    <div className="mt-4 mb-4">
      <div className="alert alert-success">This user is using a free account</div>
      <Button
        id="admin-user-set-paid"
        color="primary"
        actionMetaData={actionMetaData}
      >
        Convert to trial
      </Button>
    </div>
  );
};

export default UserDetailsFree;
