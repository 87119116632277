import AppLayout from '@/layouts/app';

import Profile from '../components/profile/profile';

const pageProps = {
  id: 'profile-page',
};

const ProfilePage = () => {
  return (
    <AppLayout
      Component={Profile}
      componentProps={pageProps}
    />
  );
};

export default ProfilePage;
