import {
  always,
  prop,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

// ACTIONS
const subscribeAction = actionDispatcher.subscribe('subscribe');

// functions
const toSubscribeRequest = ({ annual }) => {
  return Api.post(`/api/subscribe?annual=${annual}`);
};

const navigate = ({ subscribeUrl }) => {
  window.top.location = subscribeUrl;
};

// streams
const subscribeRequests = subscribeAction
  .flatMapLatest(toSubscribeRequest);

const subscribeRequestFailed = subscribeRequests
  .errors()
  .mapError(always({ message: 'Failed to subscribe', type: 'error' }));

const subscribeUnsub = subscribeRequests
  .skipErrors()
  .map(prop('data'))
  .onValue(navigate);

const subscribeRequestFailedUnSub = subscribeRequestFailed.onValue(notificationStore.publish);

// SUBSCRIBERS

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading profile-subscribe-store');
      subscribeRequestFailedUnSub();
      subscribeUnsub();
    });
  }
}
