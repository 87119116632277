import WorkItemMoney from './work-item-money';

const WorkItemSalePrice = ({ item }) => {
  return (
    <>
      <WorkItemMoney item={item} />
      <tr className="budget-item-row budget-item-row--default">
        <td colSpan={3} className="budget-item-row__tradeDescription budget-item-bit__mob-show-for-expanded2 ">
          <div>
            <b>Less:</b>
          </div>
        </td>
      </tr>
    </>
  );
};

export default WorkItemSalePrice;
