import { add } from 'date-fns';
import Button from '@/components/button';
import { formatDate } from '@/engine/utils/date';

const UserDetailsTrial = ({ user }) => {
  const actionMetaData = { userId: user.id, email: user.email };

  const trialStart = Date.parse(user.trialStart);
  const trialExpires = add(trialStart, { days: process.env.TRIAL_DAYS || 3 });

  return (
    <div className="mt-4 mb-4">
      {trialExpires > new Date() ? (
        <div className="alert alert-success">This user is in an active trial</div>
      ) : (
        <div className="alert alert-warning">Trial Expired</div>
      )}
      <span className="d-block mr-4 mb-2">
        <strong className="mr-2">Trial Expiry:</strong>
        {formatDate(trialExpires)}
      </span>
      <Button
        id="admin-user-set-free"
        color="primary"
        actionMetaData={actionMetaData}
      >
        Convert to free
      </Button>
    </div>
  );
};

export default UserDetailsTrial;
