import {
  always,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import alertStore from '@/engine/stores/alert-store';

import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';
import adminUsersDataStore from '@/data/users/admin-users-data-store';

const convertAction = actionDispatcher.subscribe('admin-user-set-paid');
const convertConfirmAction = actionDispatcher.subscribe('admin-user-set-paid-confirm');

const toConvertToPaidRequest = ({ userId }) => {
  return Api.post(`/api/admin/users/${userId}/convert-to-paid`);
};

const toConvertAlert = ({ userId, email }) => {
  return {
    title: 'Convert User',
    text: `Are you sure you want to convert ${email} to a paid account?`,
    submitAction: 'admin-user-set-paid-confirm',
    submitText: 'Convert',
    actionMetaData: { userId },
  };
};

const toUserDsUpdate = ({ data }) => {
  return data;
};

const convertUpdates = convertAction
  .map(toConvertAlert);

const convertRequestUpdates = convertConfirmAction.flatMap(toConvertToPaidRequest);
const convertSuccessUpdates = convertRequestUpdates
  .skipErrors();

const convertFailedUpdates = convertRequestUpdates
  .errors()
  .mapError(always({
    message: 'Failed to convert user',
    type: 'error',
  }));

const userUpdates = convertSuccessUpdates
  .doLog('response')
  .map(toUserDsUpdate);

const convertModalUnsubscribe = convertUpdates.onValue(alertStore.open);
const closeModalUnsubscribe = convertSuccessUpdates
  .merge(convertFailedUpdates)
  .onValue(alertStore.close);

const notificationUnsubscribe = convertFailedUpdates.onValue(notificationStore.publish);

const dsUpdateUnsubscribe = userUpdates.doLog('dsupdate').onValue(adminUsersDataStore.update);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      convertModalUnsubscribe();
      closeModalUnsubscribe();
      notificationUnsubscribe();
      dsUpdateUnsubscribe();
    });
  }
}
