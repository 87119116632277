import { useState } from 'react';
import classNames from 'classnames';
import Textarea from 'react-textarea-autosize';

import WithActions from '@/engine/decorators/with-actions';

const TextArea = ({
  wrapperClassName,
  className,
  label,
  id,
  name,
  help,
  autosize,
  hasMinHeight,
  publish,
  value,
}) => {
  const [internalValue, setInternalValue] = useState(value);

  const htmlId = id || name;

  const onChange = (e) => {
    setInternalValue(e.target.value);
    publish({ event: 'change', value: e.target.value });
  };

  const onBlur = (e) => {
    setInternalValue(e.target.value);
    publish({ event: 'blur', value: e.target.value });
  };

  return (
    <div className={wrapperClassName}>
      {label && <label htmlFor={htmlId}>{label}</label>}

      {autosize && (
        <Textarea
          id={htmlId}
          className={classNames(
            className,
            'form-control',
            'textarea-autosize',
            { 'textarea-autosize--has-min-height': hasMinHeight },
          )}
          onChange={onChange}
          onBlur={onBlur}
          value={internalValue}
        />
      )}

      {!autosize && (
        <textarea
          id={htmlId}
          className={classNames('form-control', className)}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      {help && help}
    </div>
  );
};

export default WithActions(TextArea);
