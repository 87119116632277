import { always, prop, propEq } from 'rambda';

import notificationStore from '@/engine/stores/notification-store';
import WorkTrackerCategoryDataStore from '@/data/work-tracker/work-tracker-category-data-store';
import Api from '@/engine/utils/api';
import actionDispatcher from '@/engine/actions/action-dispatcher';

const loadAction = actionDispatcher.subscribe('property').filter(propEq('event', 'load'));
const adminLoadAction = actionDispatcher.subscribe('work-tracker-admin-categories').filter(propEq('event', 'load'));

const loadCategories = () => {
  return Api.get('/api/work-tracker/categories');
};

const loadUpdates = loadAction
  .merge(adminLoadAction)
  .take(1)
  .flatMap(loadCategories);

const loadSuccessUpdates = loadUpdates
  .skipErrors()
  .map(prop('data'));

const loadFailedUpdates = loadUpdates
  .errors()
  .mapError(always({ message: 'Failed to load work tracker categories', type: 'error' }));

const failUnsubscribe = loadFailedUpdates.onValue(notificationStore.publish);
const loadUnsubscribe = loadSuccessUpdates.onValue(WorkTrackerCategoryDataStore.load);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading category-load-store');
      loadUnsubscribe();
      failUnsubscribe();
    });
  }
}
