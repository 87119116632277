import { curryN } from 'rambda';

const addIndex = (func) => {
  return curryN(func.length, (...args) => {
    let idx = 0;
    const myArgs = args.slice();
    const origFn = myArgs[0];
    const list = myArgs[args.length - 1];
    myArgs[0] = (...funcArgs) => {
      const result = origFn(...funcArgs, idx, list);
      idx += 1;
      return result;
    };

    return func(...myArgs);
  });
};

export default addIndex;
