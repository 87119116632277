import { useState, useEffect } from 'react';

import StateStore from '../stores/state-store';

const WithState = (Component, { stateKey } = {}) => {
  const ComponentWithState = (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    const subscriptionKey = props.stateKey || props.id || stateKey;

    const [state, setState] = useState({});

    useEffect(() => {
      if (subscriptionKey) {
        const unsubscribe = StateStore.Subscribe('common', subscriptionKey).onValue((stateUpdate) => setState(stateUpdate));
        return () => unsubscribe();
      }

      return () => {};
    }, [subscriptionKey]);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component {...props} {...state} />
    );
  };

  return ComponentWithState;
};

export default WithState;
