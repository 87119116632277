import {
  always, objOf, prop,
} from 'rambda';

import AdminUsersDataStore from '@/data/users/admin-users-data-store';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import alertStore from '@/engine/stores/alert-store';

import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

const deleteAction = actionDispatcher.subscribe('admin-user-remove');
const deleteConfirmAction = actionDispatcher.subscribe('admin-user-remove-confirm');

const toDeleteRequest = ({ userId }) => {
  return Api.delete(`/api/admin/users/${userId}`);
};

const toDeleteAlert = ({ userId, email }) => {
  return {
    title: 'Delete User',
    text: `Are you sure you want to delete the user with email ${email}?`,
    submitAction: 'admin-user-remove-confirm',
    submitText: 'Delete',
    actionMetaData: { userId },
  };
};

const deleteUpdates = deleteAction
  .map(toDeleteAlert);

const deleteFromDataStoreUpdates = deleteConfirmAction
  .map(prop('userId'))
  .map(objOf('id'));

const deleteRequestUpdates = deleteConfirmAction.flatMap(toDeleteRequest);
const deleteSuccessUpdates = deleteRequestUpdates
  .skipErrors();

const deleteFailedUpdates = deleteRequestUpdates
  .errors()
  .mapError(always({
    message: 'Failed to remove user',
    type: 'error',
  }));

const deleteModalUnsubscribe = deleteUpdates.onValue(alertStore.open);
const closeModalUnsubscribe = deleteSuccessUpdates
  .merge(deleteFailedUpdates)
  .onValue(alertStore.close);

const deleteFromDataStoreUnsubscribe = deleteFromDataStoreUpdates
  .onValue(AdminUsersDataStore.remove);
const notificationUnsubscribe = deleteFailedUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      deleteModalUnsubscribe();
      closeModalUnsubscribe();
      deleteFromDataStoreUnsubscribe();
      notificationUnsubscribe();
    });
  }
}
