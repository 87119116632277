import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import Button from '../button';
import WithState from '@/engine/decorators/with-state';

const AlertModal = ({
  isVisible,
  title,
  text,
  cancelText = 'Cancel',
  cancelAction = 'cancelAction',
  submitText = 'Ok',
  submitAction = 'submitAction',
  className,
  actionMetaData,
}) => {
  return (
    <Modal isOpen={isVisible} className={className}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {text}
      </ModalBody>
      <ModalFooter>
        <Button
          id={submitAction}
          actionMetaData={actionMetaData}
          color="primary"
        >
          {submitText}
        </Button>
        <Button
          id={cancelAction}
          className="ml-2"
          color="secondary"
        >
          {cancelText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WithState(AlertModal);
