import {
  always, identity,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

const collaboratorAddAction = actionDispatcher.subscribe('property-collaborator-delete');

const toAddRequest = ({ propertyId, email }) => {
  return Api.delete(`/api/properties/${propertyId}/share/${email}`);
};

const removeRequestUpdates = collaboratorAddAction
  .doLog('collaborator-remove')
  .flatMap(toAddRequest);

const removeSuccessUpdates = removeRequestUpdates
  .skipErrors();

const removeFailedUpdates = removeRequestUpdates
  .errors()
  .mapError(always({
    message: 'Failed to unshare property',
    type: 'error',
  }));

const notificationUnsubscribe = removeFailedUpdates.onValue(notificationStore.publish);

export const ShareremovedUpdates = removeSuccessUpdates.map(identity);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      notificationUnsubscribe();
    });
  }
}
