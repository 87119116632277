import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import templateDataStore from '@/data/templates/template-data-store';
import adminTemplateItemDataStore from '@/data/templates/admin-template-item-data-store';

const navigateTemplateListAction = actionDispatcher.subscribe('navigate-admin-templates');
const navigateTemplateAction = actionDispatcher.subscribe('navigate-admin-template');

const navigateToList = () => {
  Router.push('/admin/templates');
};

const navigateToTemplate = ({ templateId }) => {
  templateDataStore.clear();
  adminTemplateItemDataStore.clear();
  Router.push(`/admin/templates/${templateId}`);
};

const navigateListUnsubscribe = navigateTemplateListAction.onValue(navigateToList);
const navigateTemplateUnsubscribe = navigateTemplateAction.onValue(navigateToTemplate);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.log('unloading template-navigation-store');
      navigateListUnsubscribe();
      navigateTemplateUnsubscribe();
    });
  }
}
