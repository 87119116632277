import { when as BaconWhen, mergeAll as BaconMerge } from 'baconjs';
import { identity, merge, prop } from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import stateStore from '@/engine/stores/state-store';
import Api from '@/engine/utils/api';

const emailUpdates = actionDispatcher.subscribe('forgot.password.form.email');
const submitAction = actionDispatcher.subscribe('forgot.password.form.submit');

const toForgotPasswordUpdate = (email) => ({
  email: email.value,
  valid: email.valid,
});

const toForgotPasswordRequest = (data) => Api.post('/api/login/forgot-password', data);

const forgotPasswordUpdates = BaconWhen(
  [emailUpdates.toProperty(), submitAction],
  toForgotPasswordUpdate,
)
  .filter(prop('valid'));

const forgotPasswordRequestUpdates = forgotPasswordUpdates
  .flatMap(toForgotPasswordRequest);

const forgotPasswordErrors = forgotPasswordRequestUpdates
  .errors()
  .mapError(identity);

const forgotPasswordSuccess = forgotPasswordRequestUpdates
  .skipErrors();

const stateUpdates = BaconMerge(
  forgotPasswordUpdates.map(identity({ send: true })),
  forgotPasswordSuccess.map(identity({ success: true })),
  forgotPasswordErrors.map(identity({ error: true })),
)
  .scan({ send: false }, merge);

stateUpdates.onValue(stateStore.Publish('auth', 'forgotPasswordPage'));
