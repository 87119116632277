import { useEffect } from 'react';

// import all the css here
import 'react-circular-progressbar/dist/styles.css';
import '../styles/app.scss';

import '@/modules/auth';
import '@/modules/profile';
import '@/modules/project';
import '@/modules/properties';
import '@/modules/templates';
import '@/modules/users';
import '@/modules/work-tracker';

import '@/engine/stores/route-store';
import '@/engine/stores/alert-store';

const MyApp = ({ Component, pageProps }) => {
  useEffect(() => {
    document.body.className = pageProps.bodyClassName;
  }, [pageProps.bodyClassName]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...pageProps} />;
};

export default MyApp;
