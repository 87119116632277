import {
  always,
  prop,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

import adminTemplateItemDataStore from '@/data/templates/admin-template-item-data-store';

const addAction = actionDispatcher.subscribe('template-budget-add');

const toAddRequest = ({ templateId, status }) => {
  return Api.post(`/api/admin/templates/${templateId}/items`, { status });
};

const addRequestUpdates = addAction
  .flatMap(toAddRequest);

const addRequestFailedUpdates = addRequestUpdates
  .errors()
  .mapError(always({ message: 'failed to add template item', type: 'error' }));

const addRequestSuccessUpdates = addRequestUpdates
  .map(prop('data'));

const failedUnsubscribe = addRequestFailedUpdates.onValue(notificationStore.publish);
const addUnsubscribe = addRequestSuccessUpdates.onValue(adminTemplateItemDataStore.insert);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      failedUnsubscribe();
      addUnsubscribe();
    });
  }
}
