import { Bus as BaconBus } from 'baconjs';

import clear from './data-operations/clear';
import insert from './data-operations/insert';
import load from './data-operations/load';
import select from './data-operations/select';
import update from './data-operations/update';
import remove from './data-operations/remove';

const defaultOperations = {
  insert,
  update,
  load,
  clear,
  select,
  remove,
};

const getSelected = (updates) => {
  if (!updates.selectedId) {
    return {};
  }

  const itemIndex = updates.keyIndex[updates.selectedId];
  if (!itemIndex && itemIndex !== 0) {
    return {};
  }

  return updates.items[itemIndex];
};

class DataStore {
  constructor({ operations } = {}) {
    this.stream = new BaconBus();
    this.operations = operations || {};

    this.insert = this.insert.bind(this);
    this.update = this.update.bind(this);
    this.remove = this.remove.bind(this);
    this.clear = this.clear.bind(this);
    this.load = this.load.bind(this);
    this.select = this.select.bind(this);

    this.updates = this.stream.scan(
      {
        loaded: false,
        items: [],
        keyIndex: {},
        selectedId: false,
      },
      this.scanUpdates.bind(this),
    );

    this.selectUpdates = this.updates.map(getSelected);
  }

  clear() {
    this.stream.push({ operation: 'clear' });
  }

  remove(item) {
    this.stream.push({ operation: 'remove', item });
  }

  insert(item) {
    this.stream.push({ operation: 'insert', item });
  }

  load(items) {
    this.stream.push({ operation: 'load', items });
  }

  select(id) {
    this.stream.push({ operation: 'select', id });
  }

  update(item) {
    this.stream.push({ operation: 'update', item });
  }

  scanUpdates(prev, next) {
    const { operation } = next;
    const operationFunc = this.operations[operation] || defaultOperations[operation];

    return operationFunc ? operationFunc(prev, next) : prev;
  }
}

export default DataStore;
