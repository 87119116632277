import {
  Card,
  CardBody,
  Label,
  Nav,
  NavItem,
} from 'reactstrap';

import { useEffect } from 'react';

import WithState from '@/engine/decorators/with-state';
import WithActions from '@/engine/decorators/with-actions';

import AddressFinder from '@/components/address-finder';
import Button from '@/components/button';
import Form from '@/components/form';
import FormGroup from '@/components/form-group';
import ImageField from '@/components/image-field';

const AddProperty = ({ imageUrl, send, publish }) => {
  useEffect(() => {
    publish({ event: 'loaded' });
  }, []);

  return (
    <div className="property">
      <div className="back-button-top-container">
        <Button
          id="property-back"
          swatch
          color="secondary"
          className="back-button-top"
          size="sm"
        >
          Back
        </Button>
      </div>
      <h1 className="swatch-title swatch-title--main swatch-title--main">
        New Reno
      </h1>
      <Card className="mw-30 m-auto card-raised">
        <CardBody>
          <Form>
            <FormGroup>
              <Label for="name">Property Address</Label>
              <AddressFinder
                id="property-add-address"
                fieldClassName="form-control af-hidden-autofill-icon"
                value=""
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">Image</Label>
              <ImageField
                id="property-add-image"
                resize={{ width: 640, height: 480 }}
                fileUrl={imageUrl}
              />
            </FormGroup>
            <Nav className="justify-content-center align-items-center nav-space-items">
              <NavItem>
                <Button
                  id="property-add-save"
                  type="submit"
                  swatch
                  disabled={send}
                  color="primary"
                >
                  Next
                </Button>
              </NavItem>
            </Nav>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default WithActions(WithState(AddProperty));
