import { Bus } from 'baconjs';
import actionDispatcher from '../actions/action-dispatcher';
import stateStore from './state-store';

class AlertStore {
  constructor() {
    this.updates = new Bus();
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    actionDispatcher.subscribe('alert-modal-close').onValue(this.close);
    this.updates
      .toEventStream()
      .onValue(stateStore.Publish('common', 'alert-modal'));
  }

  open({
    title,
    text,
    cancelText = 'Cancel',
    cancelAction = 'alert-modal-close',
    submitText,
    submitAction,
    actionMetaData,
  }) {
    this.updates.push({
      isVisible: true,
      title,
      text,
      cancelText,
      cancelAction,
      submitText,
      submitAction,
      actionMetaData,
    });
  }

  close() {
    this.updates.push({ isVisible: false });
  }
}

export default new AlertStore();
