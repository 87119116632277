import { always, identity } from 'rambda';
import { when as BaconWhen } from 'baconjs';

import actionDispatcher from "@/engine/actions/action-dispatcher";"@/engine/actions/action-dispatcher";
import notificationStore from "@/engine/stores/notification-store";
import Api from '@/engine/utils/api';

import propertyDataStore from "../data/property.data.store";

const propertyEditNameAction = actionDispatcher.subscribe('property-edit-name');

const toPropertyUpdate = ({ id, name }, { value }) => {
    if (value === name) {
        return null;
    }
    return {
        id,
        name: value,
    };
};

const toPropertyUpdateRequest = ({ id, name }) => {
    return Api.put(`/api/properties/${id}`, { name });
}

const propertyEditNameUpdates = BaconWhen(
    [propertyDataStore.selectUpdates.toProperty(), propertyEditNameAction.debounce(1000)],
    toPropertyUpdate,
)
    .filter(identity);

const propertyEditNameRequestUpdates = propertyEditNameUpdates
    .flatMapLatest(toPropertyUpdateRequest);

const propertyEditNameFailedRequestUpdates = propertyEditNameRequestUpdates
    .errors()
    .mapError(always({ type: 'error', message: 'Failed to update name' }));

const dsUpdateUnsub = propertyEditNameUpdates.onValue(propertyDataStore.update);
const notifyUnsub = propertyEditNameFailedRequestUpdates
    .onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
      module.hot.accept();
      module.hot.dispose(() => {
        dsUpdateUnsub();
        notifyUnsub();
      });
    }
  }