import {
  always,
  merge,
  propEq,
} from 'rambda';

import {
  mergeAll as BaconMergeAll,
  when as BaconWhen,
} from 'baconjs';

import actionDispatcher from '@/engine/actions/action-dispatcher';

import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

import templateDataStore from '@/data/templates/template-data-store';

const nameUpdateAction = actionDispatcher.subscribe('admin-template-name');
const notesUpdateAction = actionDispatcher.subscribe('admin-template-notes');
const scopeUpdateAction = actionDispatcher.subscribe('admin-template-scope');
const formUpdateAction = actionDispatcher.subscribe('admin-template-edit-form');

const toNameChange = (template, { value }) => {
  return {
    data: {
      name: value,
    },
    valid: !!value,
    templateId: template.id,
    changed: template.name !== value,
  };
};

const toNotesChange = (template, { value }) => {
  return {
    data: {
      notes: value,
    },
    valid: true,
    templateId: template.id,
    changed: template.notes !== value,
  };
};

const toScopeChange = (template, { value }) => {
  return {
    data: {
      scopeOfWork: value,
    },
    valid: true,
    templateId: template.id,
    changed: template.scopeOfWork !== value,
  };
};

const toFormChange = (template, { status }) => {
  return {
    data: {
      status,
    },
    valid: !!status,
    templateId: template.id,
    changed: template.status !== status,
  };
};

const updateDataStore = ({ templateId, data }) => {
  templateDataStore.update(merge({ id: templateId }, data));
};

const toTemplateUpdateRequest = ({ templateId, data }) => {
  return Api.put(`/api/admin/templates/${templateId}`, data);
};

const nameBlurUpdates = nameUpdateAction.filter(propEq('event', 'blur'));
const notesBlurUpdates = notesUpdateAction.filter(propEq('event', 'blur'));
const scopeBlurUpdates = scopeUpdateAction.filter(propEq('event', 'blur'));

const nameChangeUpdates = BaconWhen(
  [templateDataStore.selectUpdates.toProperty(), nameBlurUpdates],
  toNameChange,
)
  .filter(propEq('changed', true));

const notesChangeUpdates = BaconWhen(
  [templateDataStore.selectUpdates.toProperty(), notesBlurUpdates.toEventStream()],
  toNotesChange,
)
  .filter(propEq('changed', true));

const scopeChangeUpdates = BaconWhen(
  [templateDataStore.selectUpdates.toProperty(), scopeBlurUpdates],
  toScopeChange,
)
  .filter(propEq('changed', true));

const formChangeUpdates = BaconWhen(
  [templateDataStore.selectUpdates.toProperty(), formUpdateAction],
  toFormChange,
)
  .filter(propEq('changed', true));

const templateChangeUpdates = BaconMergeAll(
  nameChangeUpdates,
  notesChangeUpdates,
  scopeChangeUpdates,
  formChangeUpdates,
);

const validTemplateChangeUpdates = templateChangeUpdates
  .filter(propEq('valid', true));

const templateUpdateRequests = validTemplateChangeUpdates
  .flatMap(toTemplateUpdateRequest);

const failedTemplateUpdateRequests = templateUpdateRequests
  .errors()
  .mapError(always({ type: 'error', message: 'Failed to update template' }));

const unsubDs = validTemplateChangeUpdates.onValue(updateDataStore);
const unsubNotifications = failedTemplateUpdateRequests.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.log('unloading admin-template-edit-store');
      unsubDs();
      unsubNotifications();
    });
  }
}
