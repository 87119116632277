import Router from 'next/router';
import actionDispatcher from '@/engine/actions/action-dispatcher';

// ACTIONS
const backAction = actionDispatcher.subscribe('profile-back');

// functions
const navigateRoot = () => {
  Router.push('/');
};

// streams

// SUBSCRIBERS
const backUnsub = backAction.onValue(navigateRoot);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading profile-update-store');
      backUnsub();
    });
  }
}
