import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';

const logoutActionUpdates = actionDispatcher.subscribe('logout');

const logout = () => Api.post('/api/login/logout');

const redirectToLogin = () => {
  window.location.href = '/auth/login';
};

const logoutRequestUpdates = logoutActionUpdates.flatMap(logout);

logoutRequestUpdates.onValue(redirectToLogin);
