/* eslint-disable react/jsx-props-no-spreading */
import { merge } from 'rambda';
import classnames from 'classnames';

import WithActions from '@/engine/decorators/with-actions';

const Link = ({
  children,
  disabled,
  publish,
  params,
  className,
  ...props
}) => {
  const onClick = (e) => {
    e.preventDefault();

    if (disabled) return;

    publish(merge(params, { event: 'clicked' }));
  };

  const linkClassName = classnames(
    'link',
    className,
  );

  return (
    <a
      className={linkClassName}
      onClick={onClick}
      {...props}
    >
      {children}
    </a>
  );
};

export default WithActions(Link);
