import {
  Col,
  Row,
} from 'reactstrap';
import { map } from 'rambda';

import Link from '@/components/link';
import Icon from '@/components/icon';
import ImageComparison from '@/components/image-comparison';
import WithState from '@/engine/decorators/with-state';

const renderEmpty = () => {
  return (
    <Col className="mt-4 text-center">
      <Icon icon="add_photo_alternate" size="xl" />
      <h5>Looks like you don&apos;t have any images of this Reno yet...</h5>
      <p>
        Add photos to your budget categories and they will also appear here,
        all together
      </p>
    </Col>
  );
};

const projectImagesGallery = (project) => {
  return project.photos.length ? (
    map((photo) => {
      const beforeImage = photo.beforeImageUrl;
      const afterImage = photo.afterImageUrl;

      return beforeImage || afterImage ? (
        <span key={photo.id}>
          {beforeImage && afterImage && (
            <ImageComparison leftImage={beforeImage} rightImage={afterImage} />
          )}
          {beforeImage && !afterImage && (
            <img src={beforeImage} alt="beforeImage" />
          )}
          {!beforeImage && afterImage && (
            <img src={afterImage} alt="afterImage" />
          )}
        </span>
      ) : null;
    }, project.photos)
  ) : (
    <p className="italic small mb-0">
      You haven&apos;t added any images to this budget category yet!
    </p>
  );
};

const renderProjects = (projects) => {
  return map((project) => {
    return (
      <Col sm="6" lg="4" key={`${project.id}`}>
        <h5>
          <span className="mr-2">{project.name}</span>
          <Link id="project-navigate" actionMetaData={{ projectId: project.id }}>
            <strong className="tiny allcaps d-inline-block">
              Add / Edit photos
            </strong>
          </Link>
        </h5>
        {projectImagesGallery(project)}
      </Col>
    );
  }, projects);
};

const PropertyGallery = ({ projects, hasPhotos }) => {
  return (
    <Row className="row-space-items">
      {!hasPhotos && renderEmpty()}
      {projects && renderProjects(projects)}
    </Row>
  );
};

export default WithState(PropertyGallery);
