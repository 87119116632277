import {
  Col,
  Row,
} from 'reactstrap';

import { FormatMoney } from '@/engine/utils/number';

import WithState from '@/engine/decorators/with-state';

const PropertyHeadingBudget = ({ spent, budget }) => {
  return (
    <Row>
      <Col xs="6" sm="auto" className="text-center pr-1 pr-sm-2">
        <h3 className="swatch-title swatch-title--pale">
          {FormatMoney(spent ?? 0)}
          <small className="tiny">Spent</small>
        </h3>
      </Col>
      <Col xs="6" sm="auto" className="text-center pr-1 pr-sm-2">
        <h3 className="swatch-title swatch-title--pale">
          {budget ? FormatMoney(budget - spent) : 0}
          <small className="tiny">Left</small>
        </h3>
      </Col>
    </Row>
  );
};

export default WithState(PropertyHeadingBudget);
