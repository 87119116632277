import AppLayout from '@/layouts/app';

import ProjectDetails from '../components/details/project-details';

const pageProps = {
  id: 'project-page',
};

const ProjectPage = () => {
  return (
    <AppLayout
      Component={ProjectDetails}
      componentProps={pageProps}
    />
  );
};

export default ProjectPage;
