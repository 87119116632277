import {
  always,
  prop,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import notificationStore from '@/engine/stores/notification-store';
import Api from '@/engine/utils/api';

import projectPhotoDataStore from '@/data/projects/project-photo-data-store';

const addAction = actionDispatcher.subscribe('project-photo-add');

const toAddRequest = ({ projectId }) => {
  return Api.post(`/api/projects/${projectId}/photos`);
};

const addRequestUpdates = addAction
  .flatMapLatest(toAddRequest);

const addRequestSuccessUpdates = addRequestUpdates
  .skipErrors()
  .map(prop('data'));

const addRequestFailedUpdates = addRequestUpdates
  .errors()
  .mapError(always({ message: 'failed to add photo', type: 'error' }));

const failedNotifyUnsubscribe = addRequestFailedUpdates.onValue(notificationStore.publish);
const addPhotoToDsUnsubscribe = addRequestSuccessUpdates.onValue(projectPhotoDataStore.insert);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('unloading project photo add store');
      failedNotifyUnsubscribe();
      addPhotoToDsUnsubscribe();
    });
  }
}
