import { merge, mergeDeepRight } from 'rambda';

import replaceItem from '@/engine/funcs/replace-item';

const update = (prev, next) => {
  const itemId = next.item.id;

  if (!itemId || !(prev.keyIndex[itemId] || prev.keyIndex[itemId] === 0)) {
    return prev;
  }

  const updatedItem = mergeDeepRight(prev.items[prev.keyIndex[itemId]], next.item);
  const newItems = replaceItem(prev.items, prev.keyIndex[itemId], updatedItem);

  return merge(prev, {
    items: newItems,
    operation: 'update',
    updated: [updatedItem],
  });
};

export default update;
