import Dropzone from 'react-dropzone';

import Plus from '@/images/white-plus.inline.svg';
import Swatch from '@/images/square-paint-swatch.inline.svg';

import WithActions from '@/engine/decorators/with-actions';
import { map, merge } from 'rambda';
const contentTypes = "image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, text/csv"
const DossierUploader = ({
  publish,
  propertyId,
  isLarge = true,
}) => {
  const onDropImage = async (files) => {
    if (files?.length) {
      publish({ files: map((file) => ({ file, propertyId }), files) });
    }
  };

  const renderLarge = () => (
    <div className="project-list-add btn-swatch btn-swatch-primary btn-swatch-lg btn btn-primary btn-md" style={{ paddingTop: '2em' }}>
      <Swatch className="btn__swatch" />
      <Plus className="btn-swatch__icon btn-swatch__icon--larger" />
      <br />
      <span>
        <small>Add file to</small>
        <br />
        Dossier
      </span>
    </div>
  );

  const renderSmall = () => (
    <>
    </>
  );

  const borderStyle = {
  };

  if (isLarge) {
    borderStyle.border = '0px';
  }

  return (
    <Dropzone
      className="form-control dropzone"
      accept={contentTypes}
      onDrop={onDropImage}
      style={borderStyle}
    >
      {isLarge ? renderLarge() : renderSmall()}
    </Dropzone>
  );
};

export default WithActions(DossierUploader);
