import { reduce, merge, assoc } from 'rambda';

import addIndex from '../../funcs/add-index';

// eslint-disable-next-line no-param-reassign
const reduceKeys = (indexObj, nextItem, index) => {
  return assoc(nextItem.id, index, indexObj);
};

const load = (prev, next) => {
  const keyIndex = addIndex(reduce)(reduceKeys, {}, next.items);

  return merge(prev, {
    items: [...next.items],
    operation: 'load',
    keyIndex,
    added: next.items,
    loaded: true,
  });
};

export default load;
