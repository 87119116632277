import {
  Badge,
} from 'reactstrap';

import { FormatMoney } from '@/engine/utils/number';

import Button from '../button';
import Plus from '../plus';

const SuggestionItem = ({
  inlineWithItems,
  suggestion = {},
}) => {
  return (
    <tr className="budget-item-row budget-item-row--suggested row-accentuated">
      <td
        colSpan={inlineWithItems ? '2' : undefined}
        className="budget-item-row__suggested-name"
      >
        {inlineWithItems && (
          <small className="mr-2">
            <Badge color="primary">SUGGESTION</Badge>
          </small>
        )}
        {suggestion.name}
      </td>
      <td className="text-right">{FormatMoney(suggestion.budget)}</td>
      <td className="text-right" colSpan={inlineWithItems ? '3' : undefined}>
        <Button
          id="project-budget-add-suggestion"
          color="as-link"
          size="sm"
          className="nowrap"
          title="Add Item"
          actionMetaData={{ suggestionId: suggestion.id }}
        >
          <Plus className="btn-swatch__icon mr-2" />
          <span className="d-none d-sm-inline">
            Add to budget
          </span>
        </Button>
      </td>
    </tr>
  );
};

export default SuggestionItem;
