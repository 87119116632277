import numeral from 'numeral';

const THOUSANDS_FORMAT = '$0,0';

export const FormatMoney = (value) => {
  let money = numeral(value).format(THOUSANDS_FORMAT);
  if (money === '$NaN') {
    money = numeral('0').format(THOUSANDS_FORMAT);
  }
  return money;
};

export const UnFormatMoney = (value) => {
  return typeof value === 'number'
    ? value
    : Number(value.replace(/[^0-9.-]/g, ''));
};
