import { propEq } from 'rambda';
import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';

const adminUserAction = actionDispatcher.subscribe('adminUser');
const adminUserBackAction = actionDispatcher.subscribe('admin-user-details-back');

const openUser = ({ userId }) => {
  Router.push(`/admin/users/${userId}`);
};

const openUserUpdates = adminUserAction.filter(propEq('event', 'openUser'));

const openUserUnsub = openUserUpdates.onValue(openUser);
const backUnSub = adminUserBackAction.onValue(() => Router.push('/admin/users'));

export const OpenUserUpdates = openUserUpdates;

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      openUserUnsub();
      backUnSub();
    });
  }
}
