import { Bus } from 'baconjs';

class NotificationStore {
  constructor() {
    this.notifactions = new Bus();
    this.publish = this.publish.bind(this);
  }

  publish({ type, message }) {
    this.notifactions.push({ type, message });
  }

  subscribe() {
    return this.notifactions.toEventStream();
  }
}

export default new NotificationStore();
