import { mergeAll, when } from 'baconjs';
import {
  always,
  identity,
  merge,
  not,
  objOf,
  pipe,
  prop,
  propEq,
} from 'rambda';

import Router from 'next/router';

import emailValidator from '@/engine/validators/email-validator';

import { MutateUser } from '@/engine/utils/use-user';
import actionDispatcher from '@/engine/actions/action-dispatcher';
import stateStore from '@/engine/stores/state-store';
import Api from '@/engine/utils/api';
import passwordValidator from '@/engine/validators/password-validator';

const firstNameAction = actionDispatcher.subscribe('register.form.firstName');
const lastNameAction = actionDispatcher.subscribe('register.form.lastName');
const emailAction = actionDispatcher.subscribe('register.form.email');
const passwordAction = actionDispatcher.subscribe('register.form.password');
const passwordConfirmAction = actionDispatcher.subscribe('register.form.password.confirm');
const submitAction = actionDispatcher.subscribe('register.form.submit');

const filterBlurOnly = propEq('event', 'blur');

const updateUser = (user) => {
  MutateUser(user);
  Router.push('/');
};

const toAccountCreateRequest = (data) => {
  return Api.post('/api/account', data);
};

const validateForm = ({
  firstName,
  lastName,
  email,
  password,
  passwordConfirm,
}) => {
  let valid = true;
  let validationErrors = [];
  if (!firstName) {
    valid = false;
    validationErrors.push('First name is required');
  }

  if (!lastName) {
    valid = false;
    validationErrors.push('Last name is required');
  }

  if (!email) {
    valid = false;
    validationErrors.push('Email is required');
  } else if (!emailValidator(email)) {
    valid = false;
    validationErrors.push('Email is invalid');
  }

  if (!password) {
    valid = false;
    validationErrors.push('Password is required');
  } else if (!passwordValidator(password)) {
    valid = false;
    validationErrors.push('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, one special character, and no whitespace');
  } else if (password !== passwordConfirm) {
    valid = false;
    validationErrors.push('Passwords do not match');
  }

  return {
    firstName,
    lastName,
    email,
    password,
    valid,
    validationErrors,
  };
};

const toValidationError = ({ validationErrors }) => ({
  send: false,
  validationErrors,
});

const firstNameUpdates = firstNameAction.filter(filterBlurOnly)
  .map(prop('value'))
  .map(objOf('firstName'));
const lastNameUpdates = lastNameAction.filter(filterBlurOnly)
  .map(prop('value'))
  .map(objOf('lastName'));
const emailUpdates = emailAction.filter(filterBlurOnly)
  .map(prop('value'))
  .map(objOf('email'));
const passwordUpdates = passwordAction.filter(filterBlurOnly)
  .map(prop('value'))
  .map(objOf('password'));
const passwordConfirmUpdates = passwordConfirmAction.filter(filterBlurOnly)
  .map(prop('value'))
  .map(objOf('passwordConfirm'));

const formUpdates = mergeAll([
  firstNameUpdates,
  lastNameUpdates,
  emailUpdates,
  passwordUpdates,
  passwordConfirmUpdates,
])
  .scan({}, merge)
  .map(validateForm);

const submitUpdates = when([formUpdates, submitAction], identity);
const validSubmitUpdates = submitUpdates.filter(prop('valid'));
const invalidSubmitUpdates = submitUpdates.filter(pipe(prop('valid'), not));

const createRequestUpdates = validSubmitUpdates
  .flatMap(toAccountCreateRequest);

const createSuccessUpdates = createRequestUpdates
  .skipErrors();

const failedCreateRequestUpdates = createRequestUpdates
  .errors()
  .mapError(always({ type: 'error', message: 'Failed to create account' }));

const stateUpdates = mergeAll(
  failedCreateRequestUpdates.map(always({ send: true, error: true })),
  validSubmitUpdates.map(always({ send: true, error: false })),
  invalidSubmitUpdates.map(toValidationError),
)
  .scan({ send: false }, merge);

const stateUnsub = stateUpdates.onValue(stateStore.Publish('auth', 'register-page'));

const createSuccessUnsubscribe = createSuccessUpdates.onValue(updateUser);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.log('unloading register-form-store');
      stateUnsub();
      createSuccessUnsubscribe();
    });
  }
}
