import {
  when as BaconWhen,
} from 'baconjs';

import {
  always,
  clone,
  not,
  pick,
  pipe,
  prop,
  propEq,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import projectPhotoDataStore from '@/data/projects/project-photo-data-store';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

const deleteAction = actionDispatcher.subscribe('project-photo-delete');

const toPhotoDeleteUpdate = ({ items, keyIndex }, { photoId, type }) => {
  const item = items[keyIndex[photoId]];

  const updated = clone(item);
  updated[`${type}ImageUrl`] = null;

  if (!updated.beforeImageUrl && !updated.afterImageUrl) {
    return {
      edit: { id: updated.id },
      type: 'both',
    };
  }

  return {
    edit: pick([`${type}ImageUrl`, 'id'], updated),
    type,
  };
};

const toDeleteRequest = ({ type, edit: { id } }) => {
  return Api.delete(`/api/projects/photos/${id}?type=${type}`);
};

const deleteUpdates = BaconWhen(
  [projectPhotoDataStore.updates, deleteAction],
  toPhotoDeleteUpdate,
);

const deleteRequestUpdates = deleteUpdates
  .flatMap(toDeleteRequest);

const deleteFailedUpdates = deleteRequestUpdates
  .errors()
  .mapError(always({ message: 'Failed to delete photo', type: 'error' }));

const deleteBothUpdates = deleteUpdates
  .filter(propEq('type', 'both'))
  .map(prop('edit'));

const deleteImageUpdates = deleteUpdates
  .filter(pipe(propEq('type', 'both'), not));

const editUpdates = deleteImageUpdates
  .map(prop('edit'));

const deleteFromDsUnsubscribe = deleteBothUpdates.onValue(projectPhotoDataStore.remove);
const editDsUnsubscribe = editUpdates.doLog('editUpdates').onValue(projectPhotoDataStore.update);
const deleteFailedUnsubscribe = deleteFailedUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.warn('Unloading project photo delete store');
      deleteFromDsUnsubscribe();
      editDsUnsubscribe();
      deleteFailedUnsubscribe();
    });
  }
}
