/* eslint-disable react/jsx-props-no-spreading */
import Head from 'next/head';
import WithUser from '@/engine/decorators/with-user';

import AppBar from '@/components/app-bar';
import StateContext from '@/engine/state-context';
import NotificationDisplay from '@/components/notifcation-display';
import AlertModal from '@/components/alert-modal';
import PrintModal from '@/components/print-modal';
import AccountInactive from '@/components/account-inactive';
import { ACCOUNT_STATUS_TRIAL } from '@/shared/constants';

const AppLayout = ({
  stateContext = 'common',
  title = 'RenoTracker',
  userRole = '',
  user = null,
  Component,
  componentProps = {},
}) => {
  const renderContent = () => {
    return (
      <StateContext.Provider value={stateContext}>
        <div className="app-layout__view-inner minh-100">
          <Component {...componentProps} />
        </div>
      </StateContext.Provider>
    );
  };

  const validRole = () => {
    if (!userRole) return true;

    if (!user) return false;

    return user.roles[userRole];
  };

  const renderView = () => {
    if (!user.isActive) {
      return <AccountInactive emailVerified={user.emailVerified} isTrial={user.accountStatus == ACCOUNT_STATUS_TRIAL} />;
    }
    if (validRole()) {
      return renderContent();
    }

    return null;
  };

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <AlertModal id="alert-modal" />
      <PrintModal id="property-print-modal" />
      <div className="app-layout">
        {user.isActive && <AppBar />}
        <NotificationDisplay />
        <div className="app-layout__view">
          {renderView()}
        </div>
      </div>
    </>
  );
};

const withUserProps = {
  redirectToIndex: false,
  redirectToLogin: true,
  showLoader: true,
};

export default WithUser(AppLayout, withUserProps);
