import {
  always,
  omit,
  propEq,
} from 'rambda';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';
import templateDataStore from '@/data/templates/template-data-store';
import adminTemplateItemDataStore from '@/data/templates/admin-template-item-data-store';

const loadTemplateAction = actionDispatcher.subscribe('template-admin-edit-page');

const loadTemplate = ({ templateId }) => {
  return Api.get(`/api/admin/templates/${templateId}`);
};

const loadDataStore = ({ data }) => {
  const templateData = omit(['items'], data);
  templateDataStore.load([templateData]);
  adminTemplateItemDataStore.load(data?.items ?? []);
};

const selectTemplate = ({ templateId }) => {
  templateDataStore.clear();
  templateDataStore.select(templateId);
};

const loadUpdates = loadTemplateAction
  .filter(propEq('event', 'load'));

const loadRequestUpdates = loadUpdates
  .flatMapLatest(loadTemplate);

const loadSuccessUpdates = loadRequestUpdates
  .skipErrors();

const loadFailedUpdates = loadRequestUpdates
  .errors()
  .mapError(always({ message: 'Failed to load template', type: 'error' }));

const selectUnsubscribe = loadUpdates.onValue(selectTemplate);
const loadUnsubscribe = loadSuccessUpdates.onValue(loadDataStore);
const loadFailedUnsubscribe = loadFailedUpdates.onValue(notificationStore.publish);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      console.log('unloading template-load-store');
      selectUnsubscribe();
      loadUnsubscribe();
      loadFailedUnsubscribe();
    });
  }
}
