import Button from '@/components/button';
import { formatDate } from '@/engine/utils/date';
import { ACCOUNT_STATUS_PAID } from '@/shared/constants';

const UserDetailsPaid = ({ user }) => {
  const actionMetaData = { userId: user.id, email: user.email };

  const GetActiveMessage = () => {
    if (user.accountStatus === ACCOUNT_STATUS_PAID) {
      return 'User has an active subscription';
    }

    return 'User subscription will expire at the end of their current subscrption period';
  };

  const GetExpiredMessage = () => {
    if (user.accountStatus === ACCOUNT_STATUS_PAID) {
      return 'User subscription failed to bill';
    }

    return 'User has cancelled their subscription';
  };

  const nextPaymentDate = Date.parse(user.subscriptionEnd);
  return (
    <div className="mt-4 mb-4">
      {nextPaymentDate > new Date() ? (
        <div className={`alert ${user.accountStatus === ACCOUNT_STATUS_PAID ? 'alert-success' : 'alert-warning'}`}>
          {GetActiveMessage()}
        </div>
      ) : (
        <div className="alert alert-danger">{GetExpiredMessage()}</div>
      )}
      <span className="d-block mr-4 mb-2">
        <strong className="mr-2">Next Payment Date:</strong>
        {formatDate(nextPaymentDate)}
      </span>
      <Button
        id="admin-user-set-free"
        color="primary"
        actionMetaData={actionMetaData}
      >
        Convert to free
      </Button>
    </div>
  );
};

export default UserDetailsPaid;
