import WithState from '@/engine/decorators/with-state';
import { FormatMoney } from '@/engine/utils/number';

const TotalsSales = ({
  total,
  gst,
  profit,
}) => {
  return (
    <table className="list-items table-accentuated table-borderless table-headed table table-sm">
      <tbody>
        <tr>
          <td>Total Sale Proceeds (GST Inclusive)</td>
          <td className="text-right">{FormatMoney(total)}</td>
        </tr>
        <tr>
          <td>Less GST Payable</td>
          <td className="text-right">{FormatMoney(gst)}</td>
        </tr>
        <tr>
          <td>Net Sale Proceeds (GST Exclusive)</td>
          <td className="text-right">{FormatMoney(total - gst)}</td>
        </tr>
        <tr>
          <td>Gross Profit (Net of GST)</td>
          <td className="text-right">{FormatMoney(profit)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default WithState(TotalsSales);
