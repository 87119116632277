import { Container, Row, Col } from 'reactstrap';
import ReactLoading from 'react-loading';

const Loading = ({
  loaderType = 'cylon', text = 'Loading...', loaderColor = '#35b8e2', loaderSize = '100px',
}) => {
  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col
          xs="12"
          className="d-flex align-items-center justify-content-center"
        >
          <div className="text-center">
            <ReactLoading
              type={loaderType}
              color={loaderColor}
              height={loaderSize}
              width={loaderSize}
            />
            <p className="mt-1">{text}</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Loading;
