import { useState, useEffect } from 'react';

import {
  Label,
  Nav,
  NavItem,
} from 'reactstrap';

import AddressFinder from '@/components/address-finder';
import Button from '@/components/button';
import Form from '@/components/form';
import FormGroup from '@/components/form-group';
import WithActions from '@/engine/decorators/with-actions';
import Input from '@/components/input';

const ClonePropertyForm = ({ publish, property }) => {
  const [newAddressValue] = useState(property?.address?.fullAddress);

  useEffect(() => {
    if (property?.address) {
      publish({
        fullAddress: property.address.fullAddress,
        address: {
          line1: property.address.address1,
          line2: property.address.address2,
          suburb: property.address.suburb,
          city: property.address.city,
          postcode: property.address.postcode,
        },
      });
    }
  }, [property?.address]);

  return (
    <Form>
      <FormGroup>
        <Label for="name">From Property Address</Label>
        <br />
        {property?.address?.fullAddress}
      </FormGroup>
      <FormGroup>
        <Label for="label">Property Name</Label>
        <Input id="property-clone-name"
          value={`Clone of ${property?.name}`}
          />
      </FormGroup>
      <FormGroup>
        <Label for="name">New Property Address</Label>
        <AddressFinder
          id="property-clone-address"
          fieldClassName="form-control af-hidden-autofill-icon"
          value={newAddressValue}
        />
      </FormGroup>
      <Nav className="justify-content-center align-items-center nav-space-items">
        <NavItem>
          <Button
            id="property-clone"
            type="submit"
            swatch
            color="primary"
          >
            Clone
          </Button>
        </NavItem>
      </Nav>
    </Form>
  );
};

export default WithActions(ClonePropertyForm);
