import { when as BaconWhen } from 'baconjs';
import { identity } from 'rambda';
import Router from 'next/router';
import actionDispatcher from '@/engine/actions/action-dispatcher';

import { PropertyCreatedUpdates } from './properties.add.store';
import propertyDataStore from '../data/property.data.store';

const propertyBackUpdates = actionDispatcher.subscribe('property-back');
const propertyEditBackUpdates = actionDispatcher.subscribe('property-edit-back');
const addPropertyAction = actionDispatcher.subscribe('add-property');
const navigatePropertyAction = actionDispatcher.subscribe('navigate-property');
const addProjectAction = actionDispatcher.subscribe('property-project-add');

const navigateToList = () => {
  Router.push('/properties');
};

const navigateToProperty = ({ propertyId }) => {
  Router.push(`/properties/${propertyId}`);
};

const navigateToAddProperty = () => {
  Router.push('/properties/add');
};

const navigateToAddProject = ({ id }) => {
  Router.push(`/properties/${id}/add-project`);
};

const addProjectUpdates = BaconWhen(
  [propertyDataStore.selectUpdates.toProperty(), addProjectAction],
  identity,
);

addPropertyAction.onValue(navigateToAddProperty);
propertyBackUpdates.onValue(navigateToList);
propertyEditBackUpdates.onValue(navigateToProperty);
PropertyCreatedUpdates.onValue(navigateToProperty);
navigatePropertyAction.onValue(navigateToProperty);
addProjectUpdates.onValue(navigateToAddProject);
