import Loading from '@/components/loading';
import UseUser from '../utils/use-user';

const WithUser = (
  Component,
  { redirectToLogin = false, redirectToIndex = false, showLoader = false } = {},
) => {
  const ComponentWithUser = (props) => {
    const { user } = UseUser({ redirectToLogin, redirectToIndex });

    if (!user || (redirectToLogin && !user?.isLoggedIn)) {
      if (!showLoader) {
        return null;
      }

      return (
        <Loading />
      );
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} user={user} />;
  };

  return ComponentWithUser;
};
export default WithUser;
