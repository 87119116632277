import {
  Label,
  Nav,
  NavItem,
  UncontrolledTooltip,
} from 'reactstrap';

import { curry } from 'rambda';

import AddressFinder from '@/components/address-finder';
import Button from '@/components/button';
import FormGroup from '@/components/form-group';
import Icon from '@/components/icon';
import ImageField from '@/components/image-field';
import Input from '@/components/input';
import CollaboraterAdd from './collaborater-add';

const renderCollaborator = curry((property, access) => {
  return (
    <div key={access.id}>
      <Icon icon="delete" id="property-collaborator-delete" actionMetaData={{ propertyId: property.id, email: access.email }} className="mr-3" />
      <span id={`access_name_${access.id}`}>{access.fullName}</span>
      <UncontrolledTooltip placement="bottom" target={`#access_name_${access.id}`}>{access.email}</UncontrolledTooltip>
      {access.isCreator && <span className="ml-1">(Creator)</span>}
    </div>
  );
});

const renderColloborators = (property) => {
  return (
    <div className="mb-3">
      <h3 className="mt-2 text-center w-100">Collaborators</h3>
      {
        property.hasCollaborators
          ? <div className="mb-2">{property.collaborators.map(renderCollaborator(property))}</div>
          : <div>No collaborators</div>
      }

      <CollaboraterAdd id="property-collaborator-add" propertyId={property.id} />
    </div>
  );
};

const EditPropertyForm = ({ isEditingExisting, property }) => {
  return (
    <>
      <Nav className="property-detail-nav property-detail-nav--right mb-2">
        <NavItem>
          <Button
            id="property-delete"
            icon
            title="Delete this project and all of it's details"
            className="text-danger"
          >
            <Icon icon="delete" />
            Delete Reno
          </Button>
        </NavItem>
      </Nav>
      <FormGroup>
        <Label for="label">Property Name</Label>
        <Input id="property-edit-name"
          value={`${property?.name}`}
          />
      </FormGroup>
      <FormGroup>
        <Label for="name">Property Address</Label>
        <AddressFinder
          id="property-edit-address"
          fieldClassName="form-control af-hidden-autofill-icon"
          value={property?.address?.fullAddress}
        />
      </FormGroup>
      <FormGroup>
        <Label for="name">Image</Label>
        <ImageField
          id="property-edit-image"
          resize={{ width: 640, height: 480 }}
          fileUrl={property.imageUrl}
        />
      </FormGroup>
      {property.isCreator && renderColloborators(property)}
      <Nav className="justify-content-center align-items-center nav-space-items">
        <NavItem>
          <Button
            id="property-save"
            type="submit"
            swatch
            color={isEditingExisting ? 'secondary' : 'primary'}
          >
            {isEditingExisting ? 'Back' : 'Next'}
          </Button>
        </NavItem>
      </Nav>
    </>
  );
};

export default EditPropertyForm;
