import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import FormGroup from '@/components/form-group';
import Input from '@/components/input';
import Button from '@/components/button';
import emailValidator from '@/engine/validators/email-validator';
import Loading from '@/components/loading';
import WithState from '@/engine/decorators/with-state';
import LoginLogo from '@/components/login-logo';

const RegisterForm = ({
  send = false,
  success,
  error,
  validationErrors,
  message,
}) => {
  const renderForm = () => {
    return (
      <>
        <Row>
          <Col xs="12" md="6" lg="4" className="ml-auto mr-auto">
            {message && (
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            )}
            {validationErrors && validationErrors.length > 0 && (
              <div className="alert alert-danger" role="alert">
                {validationErrors.map((error) => (
                  <div key={error}>{error}</div>
                ))}
              </div>
            )}
            <FormGroup lg>
              <Input id="register.form.firstName" name="firstName" isRequired type="text" placeholder="Firstname" lg />
            </FormGroup>
            <FormGroup lg>
              <Input id="register.form.lastName" name="lastName" isRequired type="text" placeholder="Lastname" lg />
            </FormGroup>
            <FormGroup lg>
              <Input id="register.form.email" name="email" validator={emailValidator} isRequired type="text" placeholder="Email Address" lg />
            </FormGroup>
            <FormGroup lg>
              <Input id="register.form.password" name="password" type="password" isRequired placeholder="Password" lg />
            </FormGroup>
            <FormGroup lg>
              <Input id="register.form.password.confirm" name="passwordConfirm" type="password" isRequired placeholder="Confirm Password" lg />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs="12" md="6" lg="4" className="ml-auto mr-auto">
            <Button
              id="register.form.submit"
              color="primary"
              type="submit"
              size="lg"
              className="w-100"
            >
              Start your free trial
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const renderSend = () => {
    let alert = '';
    if (success) {
      alert = (
        <div className="alert alert-success" role="alert">
          Hang Tight, your account will be approved shortly.
          You will receive an email with a login link once the account is approved.
        </div>
      );
    } else if (error) {
      alert = (
        <div className="alert alert-danger" role="alert">
          Something went wrong while creating your account, please try again later.
        </div>
      );
    } else {
      alert = <Loading />;
    }

    return (
      <Col xs="12" md="6" lg="4" className="ml-auto mr-auto mb-2">
        {alert}
      </Col>
    );
  };

  return (
    <form>
      <Container fluid>
        <Row>
          <Col xs="12" className="d-flex align-items-center justify-content-center">
            <LoginLogo />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6" lg="4" className="ml-auto mr-auto mb-2">
            <h3>Register a New Account</h3>
          </Col>
        </Row>
        {send ? renderSend() : renderForm()}
        <Row>
          <Col xs="12" md="6" lg="4" className="ml-auto mr-auto">
            <hr className="w-100" />
            <Button
              id="forgot.password.form.return"
              color="secondary"
              type="submit"
              size="sm"
              className="d-flex ml-auto mr-auto"
            >
              Return to Login
            </Button>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default WithState(RegisterForm);
