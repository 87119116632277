import WithState from '@/engine/decorators/with-state';
import { FormatMoney } from '@/engine/utils/number';

const AcquisitionTotals = ({ total, gst }) => {
  return (
    <table className="list-items table-accentuated table-borderless table-headed table table-sm">
      <tbody>
        <tr>
          <td>Total Acquisition Costs (GST Inclusive)</td>
          <td className="text-right">{FormatMoney(total)}</td>
        </tr>
        <tr>
          <td>Less GST Claimable</td>
          <td className="text-right">{FormatMoney(gst)}</td>
        </tr>
        <tr>
          <td>Net Acquisition Cost (GST Exclusive)</td>
          <td className="text-right">{FormatMoney(total - gst)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default WithState(AcquisitionTotals);
