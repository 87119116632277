import { Col, Card } from 'reactstrap';
import Link from 'next/link';

import Icon from '@/components/icon';
import Progress from '@/components/progress';

import PropertiesUtils from '../../utils/properties-util';
import { STATUS_COMPLETED } from '@/shared/constants';

const PropertyTile = ({ property }) => {
  const propertyUrl = `/properties/${property.id}`;

  const propertyBudget = PropertiesUtils.getPropertyBudget(property);

  const progressBarIcon = property.status === STATUS_COMPLETED ? (
    <Icon icon="check" size="md" />
  ) : (
    false
  );

  return (
    <Col xs="6" md="4" lg="3">
      <Card className="card-raised h-100">
        <Link href={propertyUrl}>
          <a>
            {!property.hasImage && (
              <div className="property-preview-image property-preview-image-default" />
            )}
            {property.hasImage && (
              <div
                className="property-preview-image"
                style={{ backgroundImage: `url(${property.imageUrl})` }}
              />
            )}
          </a>
        </Link>
        <div className="property-preview-details">
          <Progress
            className="property-progress"
            percentage={
              propertyBudget.progress > 100
                ? propertyBudget.progress - 100
                : propertyBudget.progress
            }
            text={property.status === STATUS_COMPLETED ? '' : propertyBudget.progressFormatted}
            icon={progressBarIcon}
            status={propertyBudget.progress > 100 ? 'danger' : false}
            withBG
          />
          <Link
            href={propertyUrl}
            passHref
          >
            <a className="property-title btn property-preview-button property-preview-button-name">
              <h5 className="mb-0">{property.name}</h5>
            </a>
          </Link>
          <Link
            href={propertyUrl}
            passHref
          >
            <a className="btn property-preview-button property-preview-button-gallery d-none d-sm-block">
              <Icon icon="photo_library" size="md" />
              <br />
              <span>Gallery</span>
            </a>
          </Link>
        </div>
      </Card>
    </Col>
  );
};

export default PropertyTile;
