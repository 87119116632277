import { useEffect } from 'react';
import Loading from '@/components/loading';

import actionDispatcher from '../actions/action-dispatcher';

import component from '../utils/component';

const WithLoading = (WrappedComponent) => {
  const ComponentWithLoading = (props) => {
    const { loaded } = props;

    useEffect(() => {
      if (!loaded) {
        const actionData = {
          // eslint-disable-next-line react/destructuring-assignment
          id: props.actionKey || props.id,
          data: { event: 'load' },
        };
        actionDispatcher.dispatch(actionData);
      }
    }, [loaded]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return (loaded ? <WrappedComponent {...props} /> : <Loading />);
  };

  ComponentWithLoading.displayName = `WithLoading(${component.getDisplayName(WrappedComponent)})`;
  return ComponentWithLoading;
};

export default WithLoading;
