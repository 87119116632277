import { mergeAll as BaconMergeAll } from 'baconjs';
import {
  always,
  merge,
  objOf,
  prop,
  propEq,
  sortBy,
} from 'rambda';
import Router from 'next/router';

import actionDispatcher from '@/engine/actions/action-dispatcher';
import Api from '@/engine/utils/api';
import notificationStore from '@/engine/stores/notification-store';

import templateDataStore from '@/data/templates/template-list-data-store';
import stateStore from '@/engine/stores/state-store';

import { LoadProject } from './project-load-store';

const loadAction = actionDispatcher.subscribe('project-add-page').filter(propEq('event', 'load'));
const selectTemplateAction = actionDispatcher.subscribe('project-add-page').filter(propEq('event', 'selectTemplate'));
const addCustomTemplateAction = actionDispatcher.subscribe('project-add-custom');

const loadTemplateRequest = () => {
  return Api.get('/api/templates');
};

const loadDataStore = ({ data }) => {
  templateDataStore.load(data);
};

const loadProject = (project) => {
  LoadProject(project);
  Router.push(`/project/${project.id}`);
};

const addTemplateToProjectRequest = ({ templateId, propertyId }) => {
  return Api.post(`/api/properties/${propertyId}/projects`, { templateId });
};

const loadTemplateRequestsUpdates = loadAction.flatMap(loadTemplateRequest);

const loadTemplateRequestSuccessUpdates = loadTemplateRequestsUpdates
  .skipErrors();

const loadTemplatesErrorUpdates = loadTemplateRequestsUpdates
  .errors()
  .mapError(always({ message: 'Failed to load templates', type: 'error' }));

const templatesStateUpdates = templateDataStore
  .updates
  .map(prop('items'))
  .map(sortBy(prop('name')))
  .map(objOf('templates'));

const stateUpdates = BaconMergeAll(
  templatesStateUpdates,
)
  .scan({}, merge);

const addProjectRequestUpdates = selectTemplateAction
  .merge(addCustomTemplateAction)
  .flatMap(addTemplateToProjectRequest);

const addProjectRequestErrorUpdates = addProjectRequestUpdates
  .errors()
  .mapError(always({ message: 'Failed to add project', type: 'error' }));

const addProjectSuccessUpdates = addProjectRequestUpdates
  .skipErrors()
  .map(prop('data'));

const loadUnsubscribe = loadTemplateRequestSuccessUpdates.onValue(loadDataStore);
const failedUnsubscribe = loadTemplatesErrorUpdates.onValue(notificationStore.publish);
const stateUnsubscribe = stateUpdates.onValue(stateStore.Publish('projects', 'project-add-page'));
const failAddProjectUnsubscribe = addProjectRequestErrorUpdates.onValue(notificationStore.publish);
const addProjectUnsubscribe = addProjectSuccessUpdates.onValue(loadProject);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => {
      loadUnsubscribe();
      failedUnsubscribe();
      stateUnsubscribe();
      failAddProjectUnsubscribe();
      addProjectUnsubscribe();
    });
  }
}
