import GroupByStore from '@/engine/stores/group-by-store';
import workTrackerItemDataStore from './work-tracker-item-data-store';
import workTrackerCategoryDataStore from './work-tracker-category-data-store';

class WorkTrackerItemGroupByCategoryStore extends GroupByStore {
  constructor() {
    super(workTrackerItemDataStore, workTrackerCategoryDataStore, { itemDetailKey: 'categoryId' });
  }
}

export default new WorkTrackerItemGroupByCategoryStore();
