import { combineTemplate } from 'baconjs';
import {
  forEach,
  map,
  merge,
  prop,
} from 'rambda';
import addIndex from '../funcs/add-index';

class GroupByStore {
  constructor(itemDataStore, detailDataStore, { itemDetailKey }) {
    this.groupItems = this.groupItems.bind(this);
    this.itemDetailKey = itemDetailKey;
    this.updates = combineTemplate({
      itemsUpdate: itemDataStore.updates,
      detailsUpdate: detailDataStore.updates,
    })
      .map(this.groupItems)
      .toProperty();
  }

  groupItems({ itemsUpdate, detailsUpdate }) {
    const keyIndex = {};
    const groupedItems = addIndex(map)((detailItem, idx) => {
      const detailId = prop('id', detailItem);

      keyIndex[detailId] = idx;
      return merge(detailItem, {
        items: [],
      });
    }, detailsUpdate.items);

    forEach((item) => {
      const detailId = prop(this.itemDetailKey, item);
      const detailIndex = keyIndex[detailId];

      if (detailIndex >= 0) {
        groupedItems[detailIndex].items.push(item);
      }
    }, itemsUpdate.items);

    return {
      items: groupedItems,
    };
  }
}

export default GroupByStore;
