import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import NumberFormat from 'react-number-format';

import WithActions from '@/engine/decorators/with-actions';

import WorkItemMenu from './work-item-menu';
import { FormatMoney } from '@/engine/utils/number';

const WorkItemAgentFees = ({ publish, salePrice, item, isAdmin }) => {
  const [internalValue, setInternalValue] = useState(item.value?.value ?? 0);
  const [internalType, setInternalType] = useState(item.value?.type ?? 'percent');

  const publishInternal = (value, type) => {
    publish({ value: { value, type }, itemId: item.id });
  };

  const onValueChange = (value) => {
    if (value !== internalValue) {
      publishInternal(value ?? 0, 'fixed');
      setInternalValue(value ?? 0);
    }
  };

  const onPercentChange = (values) => {
    const updatedValue = values?.floatValue ?? 0;
    if (updatedValue !== internalValue) {
      publishInternal(updatedValue, 'percent');
      setInternalValue(updatedValue);
    }
  };

  const calculateFixed = () => {
    if (!internalValue || !salePrice) {
      return 0;
    }
    return Math.round((internalValue * salePrice) / 100);
  };

  const calculatePercent = () => {
    if (!internalValue || !salePrice || salePrice === '0') {
      return 0;
    }
    return Math.round(((100 * internalValue) / salePrice) * 1000) / 1000;
  };

  const onTypeChange = (e) => {
    let value = 0;
    if (e.target.value === 'percent') {
      value = calculatePercent();
    } else {
      value = calculateFixed();
    }

    publishInternal(value, e.target.value);
    setInternalValue(value);
    setInternalType(e.target.value);
  };

  const renderInput = () => {
    switch (internalType) {
      case 'percent':
        return (
          <>
            <NumberFormat
              value={internalValue}
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={2}
              allowEmptyFormatting
              suffix="%"
              onValueChange={onPercentChange}
              className="form-control form-control-no-browser-helpers"
            />
            <div className="mt-2">
              {FormatMoney(calculateFixed())}
            </div>
          </>
        );
      case 'fixed':
        return (
          <>
            <CurrencyInput
              allowDecimals={false}
              allowNegativeValue={false}
              value={internalValue}
              onValueChange={onValueChange}
              placeholder="$0"
              intlConfig={{ locale: 'en-NZ', currency: 'NZD' }}
              prefix="$"
              decimalSeparator="."
              groupSeparator=","
              className="form-control form-control-no-browser-helpers"
            />
            <div className="mt-2">
              {calculatePercent()}
              %
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <tr key={item.id} className="budget-item-row budget-item-row--default">
      <td className="budget-item-row__tradeDescription budget-item-bit__mob-show-for-expanded2 ">
        <div>
          {item.label}
        </div>
        <div className="form-check form-check-inline mt-2">
          <input onChange={onTypeChange} className="form-check-input" type="radio" name={item.id} id="workItemAgentFeePercent" value="percent" checked={internalType === 'percent'} />
          <label className="form-check-label" htmlFor="workItemAgentFeePercent">
            Percent
          </label>
        </div>
        <div className="form-check form-check-inline mt-2">
          <input onChange={onTypeChange} className="form-check-input" type="radio" name={item.id} id="workItemAgentFeeFixed" value="fixed" checked={internalType === 'fixed'} />
          <label className="form-check-label" htmlFor="workItemAgentFeeFixed">
            Fixed Fee
          </label>
        </div>
      </td>
      <td className="text-right budget-item-row__tradeBudget budget-item-bit__mob-show-for-expandedNOT">
        {renderInput()}
      </td>
      <td>
        <WorkItemMenu item={item} isAdmin={isAdmin} />
      </td>
    </tr>
  );
};

export default WithActions(WorkItemAgentFees, { actionKey: 'work-item-update' });
