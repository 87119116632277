import {
  assoc,
  merge,
  reduce,
} from 'rambda';

import addIndex from '../../funcs/add-index';

const reduceKeys = (indexObj, nextItem, index) => {
  return assoc(nextItem.id, index, indexObj);
};

const remove = (prev, next) => {
  const { items } = prev;
  const newItems = [...items];
  let { keyIndex } = prev;

  if (!prev.keyIndex[next.item.id] && prev.keyIndex[next.item.id] !== 0) {
    return prev;
  }
  newItems.splice(prev.keyIndex[next.item.id], 1);

  keyIndex = addIndex(reduce)(reduceKeys, {}, newItems);

  return merge(prev, {
    items: newItems,
    operation: 'remove',
    keyIndex,
    added: [next.item],
  });
};

export default remove;
