import Link from '@/components/link';
import ProjectItem from '@/components/project-item';

const renderProjectItems = (project, items) => {
  return items.map((item) => {
    return <ProjectItem key={item.id} projectId={project.id} item={item} />;
  });
};

const filterProjectItems = (items, searchText) => {
  if (!searchText) {
    return items;
  }

  return items.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));
};

const renderProjects = (projects, searchText) => {
  return projects.map((project) => {
    return (
      <div key={project.id}>
        <table className="list-items list-items--transformer table-borderless table-headed table table-sm">
          <thead className="list-items__head">
            <tr className="list-items__head-row">
              <td style={{ width: '50%' }} className="list-items__head-description">
                <h5>
                  <span className="mr-2">{project.name}</span>
                  <strong className="tiny allcaps">
                    <Link id="project-navigate" actionMetaData={{ projectId: project.id }}>
                      View/Edit
                    </Link>
                  </strong>
                </h5>
              </td>
              <td className="allcaps text-center h6 list-items__head-quantity">Qty.</td>
              <td className="allcaps text-right h6 minw-5 list-items__head-budget">Budget</td>
              <td className="allcaps text-right h6 minw-5 list-items__head-spent">Spent</td>
              <td className="allcaps text-right h6 list-items__head-available">Left</td>
              <td className="allcaps text-right h6 list-items__head-actions">&nbsp;</td>
            </tr>
          </thead>
          <tbody className="list-items__body">
            {renderProjectItems(project, filterProjectItems(project.items, searchText))}
          </tbody>
        </table>
      </div>
    );
  });
};

const PropertyBudgetDetail = ({ projects, searchText }) => {
  return (
    <>
      {renderProjects(projects, searchText)}
    </>
  );
};

export default PropertyBudgetDetail;
