import { useState } from 'react';

import { Button, Input } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';

import Plus from '@/components/plus';
import WithActions from '@/engine/decorators/with-actions';

const WorkItemAdd = ({ publish, categoryId }) => {
  const [adding, setAdding] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');

  const onAdd = (e) => {
    e.preventDefault();
    setAdding(true);
  };

  const returnToDefault = () => {
    setAdding(false);
    setName('');
    setValue('');
  };

  const onCancel = (e) => {
    e.preventDefault();
    returnToDefault();
  };

  const onSave = (e) => {
    e.preventDefault();
    publish({ categoryId, label: name, value: value ?? 0 });
    returnToDefault();
  };

  return (
    <>
      {adding && (
        <tr
          key="adding-custom-field"
          className="budget-item-row budget-item-row--default"
        >
          <td>
            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </td>
          <td className="text-right">
            <CurrencyInput
              allowDecimals={false}
              allowNegativeValue={false}
              value={value}
              onValueChange={(val) => setValue(val ?? '')}
              placeholder="$0"
              intlConfig={{ locale: 'en-NZ', currency: 'NZD' }}
              prefix="$"
              decimalSeparator="."
              groupSeparator=","
              className="form-control form-control-no-browser-helpers"
            />
          </td>
        </tr>
      )}
      <tr key="custom-field">
        <td>
          {!adding && (
            <Button
              onClick={onAdd}
              color="primary"
            >
              <Plus className="btn-swatch__icon mr-2" />
              New Item
            </Button>
          )}
          {adding && (
            <div>
              <Button
                onClick={onSave}
                color="primary"
                disabled={!name}
              >
                Save
              </Button>
              <Button
                onClick={onCancel}
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default WithActions(WorkItemAdd);
